import React, { Component } from 'react';
import styled from 'styled-components';

const ComponentContainer = styled.div`
    max-height: calc(${props => props.viewport}px - 6.2rem - 1px);
    overflow-y: auto;
`;

class PopupComponent extends Component {
    closePopup = () => {
        this.props.setAppState({renderKlarna: false});
    };
    getKlarnaSnippet = () => {
        //Mostley untouched code snippet from Klarna
        const checkoutContainer = document.getElementById('checkout-container');
        checkoutContainer.innerHTML = this.props.appState.klarnaFrame;
        const scriptsTags = checkoutContainer.getElementsByTagName('script');
        //Originally scriptsTagsLength in for loop was scriptsTags.length. Somehow it caused infinite loop.
        //Therefor changed.
        const scriptsTagsLength = scriptsTags.length;
        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (let i = 0; i < scriptsTagsLength; i++) {
            const parentNode = scriptsTags[i].parentNode;
            const newScriptTag = document.createElement('script');
            newScriptTag.type = 'text/javascript';
            newScriptTag.text = scriptsTags[i].text;
            parentNode.removeChild(scriptsTags[i]);
            parentNode.appendChild(newScriptTag);
        }
    };
    componentDidMount() {
        this.getKlarnaSnippet();
        document.getElementById('klarna-checkout-container').style.overflowX = '';
    };
    componentWillUnmount(){
        //To update booking with payment status:
        this.props.setAppState(
            null,
            {
                type: "getWebBookingLogin",
                payload: this.props.appState.booking.id
            }
        );
    }
    render() {
        return (
            <ComponentContainer
                id="checkout-container"
                viewport={this.props.appState.viewportHeightPx}
            />
        );
    }
}
export default PopupComponent;
