import React from 'react';
import styled from 'styled-components';
import {CSSCommon} from "../variables/CSSVariables";
import loader from '../pictures/Dual_Ring_yellow_06_opacity.gif'

const blackout= CSSCommon.color.blackout;

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: ${props => props.viewport}px;
    width: 100%;
    background: ${blackout};
    z-index: 100;
`;
const LoaderGIF = styled.img`
    height: 6rem;
    width: 6rem;
`;

const LoaderComponent = props => {
        return (
            <LoaderContainer viewport={props.appState.viewportHeightPx}>
                <LoaderGIF src={loader} alt="loader"/>
            </LoaderContainer>
        );
};
export default LoaderComponent;
