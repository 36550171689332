import React, { Component } from 'react';
import styled from 'styled-components';
import {Button, TextH4} from "../../variables/StyledComponents";

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 3.8rem;
    padding: 1rem 0.9rem;
`;
const H4 = styled(TextH4)`
    width: 100%;
    margin-bottom: 3rem;
    align-self: center;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

class BookingHasChangedWarningComponent extends Component {
    confirm = () => {
        document.documentElement.scrollIntoView();
        this.props.setAppState(this.props.appState.renderBookingHasChangedWarning);
        this.props.setAppState({renderBookingHasChangedWarning: false});
    };
    reject = () => {
        document.documentElement.scrollIntoView();
        this.props.setAppState({renderBookingHasChangedWarning: false});
    };
    render() {
        return (
            <ComponentContainer>
                <H4>
                    Du har ändrat fordon eller produkt. Om du går vidare kommer din bokade tid att tas bort och du får
                    välja en ny. <br/> <br/> Vill du gå vidare? <br/> <br/> Observera att du måste bekräfta din nya
                    bokning för att ändringarna ska slå igenom.
                </H4>
                <ButtonContainer>
                    <Button next onClick={this.confirm} onKeyDown={this.handleKeyPress}>Gå vidare</Button>
                    <Button onClick={this.reject} onKeyDown={this.handleKeyPress}>Ångra</Button>
                </ButtonContainer>
            </ComponentContainer>
        );
    }
}
export default BookingHasChangedWarningComponent






























