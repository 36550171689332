import axios from 'axios';
import { URLLocation } from './variables/StringVariables';

export const getVehicleAPI = regNr => {
    return axios.get(URLLocation() + '/bb-web/api/v2/vehicles/' + regNr + '/summarywithproducts');
};

export const getShopsAPI = currentBookingId => {
    return axios.get(URLLocation() + '/bb-web/api/booking/' + currentBookingId + '/shops');
};

//getTimeslots start
export const getTimeslotsAPI = (choosenShop, bookingItem, displayedMonth, get31Days, get28Days) => {
    const timeslotItem = prepareTimeslotRequestItem(choosenShop, bookingItem, displayedMonth, get31Days, get28Days);
    return axios.get(URLLocation() + '/bb-web/api/v2/shops/timeslots?' + timeslotItem.shopIdOrArea +
        '&forBooking=' + timeslotItem.bookingId +
        '&fromDate=' + timeslotItem.fromDate +
        '&toDate=' + timeslotItem.toDate);
};

const prepareTimeslotRequestItem = (choosenShop, bookingItem, displayedMonth, get31Days, get28Days) => {
    const timeslotItem = {};
    timeslotItem.shopIdOrArea = choosenShop.id ? 'shopId=' + choosenShop.id : 'area=' + choosenShop.name;
    if (get31Days) {
        timeslotItem.fromDate = displayedMonth.toISO().substring(0, 10);
        timeslotItem.toDate = displayedMonth.plus({ days: 30 }).toISO().substring(0, 10);
    }
    else if (get28Days) {
        timeslotItem.fromDate = displayedMonth.toISO().substring(0, 10);
        timeslotItem.toDate = displayedMonth.plus({ days: 27 }).toISO().substring(0, 10);
    }
    //Get timeslots for an entire month.
    else {
        timeslotItem.fromDate = displayedMonth.toISO().substring(0, 10);
        timeslotItem.toDate = displayedMonth.endOf('month').toISO().substring(0, 10);
    }
    timeslotItem.bookingId = bookingItem.id ? bookingItem.id : bookingItem.originalId;
    return timeslotItem;
};
//getTimeslots end

export const getBookingAPI = bookingId => {
    return axios.get(URLLocation() + '/bb-web/api/booking/' + bookingId)
};

export const postBookingAPI = booking => {
    return axios.post( URLLocation() + '/bb-web/api/booking/', booking)
};

export const putBookingAPI = (currentBookingId, booking) => {
    return axios.put(URLLocation() + '/bb-web/api/booking/' + currentBookingId, booking)
};

//putBookingTimeslot start
export const putTimeslotAPI = (currentBookingId, currentBookingItemId, bookingItem) => {
    return axios.put(URLLocation() + '/bb-web/api/booking/' + currentBookingId + '/item/' + currentBookingItemId, bookingItem)
};
//putBookingTimeslot end

//postPayment start
export const postPaymentAPI = currentBookingId => {
    return axios.post( URLLocation() + '/bb-web/api/booking/' + currentBookingId + '/pay/')
};
//postPayment end

//getKlarnaReceipt start
export const getKlarnaReceiptAPI = (currentBookingId, checkoutId) => {
    return axios.get(URLLocation() + '/bb-web/api/booking/' + currentBookingId + '/receipt/' + checkoutId)
};
//getKlarnaReceipt end

//postContactInformation start
export const postContactInformationAPI = (currentBookingId, contacts) => {
    return axios.post(URLLocation() + '/bb-web/api/booking/' + currentBookingId + '/contactinformation/', contacts)
};
//postContactInformation end

//getEditBooking start
export const getEditBookingAPI = (bookingReference, orderReference) => {
    return axios.get( URLLocation() + '/bb-web/api/booking/?bookingReference=' + bookingReference + '&orderReference=' + orderReference)
};
//getEditBooking end

//getRebooking start
export const getRebookingAPI = currentBookingItemId => {
    return axios.get(URLLocation() + '/bb-web/api/booking/' + currentBookingItemId + '/rebook/')
};
//getRebooking end

//getPreliminaryBooking start
export const postPreliminaryBookingAPI = registrationNumber => {
    return axios.post(URLLocation() + '/bb-web/api/booking/' + registrationNumber + '/preliminary/')
};
//getPreliminaryBooking end

//postResendConfirmationEmail start
export const postResendConfirmationEmailAPI = (registrationNumber, email) => {
    return axios.post(URLLocation() + '/bb-web/api/booking/' + registrationNumber + '/resendConfirmationEmail/', email)
};
//postResendConfirmationEmail end

//cancelBooking start
export const putBookingCanceledAPI = (currentBookingId) => {
    return axios.put(URLLocation() + '/bb-web/api/booking/' + currentBookingId + '/cancel/')
};
//cancelBooking end
