import styled, {css} from 'styled-components';
import {CSSCommon} from "./CSSVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey         	= CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const white         = CSSCommon.color.white;
const yellow        = CSSCommon.color.yellow;
const maxRowWidth   = CSSCommon.maxRowWidth;
const tabletMin   	= CSSCommon.mediaBreakpoints.tabletMin;

export const Button = styled.button`
    max-width: 15.7rem;
    width: calc(50% - 0.5rem);
    height: 5rem;
    background: ${white};
    color: ${green};
    border: solid 0.1rem ${green};
    font-size: ${CSSCommon.fontSize.button};
    font-weight: ${CSSCommon.fontWeight.heavy};
    cursor: pointer;
    @media(${tabletMin}) {
        &:hover{
            background: ${hover};
            border: none;
        }    
    } 
    ${props => props.next | props.onlyNext && css`
		background: ${green};
		color: ${yellow};
		border: none;
        @media(${tabletMin}) {
            &:hover{
              background: ${yellow};
              color: ${green};
            }       
        }
	`}
    ${props => props.onlyNext && css`
		max-width: ${maxRowWidth};
        width: 100%;
    `}
    ${props => props.disabled && (props.next || props.onlyNext) && css`
        opacity: .15;
        cursor: default;
        &:hover{
              background: ${green};
              color: ${yellow};
            }
    `}
    ${props => props.disabled && !(props.next || props.onlyNext) && css`
        cursor: default;
        opacity: .15;
        &:hover{
        	cursor: default;
            background: ${white};
            color: ${green};
    		border: solid 0.1rem ${green};
        }
    `}
`;
export const Input = styled.input`
    width: calc(100% - 1.2rem);
    height: 2.9rem;
    padding-left: 1rem;
    border: solid 0.1rem ${grey};
    border-radius: 0.3rem;
    background: ${grey};
    font-size: ${CSSCommon.fontSize.h5};
    transition: border-color .15s, background .15s;
    ${props => !props.isValid && css`
        border-color: red;
        background: #e4cbcb;
        transition: border-color .15s, background .15s;
    `}
`;
export const Label = styled.label`
    display:inline-block;
    height: 1.5rem;
    font-size: 1rem;
    color: darkred;
    overflow: hidden;
    transition: height .15s;
    ${props => props.isValid && css`
        height: 0;
        transition: height .15s;
    `}
`;
export const SVGLabel = styled.svg`
    width: 1.5rem;
    height: 1rem;
    margin-right: 0.3rem;
    fill: red;
`;
export const SVGClose = styled.button`
    width: 1.6rem;
    height: 1.6rem;
    stroke-width: 0;
    fill: ${green};
    stroke: ${green};				
    cursor: pointer;
    z-index: 100;
    &:hover{
        fill: ${yellow};
        stroke: ${yellow};
    }
`;
export const SVGContainer = styled.svg`
    height: 1.8rem;
    width: 2.4rem;
`;

export const TextH3 = styled.h3`
    font-size: ${CSSCommon.fontSize.h3};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
`;
export const TextH4 = styled.h4`
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
`;
export const TextH5 = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
`;
export const TextH6 = styled.h6`
    font-size: ${CSSCommon.fontSize.h6};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
`;