import React from 'react';
import styled, { css } from 'styled-components';
import { stringBuilderDate } from '../../resource-functions/HelperFunctions';
import { SVG } from "../../svg/SVG.js"
import { CSSCommon } from "../../variables/CSSVariables";
import { TextH3, TextH5, TextH6, SVGContainer } from "../../variables/StyledComponents";

const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 27.8rem;
    max-width: 30.2rem;
    min-height: 3.8rem;
    border: solid 0.1rem ${green};
    padding: 1rem;
    margin-bottom: 2rem;
    @media(${tabletMin}){
        min-height: 5.2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
`;
const SubsectionContainer = styled.div`
    display: flex;
    max-width: 32.4rem;
    width: 100%;
    ${props => props.first && css`
        border-bottom: solid 0.1rem ${grey};
    `}
`;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 0;
    ${props => props.first && css`
        padding-top: 0;
        padding-bottom: 1rem;
    `}
`;
const H5 = styled(TextH5)`
    margin-top: 0.5rem;
`;
const H6 = styled(TextH6)`
    padding: 0.2rem 0 0rem 0;
`;
const SVGCont = styled(SVGContainer)`
    margin-top: 1rem;
`;

const StationAndTimeComponent = props => {

    const formatAreaText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    return (
        <ComponentContainer>
            <SubsectionContainer first>
                <SVGCont>
                    {SVG.garage}
                </SVGCont>
                <TextContainer first>
                    <TextH3>{formatAreaText(props.appState.choosenShop.name)}</TextH3>
                    <H5>{formatAreaText(props.appState.choosenShop.addressLine1)}, {formatAreaText(props.appState.choosenShop.area)}</H5>
                    <H6>Max fordonshöjd: {props.appState.choosenShop.maxHeight / 1000} m</H6>
                </TextContainer>
            </SubsectionContainer>
            <SubsectionContainer>
                <SVGCont>
                    {SVG.clock}
                </SVGCont>
                <TextContainer>
                    <TextH3>{stringBuilderDate(props.appState.booking.items[0].startTime)}</TextH3>
                </TextContainer>
            </SubsectionContainer>
        </ComponentContainer>
    );
};
export default StationAndTimeComponent






























