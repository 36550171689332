import React, { Component } from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {SVGClose, TextH5} from "../../variables/StyledComponents";
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const white         = CSSCommon.color.white;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const desktopMinInt = CSSCommon.mediaBreakpointsInt.desktopMin;

const ComponentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 2rem);
    max-width: calc(${desktopMinInt}px - 2rem);
    min-height: 3.4rem;
    padding: 0 1rem;
    color: ${white};
    fill: ${white};
    cursor: pointer;
    @media (${tabletMin}) {  
        width: calc(100% - 4rem);
        max-width: calc(${desktopMinInt}px - 4rem);
        padding: 0 2rem;
    }
    @media print{   
        display: none;
    }
    ${props => props.alertType && css`
        background: ${CSSCommon.color[props.alertType]};
        //'ba' is problematic with EDGE
        //background: linear-gradient(${CSSCommon.color[props.alertType]}, ${CSSCommon.color[props.alertType]}ba);     
    `}
`;
const AlertTextH5 = styled(TextH5)`  
    font-size: 1.1rem;
    flex-grow: 1;
    color: ${white};
    padding: 0.5rem 1rem;
    cursor: pointer;
    @media (${tabletMin}) {  
        font-size: 1.2rem;
    }
`;
const AlertSVG = styled.svg`
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
    fill: ${white};
    stroke:  ${white};
`;
const AlertSVGClose = styled(SVGClose)`
    flex-shrink: 0;
    fill: ${white};
    stroke:  ${white};
    z-index: 9;
    &:hover{
        fill: ${white};
    }
`;

class AlertMessageComponent extends Component {

    alterState = type => {
        const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
        renderAlert[type] = false;
        this.props.setAppState((prevState) => ({
            renderAlert: renderAlert
        }));
    };
    componentDidMount(){
        document.documentElement.scrollTop = 0;
    }

    render(){
        const {appState} = this.props;
        return (
            <ComponentContainer
                className="alert-message"
                onClick={this.alterState.bind(this, this.props.alertType)}
                alertType={this.props.alertType}
            >
                <AlertSVG>
                    {SVG.alert[this.props.alertType]}
                </AlertSVG>
                <AlertTextH5>{appState.alertMessage[this.props.alertType]}</AlertTextH5>
                <AlertSVGClose>
                    {SVG.close}
                </AlertSVGClose>
            </ComponentContainer>
        );
    }
};


export default AlertMessageComponent;