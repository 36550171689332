import React, { Component } from 'react';
import { DateTime } from 'luxon';
import _ from 'underscore';
import styled, {css} from 'styled-components';
//Styled components
import { stringBuilderDay, stringBuilderMonth } from '../../../resource-functions/HelperFunctions';
import {TextH4} from "../../../variables/StyledComponents";
import {CSSCommon} from "../../../variables/CSSVariables";
import loader from '../../../pictures/Dual_Ring_yellow_06_opacity.gif'

import Timeslot from "./Timeslot";
import FixedHeaderComponent from "./FixedHeaderComponent";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;

const ComponentContainer = styled.div`
    max-height: calc(${props => props.viewport}px - 9.4rem);
    padding-right: 0.1rem;
    overflow: auto;
    transition-property: height;
    transition-duration: .5s;
`;
const DayContainer = styled.div`
`;
const SubContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
`;
const ShowMore = styled.button`
    width: 100%;
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
    text-align: center;
    padding: 1.6rem 0 1.4rem 0;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    ${props => props.disabled && css`
        cursor: default;
        &:hover {
            text-decoration: none;
        }
    `}
`;
const NoTimeslotMessage = styled(TextH4)`
    color: ${green};
    padding: 1rem;
`;
const LoaderGIF = styled.img`
    height: 3rem;
    width: 3rem;
    margin: -1rem 0;
`;

class FirstFreeAndCheapestTimeslotContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.isFirstFree = false;
    };
    getMoreTimeslots = () => {
        if(!this.props.appState.renderTimeslotLoader){
            const timeslots = this.props.appState.timeslots;
            let newMonth = DateTime.fromISO(timeslots[timeslots.length - 1].date);
            newMonth = newMonth.plus({days: 1});
            this.props.setAppState({}, {type: "getTimeslots", payload: {displayedMonth: newMonth, get31Days: true}});
        }
    };
    getInitialTimeslots = () => {
        this.props.setAppState({timeslots:[]}, {type: "getTimeslots", payload: {displayedMonth: DateTime.local(), get31Days: true}});
    };
    stringBuilder = (date) => {
        const newDate = DateTime.fromISO(date);
        return `${stringBuilderDay(newDate)} ${newDate.day} ${stringBuilderMonth(newDate)} (vecka ${newDate.weekNumber})`;
    };
    sortTimeslotsByPrice = () => {
        let timeslots = [];
        //Put all timeslots in same array
        this.props.appState.timeslots.forEach((date, idx)=> {
            //Idx caps how many days are displayed
            if(idx < 28) {
                timeslots.push(...date.timeSlots);
            }
        });
        //Sort by price
        timeslots = _.sortBy(timeslots, 'price');
        const newTimeslots = [];
        timeslots.forEach((timeslot)=>{
            if(newTimeslots.length === 0){
                newTimeslots.push({date: timeslot.interval.start.slice(0, 10), timeSlots: [timeslot]})
            } else if(timeslot.interval.start.slice(0, 10) === newTimeslots[newTimeslots.length - 1].date){
                newTimeslots[newTimeslots.length - 1].timeSlots.push(timeslot);
            } else if(timeslot.interval.start.slice(0, 10) !== newTimeslots[newTimeslots.length - 1].date) {
                newTimeslots.push({date: timeslot.interval.start.slice(0, 10), timeSlots: [timeslot]})
            }
        });
        return newTimeslots
    };
    renderTimeslots = (date, id, appState, setAppState) => {
        const firstFreeIdx = id;
        //To render no free timeslot message:
        if(date.length < 1) {
            return <NoTimeslotMessage>Inga lediga tider</NoTimeslotMessage>;
        }
        return(
            date.map((timeslot, idx)=> {
                const firstFree = this.props.firstFree && idx === 0 && this.firstFreeIdx;
                if(firstFree){ this.isFirstFree = firstFreeIdx }
                if(this.props.appState.choosenTimeslot.interval && timeslot.interval.start === this.props.appState.choosenTimeslot.interval.start){
                    this.isFirstFree = id;
                }
                this.firstFreeIdx = false;
                // const cheapest = timeslot.price === lowestPrice && this.props.cheapest;
                const cheapest = this.props.cheapest && id === 0 && idx === 0;
                return <Timeslot key={idx}
                                 cheapest={cheapest}
                                 firstFree={firstFree}
                                 timeslot={timeslot}
                                 {...{appState, setAppState}}/>
            })
        )
    };
    renderDays = (appState, setAppState) => {
        this.firstFreeIdx = true;

        this.timeslots = this.props.firstFree ? this.props.appState.timeslots : this.sortTimeslotsByPrice();

        if(this.timeslots.length > 0){
            return(
                this.timeslots.map((timeslots, idx)=>{
                    return(
                        <DayContainer id={idx} key={idx}>
                            <FixedHeaderComponent
                                hidden={idx === 0}
                                text={this.stringBuilder(timeslots.date)}
                            />
                            <SubContainer>
                                {this.renderTimeslots(timeslots.timeSlots, idx, appState, setAppState)}
                            </SubContainer>
                        </DayContainer>
                    )
                })
            );
        } else if(!appState.renderTimeslotLoader) {
            return <NoTimeslotMessage>Inga lediga tider</NoTimeslotMessage>;
        }
    };
    scrollIntoView = () => {
        if(this.props.appState.choosenTimeslot.price && this.isFirstFree){
            document.getElementById(this.isFirstFree.toString()).children[1].scrollIntoView();
            this.scrollIntoView = false;
        } else if(this.props.appState.timeslots.length > 0 && this.isFirstFree){
            // Subtract 1 in order to show that first day has no available times
            document.getElementById(this.isFirstFree.toString() - 1).children[1].scrollIntoView();
            this.scrollIntoView = false;
        }
    };
    handleScroll = () => {
        let notInView = 1;
        while(notInView !== false){
            if(notInView >= this.timeslots.length
                || (document.getElementById(notInView).children[0].getBoundingClientRect().bottom >= this.containerTop + 5))
            {
                if(this.timeslots[notInView - 1] && this.state.fixedHeader !== this.timeslots[notInView - 1].date){
                    this.setState({fixedHeader: this.timeslots[notInView - 1].date});
                    this.props.setAppState({fixedHeaderText: this.timeslots[notInView - 1].date});
                }
                notInView = false;
            } else {
                notInView += 1;
            }
        }
    };
    getFixedHeaderText = () => {
        if(this.props.appState.fixedHeaderText){
            return this.stringBuilder(this.props.appState.fixedHeaderText);
        } else if(this.timeslots[0]){
            return this.stringBuilder(this.timeslots[0].date);
        }
    };
    componentDidUpdate(){
        if(this.scrollIntoView){
            this.scrollIntoView();
        }
        const containerTop = document.getElementById('first-free-and-cheapest-timeslot').getBoundingClientRect().top;
        if(this.containerTop !== containerTop){
            this.containerTop = containerTop;
        }
    }
    componentDidMount(){
        this.scrollIntoView();
        if(this.props.appState.timeslotTicker > 1){
            this.getInitialTimeslots();
        }
        this.containerTop = document.getElementById('first-free-and-cheapest-timeslot').getBoundingClientRect().top;
        document.getElementById('first-free-and-cheapest-timeslot').addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount(){
        this.props.setAppState({timeslots:[], timeslotTicker: 2, fixedHeaderText: false});
        document.getElementById('first-free-and-cheapest-timeslot').removeEventListener('scroll', this.handleScroll);
    }
    shouldComponentUpdate(nextProps) {
        if (this.props.appState.timeslots !== nextProps.appState.timeslots) {
            return true;
        }
        if (this.props.appState.renderTimeslotLoader !== nextProps.appState.renderTimeslotLoader) {
            return true;
        }
        if (this.props.appState.viewportHeightPx !== nextProps.appState.viewportHeightPx) {
            return true;
        }
        if (this.props.appState.fixedHeaderText !== nextProps.appState.fixedHeaderText) {
            return true;
        }
        return false;
    };
    render() {
        const {appState, setAppState} = this.props;
        const text = appState.renderTimeslotLoader ? <LoaderGIF src={loader} alt="loader"/> : 'Visa fler';
        const days = this.renderDays(appState, setAppState);
        const showMore = this.props.firstFree || appState.renderTimeslotLoader
            ? <ShowMore disabled={appState.renderTimeslotLoader} onClick={this.getMoreTimeslots}>{text}</ShowMore>
            : null;
        return (
            <DayContainer>
                <FixedHeaderComponent
                    text={this.getFixedHeaderText()}
                />
                <ComponentContainer
                    id='first-free-and-cheapest-timeslot'
                    viewport={this.props.appState.viewportHeightPx}
                >
                    {days}
                    {showMore}
                </ComponentContainer>
            </DayContainer>
        );
    }
}
export default FirstFreeAndCheapestTimeslotContainer;
















