import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {TextH4} from "../../../variables/StyledComponents";
import {SVG} from "../../../svg/SVG.js"
import {CSSCommon} from "../../../variables/CSSVariables";

const green     = CSSCommon.color.green;
const grey      = CSSCommon.color.grey;
const hover     = CSSCommon.color.hover;
const white     = CSSCommon.color.white;
const yellow    = CSSCommon.color.yellow;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4.5rem;
    cursor: pointer;
    fill: ${green};
    z-index: 200;
    @media(${mobileMax}){
        margin-bottom: 2.5rem;
    }
    ${props => props.displayDropdown && css`
        @media(${mobileMax}){
            position: absolute;
            top: -22%;
            left: 0;
        }
    `}
`;
const DropdownButtonContainer = styled.button`
    display: flex;
    height: 5rem;
    padding: 0 1rem;
    border: 0.1rem solid ${grey};
    align-items: center;
    justify-content: space-between;
    background-color: ${white};
    cursor: pointer;
    z-index: 10;
    &:hover{
        fill: ${yellow};
    }
    ${props => !props.displayDropdown && css`
        &:hover{
            background-color: ${hover};
            border-color: ${hover};
            fill: ${yellow};
        }
    `}
    ${props => props.displayDropdown && css`
        border-color: ${green};
        @media(${mobileMax}){
            border: none;
            border-bottom: 0.1rem solid ${green};
        }
    `}
`;
const DropdownItemContainer = styled.div`
    position: absolute;
    top: 26.6rem;
    left: 1rem;
    width: calc(${props => props.width}px - 1.21rem);
    max-height: calc(${props => props.height}px - 2rem);
    background-color: ${white};
    border: 0.1rem solid ${green};
    border-top: none;
    padding: 0.5rem;
    overflow: auto;
    cursor: pointer;
    ${props => props.displayDropdown && css`
        @media(${mobileMax}){
            position: initial;
            top: 5rem;
            left: 0;
            width: auto;
            max-height: none;
            border: none;
        }
    `}
`;
const H4 = styled(TextH4)`
    color: ${green};
    cursor: pointer;
`;
const H4ListItem = styled.button`
    width: 100%;
    text-align: left;
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${green};
    cursor: pointer;
    padding: 0.5rem;
    &:focus{
        background-color: ${hover}; 
        outline: none;
    }
    &:hover{
        background-color: ${hover};
    }
`;
const SVGDisplaySearch = styled.div`
    width: 1.6rem;
    height: 0.9rem;
    margin-left: auto;
    ${props => props.displayDropdown && css`
        align-self: center;
        transform: rotate(180deg);
    `}
`;

class DropdownComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            choosenVehicleKind: '',
            selectedIdx: -1,
            activeShop: '',
            visibleIndex: [],
            renderDropdown: false,
            top: 0
        };
    }
    chooseVehicleKind = vehicle => {
        this.props.setAppState({choosenVehicleTsUnavailable: vehicle});
    };
    toggleDropdown = () => {
        this.setState({renderDropdown: !this.state.renderDropdown});
    };
    renderDropdownItemContainer = () => {
        const dropdownItems = this.props.appState.vehicleTsUnavailable.vehicleKinds.map((vehicleKind, idx)=>{
            return <H4ListItem key={idx} onClick={this.chooseVehicleKind.bind(this, vehicleKind)}>{vehicleKind.text}</H4ListItem>
        });
        return (
            <DropdownItemContainer
                displayDropdown={this.state.renderDropdown}
                width={this.state.width}
                height={this.state.height}
                onMouseEnter={this.onMouseEnter}
            >
                {dropdownItems}
            </DropdownItemContainer>
        )

    };
    setDropdownHeight = () => {
        const clientRect = document.getElementById('dropdown-container').getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        return viewportHeight - clientRect.bottom;
    };
    setDropdownSize = () => {
        const clientRect = document.getElementById('dropdown-container').getBoundingClientRect();
        const dropdownHeight = this.setDropdownHeight();
        if(clientRect.bottom !== this.state.top
            || clientRect.left !== this.state.left
            || this.state.width !== clientRect.width
            || this.state.height !== dropdownHeight)
        {
            this.setState({
                top: clientRect.bottom,
                left: clientRect.left,
                width: clientRect.width,
                height: dropdownHeight
            });
        }
    };
    componentDidUpdate(){
        this.setDropdownSize();
    }
    componentDidMount(){
        this.setDropdownSize();
        window.addEventListener('resize', this.setDropdownSize, {passive: true});
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setDropdownSize);
    }
    render() {
        const dropdownItemContainer = this.state.renderDropdown ? this.renderDropdownItemContainer() : null;
        const text = this.props.appState.choosenVehicleTsUnavailable ? this.props.appState.choosenVehicleTsUnavailable.text : 'Välj fordonstyp';
        return (
            <ComponentContainer
                id='dropdown-container'
                viewport={this.props.appState.viewportHeightPx}
                displayDropdown={this.state.renderDropdown}
                onClick={this.toggleDropdown}
            >
                <DropdownButtonContainer displayDropdown={this.state.renderDropdown}>
                    <H4>{text}</H4>
                    <SVGDisplaySearch displayDropdown={this.state.renderDropdown}>
                        {SVG.dropdownArrow}
                    </SVGDisplaySearch>
                </DropdownButtonContainer>
                {dropdownItemContainer}
            </ComponentContainer>
        );
    }
}
export default DropdownComponent;
