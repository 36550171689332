import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { CSSCommon } from "../../variables/CSSVariables";
import { TextH4/*, TextH5*/ } from "../../variables/StyledComponents";

import StageHeaderComponent     from "../StageHeaderComponent";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
// const hover         = CSSCommon.color.hover;
// const yellow        = CSSCommon.color.yellow;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 30rem;
    max-width: 32.4rem;
    margin-bottom: 2rem;
`;
const SubsectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border: solid 0.1rem ${green};
    @media(${tabletMin}){
        padding: 1.5rem 1rem;
    }
`;
const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 1.7rem;
    margin-bottom: 1rem;
    overflow: hidden;
    transition: margin .15s;
    ${props => props.bottom && css`
        padding-top: 1rem;
        margin-bottom: 0;
        border-top: solid 0.1rem ${grey};
    `}
    ${props => props.hideText && css`
        height: 0;
        margin: 0;
        transition: height .15s, margin .15s;
    `}    
`;
const H4Heavy = styled(TextH4)`
    font-weight: ${CSSCommon.fontWeight.heavy};
    color: ${green};
    transition: font-size .15s;
    ${props => props.black && css`
        color: ${black};
    `}
`;
/* Future feature:
const DiscountContainer = styled.div`
    display: flex;
    width: calc(100% - 4rem);
    border: solid 0.1rem ${grey};
    border-top: none;
    padding: 1rem;
    fill: ${green};
    cursor: pointer;
    @media(${tabletMin}){
        &:hover {
            background-color: ${hover};
            border-color: ${hover};
            fill: ${yellow};
        }
    }
    ${props => props.active && css`
        border: solid 0.1rem ${green};
    `}
`;
const SVGPlus = styled.svg`
    width: 1.4rem;
    height: 1.4rem;
`;
const H5 = TextH5.extend`
    flex-grow: 1;
    margin-left: 1rem;
    cursor: pointer;
`;
*/

class SummeryComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            discount: 0
        };
    }
    getTotalPriceAndDiscount = () => {
        let totalPrice = 0;
        let discount = 0;
        let bookingDiscount = 0;

        this.props.appState.booking.items.forEach((item) => {
            totalPrice += item.price;
            discount += item.discount;
            bookingDiscount += item.bookingDiscount;
        });
        return {...{totalPrice, discount, bookingDiscount}}
    };
    renderProducts = () => {
        this.totalPrice = 0;
        this.discount = 0;
        return(
            this.props.appState.booking.items.map((item) => {
                return(
                    item.products.map((product, idx) =>{
                        this.totalPrice += product.price;
                        this.discount = product.discount > this.discount ? product.discount : this.discount;
                        return(
                            <TextContainer key={idx}>
                                <TextH4>{product.shortDescription}</TextH4>
                                <H4Heavy>{product.price}kr</H4Heavy>
                            </TextContainer>
                        )
                    })
                )
            })
        );
    };
    renderDiscount = (discount, bookingdiscount) => {
        const otherDiscount = discount - bookingdiscount;

        const hasBookingDiscount = bookingdiscount !== 0;
        const payWithKlarna = this.props.appState.payWithKlarna;
        const hasOtherDiscount = otherDiscount !== 0;

        return(
            <>
                <TextContainer hideText={!hasBookingDiscount}>
                    <TextH4>Bokningsrabatt</TextH4>
                    <H4Heavy>- {bookingdiscount}kr</H4Heavy>
                </TextContainer>

                <TextContainer hideText={!payWithKlarna || !hasOtherDiscount}>
                   <TextH4>Förbetalningsrabatt</TextH4>
                   <H4Heavy>- {otherDiscount}kr</H4Heavy>
                </TextContainer>
            </>
        )
    };
    renderPseudoDiscount = discount => {
        const hasDiscount = this.props.appState.payWithKlarna && discount !== 0;
        return(
            <TextContainer hideText={hasDiscount}/>
        )
    };
    componentDidMount(){
        this.getTotalPriceAndDiscount();
    }
    render() {
        const {appState, setAppState} = this.props;
        const totalPrice = this.getTotalPriceAndDiscount().totalPrice;
        const discount = this.getTotalPriceAndDiscount().discount;
        const bookingdiscount = this.getTotalPriceAndDiscount().bookingDiscount;

        const totalPriceWithDiscount = appState.payWithKlarna ? totalPrice - discount : totalPrice - bookingdiscount;
        const stageHeaderText = appState.payWithKlarna ? appState.stage3.header5.klarna : appState.stage3.header5.payAtStation;
        return (
            <ComponentContainer isMobile={this.props.isMobile}>
                <StageHeaderComponent headerNr={stageHeaderText} {...{appState, setAppState}}/>
                <SubsectionContainer>
                    {this.renderProducts()}
                    {this.renderDiscount(discount, bookingdiscount)}
                    <TextContainer bottom>
                        <H4Heavy black>Totalt</H4Heavy>
                        <H4Heavy>{totalPriceWithDiscount}kr</H4Heavy>
                    </TextContainer>
                </SubsectionContainer>
                {this.renderPseudoDiscount(discount)}
                {/*Future feature:*/}
                {/*<DiscountContainer>*/}
                    {/*<SVGPlus>*/}
                        {/*{SVG.plus}*/}
                    {/*</SVGPlus>*/}
                    {/*<H5>Lägg till rabattkod</H5>*/}
                {/*</DiscountContainer>*/}
            </ComponentContainer>
        );
    }
}
export default SummeryComponent






























