import React from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {TextH5} from "../../variables/StyledComponents";
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const white         = CSSCommon.color.white;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const desktopMinInt = CSSCommon.mediaBreakpointsInt.desktopMin;

const ComponentContainer = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
    max-width: ${desktopMinInt}px;
    min-height: 3.4rem;
    padding: 0 1rem;
    color: ${white};
    fill: ${white};
    @media (${tabletMin}) {
        padding: 0 2rem;
    }
    @media print{   
        display: none;
    }
    ${props => props.alertType && css`
        background: linear-gradient(${CSSCommon.color[props.alertType]}, ${CSSCommon.color[props.alertType]}ba);     
    `}
`;
const AlertTextH5 = styled(TextH5)`  
    font-size: 1.1rem;
    flex-grow: 1;
    color: ${white};
    padding: 0.5rem 0 0.5rem 1rem;
    @media (${tabletMin}) {  
        font-size: 1.2rem;
    }
`;
const AlertSVG = styled.svg`
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
    fill: ${white};
    stroke:  ${white};
    cursor: default;
`;

const PersistentAlertMessageComponent = (props) => {
    const {appState} = props;

    const onClick = () => {
        if(props.releaseBanner){
            window.location = window.location.origin + '/bb-web/webBooking';
        }
    };

    return (
        <ComponentContainer
            alertType={props.svg}
            onClick={onClick}
            tabIndex='0'
        >
            <AlertSVG>
                {SVG.alert[props.svg]}
            </AlertSVG>
            <AlertTextH5>{appState.alertMessage[props.alertType]}</AlertTextH5>
        </ComponentContainer>
    );
};

export default PersistentAlertMessageComponent;