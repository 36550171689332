import React, { Component } from 'react';
import { DateTime } from 'luxon';
import styled, {css} from 'styled-components';
//Styled components
import {TextH5, TextH6} from "../../../variables/StyledComponents";
import {CSSCommon} from "../../../variables/CSSVariables";

import CalendarDay from "./Day";

const green         = CSSCommon.color.green;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: solid 0.1rem ${green};
    overflow: hidden;
    transition-property: height;
    transition-duration: .5s;
    @media(${mobileMax}){
        height: ${props => props.height};
    }
    ${props => props.toggle && css`
        @media(${mobileMax}){
        height: 0;
        }
    `}
`;
const WeekdaysContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 1.5rem;
    border-bottom: solid 0.1rem ${green};
`;
const Weekdays = styled.div`
    display: flex;
    justify-content: space-between;
    width: 29.4rem;
    padding: 0 0 0.5rem 0.4rem;
    @media(${tabletMin}){
        width: 35.8rem;
    }
`;
const WeekdaysH6 = styled(TextH6)`
    flex-grow: 1;
`;
const CalendarContainer = styled.div`
    display: flex;
    @media(${tabletMin}){
        align-self: flex-start;
    }
`;
const DaysContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 29.4rem;
    min-height: 21.5rem;
    margin: 0.1rem 0.2rem;
    @media(${tabletMin}){
        width: 35.8rem;
    }
`;
const WeekNrContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 4.45rem;
`;
const WeekNr = styled(TextH5)`
    flex-grow: 1;
    color: ${green};   
    padding-top: 0.5rem; 
    padding-left: 0.9rem;
    padding-right: 0.9rem;
`;

Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
// Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
// January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
// Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

class Calendar extends Component {

    getWeeksInMonth = (year, month) => {
        const weeks = [];
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const daysInMonth = lastDay.getDate();
        let dayOfWeek = firstDay.getDay();
        let start;
        let end;

        for (let i = 1; i < daysInMonth + 1; i++) {
            if (dayOfWeek === 0 || i === 1) {
                start = i;
            }
            if (dayOfWeek === 6 || i === daysInMonth) {
                end = i;
                if (start !== end) {
                    let d = new Date(year, month, start);
                    if (start === 2) {
                        let w = d.getWeek() - 1;
                        if (w === 0) w = this.getISOWeeks(year-1);
                        weeks.push({
                            weeknr: w,
                            start: 1,
                            end: 1
                        });
                    }
                    weeks.push({
                        weeknr: d.getWeek(),
                        start: start,
                        end: end
                    });
                }
            }
            dayOfWeek = new Date(year, month, i).getDay();
        }
        return weeks;
    }

    getISOWeeks = (y) => {
        var d,isLeap;

        d = new Date(y, 0, 1);
        isLeap = new Date(y, 1, 29).getMonth() === 1;

        return d.getDay() === 4 || isLeap && d.getDay() === 3 ? 53 : 52
    }

    constructor(props) {
        super(props);
        this.state = {
            todaysDate: DateTime.local(),
            height: ''
        };
        this.onClick = this.onClick.bind(this);
    };
    onClick = day => {
        const height = !this.props.appState.toggleCalendar
            ? document.getElementById('cal').getBoundingClientRect().height - 1
            : this.state.height;
        let toggleCalendar = this.props.appState.toggleCalendar;
        if(this.props.appState.viewportMobile){
            this.setState({height: height});
            toggleCalendar = !toggleCalendar;
        }
        this.props.setAppState({
            displayedMonth: DateTime.local(this.props.displayedMonth.year, this.props.displayedMonth.month, day),
            toggleCalendar: toggleCalendar
        });
    };
    calculateElementsHeights = () => {
        //Calculates height for all elements that make up calendar
        //Needed to have a set height for animations
        this.weekdayHeight = document.getElementById('cal-weekdays').getBoundingClientRect().height;
        this.dayHeight = document.getElementById('cal-day').getBoundingClientRect().height;
        const dayStyles = window.getComputedStyle(document.getElementById('cal-day'));
        const daysStyles = window.getComputedStyle(document.getElementById('cal-days'));
        const dayMargin = parseFloat(dayStyles['marginTop']) + parseFloat(dayStyles['marginBottom']);
        this.daysMargin = parseFloat(daysStyles['marginTop']) + parseFloat(daysStyles['marginBottom']);
        this.totalDayHeight = this.dayHeight + dayMargin;
    };
    calculateCalendarHeight = () => {
        //Calculates height of calendar
        //Needed to have a set height for animations
        const numberOfWeeks = this.numberOfDays / 7;
        const calendarHeight = (numberOfWeeks * this.totalDayHeight) + this.daysMargin + this.weekdayHeight;
        if(calendarHeight) {
            return calendarHeight + 'px';
        } else {
            return 'auto';
        }
    };
    daysInMonthView = (year, month) => {
        let arrayWith35Days = [];
        const weekday = this.firstWeekdayInMonth(year, month);
        if(weekday > 1) {
            //Adds the first greyed out days to the month view from previous month. Ie 29 30 31 1 2 3 4...
            const lastDayInPreviousMonth = this.lastDayInPreviousMonth(year, month);
            for(let i = 2 ; i <= weekday ; i++){
                const day = lastDayInPreviousMonth - (weekday - i);
                arrayWith35Days.push(day);
            }
            //Adds the last greyed out days to the month view from next month. Ie ...28 30 1 2 3 4
            arrayWith35Days = arrayWith35Days.concat(this.daysInMonth(year,month));
            if(arrayWith35Days.length < 35) {
                for(let i = 1 ; arrayWith35Days.length < 35 ; i++){
                    arrayWith35Days.push(i);
                }
            }
            if(weekday === 6 && this.daysInMonth(year,month).length === 31) {
                for(let i = 1 ; arrayWith35Days.length < 42 ; i++){
                    arrayWith35Days.push(i);
                }
            }
            if(weekday === 7) {
                for(let i = 1 ; arrayWith35Days.length < 42 ; i++){
                    arrayWith35Days.push(i);
                }
            }
        } else {
            arrayWith35Days = arrayWith35Days.concat(this.daysInMonth(year,month));
            if(arrayWith35Days.length < 35) {
                for(let i = 1 ; arrayWith35Days.length < 35 ; i++){
                    arrayWith35Days.push(i);
                }
            }
        }
        this.numberOfDays = arrayWith35Days.length;
        return arrayWith35Days;
    };
    firstWeekdayInMonth = (year, month) => {
        return DateTime.local(year,month,1).weekday;
    };
    lastDayInPreviousMonth = (year, month) => {
        if(month === 1){
            year -= 1;
            month = 12;
        } else {
            month -= 1;
        }
        return this.daysInMonth(year, month).pop();
    };
    daysInMonth = (year, month) => {
        const days = DateTime.local(year,month).daysInMonth;
        const daysArray = [];
        for(let i = 1; i <= days; i++){
            daysArray.push(i);
        }
        return daysArray;
    };
    getLowestPriceInDay = (year, month, day) => {
        let lowestPrice;
        const newMonth = month.toString().length === 1 ?  '0' + month.toString() : month;
        const newDay = day.toString().length === 1 ?  '0' + day.toString() : day;
        this.props.appState.timeslots.forEach((date)=>{
            if(date.date === (year +`-`+ newMonth +'-'+ newDay)){
                lowestPrice = date.lowestPrice;
            }
        });
        return lowestPrice;
    };
    renderDays = () => {
        const appState = this.props.appState;
        let activeDayToggle = false;
        const year = appState.displayedMonth.year;
        const month = appState.displayedMonth.month;
        const daysInMonthView = this.daysInMonthView(year, month);
        const isTodaysDate = DateTime.local().year === year && DateTime.local().month === month;
        const startOfMonthIdx = daysInMonthView.findIndex(idx => idx === 1);
        const endOfMonthIdx = daysInMonthView.lastIndexOf(1) > 15
            ? daysInMonthView.lastIndexOf(1) - 1
            : daysInMonthView[daysInMonthView.length - 1];
        return(
            daysInMonthView.map((day, idx) => {
                let lowestPrice;
                const todaysDay = DateTime.local().day;
                if(isTodaysDate){
                    if(!activeDayToggle && startOfMonthIdx <= idx && endOfMonthIdx >= idx && day >= todaysDay){
                        activeDayToggle = !activeDayToggle;
                    } else if (activeDayToggle && day === 1) {
                        activeDayToggle = !activeDayToggle;
                    }
                } else {
                    if(!activeDayToggle && day === 1){
                        activeDayToggle = !activeDayToggle;
                    } else if (activeDayToggle && day === 1) {
                        activeDayToggle = !activeDayToggle;
                    }
                }

                //Decides if day should be marked as choosen ie green
                const choosen = day === this.props.displayedMonth.day;
                let choosenMobile = appState.choosenTimeslot.interval
                    ? year === DateTime.fromISO(appState.choosenTimeslot.interval.start).year
                    : false;
                choosenMobile  = choosenMobile
                    ? month === DateTime.fromISO(appState.choosenTimeslot.interval.start).month
                    : false;
                choosenMobile  = choosenMobile
                    ? day === DateTime.fromISO(appState.choosenTimeslot.interval.start).day
                    : false;
                choosenMobile  = appState.viewportMobile ? choosenMobile : false;

                lowestPrice = activeDayToggle ? this.getLowestPriceInDay(year, month, day) : null;

                return <CalendarDay key={idx}
                            active={activeDayToggle}
                            choosen={choosen}
                            choosenMobile={choosenMobile}
                            day={day}
                            price={lowestPrice}
                            onClick={this.onClick.bind(this, day)}/>
            })
        )
    };
    renderWeekdays = () => {
        const days = ["Mån", "Tis", "Ons", "Tors", "Fre", "Lör", "Sön"];

        return days.map((day, idx) => {
            return <WeekdaysH6 key={idx} >{day}</WeekdaysH6>
        })
    };
    renderWeekNr = (isPseudo) => {
        const weekNrList = this.getWeeksInMonth(this.props.displayedMonth.startOf('year').year,this.props.displayedMonth.startOf('month').month-1)

        if(!isPseudo) {
            return(
                <WeekNrContainer>
                    <WeekNr>V {weekNrList[0].weeknr}</WeekNr>
                    <WeekNr>V {weekNrList[1].weeknr}</WeekNr>
                    <WeekNr>V {weekNrList[2].weeknr}</WeekNr>
                    <WeekNr>V {weekNrList[3].weeknr}</WeekNr>
                    <WeekNr>V {weekNrList[4] != null ? weekNrList[4].weeknr : weekNrList[3].weeknr + 1 }</WeekNr>
                    {this.numberOfDays > 35 ? <WeekNr>V {weekNrList[3].weeknr + 2}</WeekNr> : null }
                </WeekNrContainer>
            )
        } else {
            return <WeekNrContainer/>
        }
    };
    componentDidMount(){
        this.props.setAppState({initialCalendarRender: true});
        if(this.props.appState.choosenTimeslot.interval){
            this.props.setAppState({displayedMonth: DateTime.fromISO(this.props.appState.choosenTimeslot.interval.start)});
        }
        this.setState({height: document.getElementById('cal').getBoundingClientRect().height - 1});

        setTimeout(() => {
            this.calculateElementsHeights();
        }, 1000);
    }
    render() {
        const {appState} = this.props;
        const daysInWeek = this.renderWeekdays();
        const days = this.renderDays();
        const weekNr = window.innerWidth > 519 ? this.renderWeekNr() : null;
        const pseudoWeekNr = window.innerWidth > 519 ? this.renderWeekNr(true) : null;
        return (
            <ComponentContainer id="cal" toggle={appState.toggleCalendar} height={this.calculateCalendarHeight()}>
                <WeekdaysContainer id='cal-weekdays'>
                    <Weekdays>
                        {daysInWeek}
                    </Weekdays>
                </WeekdaysContainer>
                <CalendarContainer>
                    {weekNr}
                    <DaysContainer id='cal-days'>
                        {days}
                    </DaysContainer>
                    {pseudoWeekNr}
                </CalendarContainer>
            </ComponentContainer>
        );
    }
}
export default Calendar;
