import React, { Component } from 'react';
import styled from 'styled-components';
import {Button, TextH4} from "../variables/StyledComponents";

import SummeryComponent from "./stage4-component/SummeryComponent";
import SearchStationComponent from "./stage2-component/search-station-component/SearchStationContainer";

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 1.8rem);
    min-height: 3.8rem;
    padding: 0 0.9rem 1rem;
`;
const H4 = styled(TextH4)`
    width: 100%;
    margin-bottom: 1.5rem;
    align-self: center;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

class InquiryPopupComponent extends Component {
    confirm = () => {
        const activeStage = JSON.parse(JSON.stringify(this.props.appState.activeStage));
        activeStage.stage1 = true;
        activeStage.stage2 = false;
        activeStage.stage3 = false;
        activeStage.stage4 = null;
        this.props.setAppState({activeStage: activeStage, renderInquiryPopup: false});
    };
    reject = () => {
        const activeStage = JSON.parse(JSON.stringify(this.props.appState.activeStage));
        activeStage.stage1 = false;
        activeStage.stage2 = true;
        activeStage.stage3 = false;
        activeStage.stage4 = null;

        this.props.appState.booking.status = "NEW"

        this.props.setAppState({activeStage: activeStage, renderInquiryPopup: false});
    };
    render() {
        const {appState, setAppState} = this.props;
        return (
            <ComponentContainer>

                <SearchStationComponent {...{appState, setAppState}}/>

                <ButtonContainer>
                    <Button onClick={this.reject} onKeyDown={this.handleKeyPress}>Avbryt</Button>
                    <Button next onClick={this.confirm} onKeyDown={this.handleKeyPress}>Skicka förfrågan</Button>
                </ButtonContainer>
            </ComponentContainer>
        );
    }
}
export default InquiryPopupComponent






























