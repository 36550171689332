import { DateTime } from 'luxon';

export const stringBuilderDay = date => {
    let dayStr;
    switch (date.weekday) {
        case 1:
            dayStr = 'måndag ';
            break;
        case 2:
            dayStr = 'tisdag ';
            break;
        case 3:
            dayStr = 'onsdag ';
            break;
        case 4:
            dayStr = 'torsdag ';
            break;
        case 5:
            dayStr = 'fredag ';
            break;
        case 6:
            dayStr = 'lördag ';
            break;
        case 7:
            dayStr = 'söndag ';
            break;
        default:
            break;
    }
    return dayStr;
};
export const stringBuilderMonth = date => {
    let monthStr;
    switch (date.month) {
        case 1:
            monthStr = 'januari';
            break;
        case 2:
            monthStr = 'februari';
            break;
        case 3:
            monthStr = 'mars';
            break;
        case 4:
            monthStr = 'april';
            break;
        case 5:
            monthStr = 'maj';
            break;
        case 6:
            monthStr = 'juni';
            break;
        case 7:
            monthStr = 'juli';
            break;
        case 8:
            monthStr = 'augusti';
            break;
        case 9:
            monthStr = 'september';
            break;
        case 10:
            monthStr = 'oktober';
            break;
        case 11:
            monthStr = 'november';
            break;
        case 12:
            monthStr = 'december';
            break;
        default:
            break;
    }
    return monthStr;
};
export const stringBuilderDate = startTime => {
    if(startTime){
        const strDay = stringBuilderDay(DateTime.fromISO(startTime));
        const strDate = DateTime.fromISO(startTime).setLocale('sv').toFormat("d ");
        const strTime = DateTime.fromISO(startTime).setLocale('sv').toFormat("' kl 'HH':'mm ");
        const strMonth = stringBuilderMonth(DateTime.fromISO(startTime));
        return strDay+strDate+strMonth+strTime;
    }
};

export const formatText = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const detectIE = () => {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) <= 10;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        // const rv = ua.indexOf('rv:');
        // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) <= 11;
        return false;
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        // return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        return false;
    }

    const firefox = ua.indexOf('Firefox/');
    if (firefox > 0) {
        // Firefox (< 28) => return boolean
        return parseInt(ua.substring(firefox + 8, ua.indexOf('.', firefox)), 10) < 28;
    }

    // other browser
    return false;
};

export const clearActiveStage1 = (activeStage) => {
    const newActiveStage = JSON.parse(JSON.stringify(activeStage));
    newActiveStage.stage1 = true;
    newActiveStage.stage2 = null;
    newActiveStage.stage3 = null;
    newActiveStage.stage4 = null;
    return newActiveStage;
};
export const clearActiveStage2 = (activeStage) => {
    const newActiveStage = JSON.parse(JSON.stringify(activeStage));
    newActiveStage.stage1 = false;
    newActiveStage.stage2 = true;
    newActiveStage.stage3 = null;
    newActiveStage.stage4 = null;
    return newActiveStage;
};

export const sendGTMEvent = (eventName, eventType, eventValue) => {
    //Google Tag Manager:
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': eventName,
        'eventType': eventType,
        'eventValue': eventValue
    });

}