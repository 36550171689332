import React, { Component } from 'react';
import styled, { css } from 'styled-components';
//Styled components
import { SVG } from "../svg/SVG.js"
import { CSSCommon } from "../variables/CSSVariables";

const black = CSSCommon.color.black;
const green = CSSCommon.color.green;
const grey = CSSCommon.color.grey;
const white = CSSCommon.color.white;
const margin = CSSCommon.margin.normal;

const StageHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 30rem;
    width: 100%;
    height: 2.9rem;
    border-bottom: solid 0.2rem ${grey};
    margin-bottom: 1rem;
`;
const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    height: 1.8rem;
`;
const Header = styled.h2`
    font-size: ${CSSCommon.fontSize.h2};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
    //margin-left: ${margin};
    ${props => props.red && css`
        color: red;
    `}
`;
const InfoSVG = styled.button`
    height: 1.4rem;
    width: 1.4rem;
    margin-left: 1rem;
    padding-bottom: 0.1rem;
    cursor: pointer;
`;
const EditContainer = styled.div`
    height: 1.8rem;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const EditSVG = styled.svg`
    height: 1.2rem;
    width: 1.2rem;
    padding-bottom: 0.3rem;
`;
const EditText = styled.button`
    height: 1.4rem;
    margin-left: 0.5rem;
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${black};
    border: none;
    background: ${white};
    cursor: pointer;
`;

class StageHeaderComponent extends Component {
    onClickInfo = () => {
        this.props.setAppState({ renderInfo: !this.props.appState.renderInfo });
    };
    onClickEdit = () => {
        this.props.setAppState((prevState) => ({
            inputPopup: {
                renderPopup: !prevState.inputPopup.renderPopup,
                isEdit: !prevState.inputPopup.isEdit,
                isVehicleMain: true
            }
        }));
    };
    renderInfoButton = () => {
        return (
            <InfoSVG onClick={this.onClickInfo}>
                {SVG.info}
            </InfoSVG>
        )
    };
    renderEditButton = () => {
        return (
            <EditContainer onClick={this.onClickEdit}>
                <EditSVG>
                    {SVG.pen}
                </EditSVG>
                <EditText>Ändra</EditText>
            </EditContainer>
        )
    };
    shouldComponentUpdate(nextProps) {
        if (this.props.appState.stage1.header !== nextProps.appState.stage1.header) {
            return true;
        } else if (this.props.headerNr !== nextProps.headerNr) {
            return true;
        } else if (this.props.appState.vehicleMain.kind !== nextProps.appState.vehicleMain.kind) {
            return true;
        }
        return false;
    }
    render() {
        const header = this.props.headerNr;
        const infoButton = this.props.appState.vehicleMain.kind && header.info ? this.renderInfoButton() : null;
        const editButton = header.edit ? this.renderEditButton() : null;
        return (
            <StageHeaderContainer>
                <HeaderContainer>
                    <Header red={this.props.red}>{header.text}</Header>
                    {infoButton}
                </HeaderContainer>
                {editButton}
            </StageHeaderContainer>
        )
    }
}

export default StageHeaderComponent;

















