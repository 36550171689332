import React, { Component } from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {Button, SVGClose} from "../../variables/StyledComponents";
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const black = CSSCommon.color.black;
const blackout = CSSCommon.color.blackout;
const green = CSSCommon.color.green;
const white = CSSCommon.color.white;
const borderBold = CSSCommon.border.bold;

const ComponentContainer = styled.div`
    display: flex;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    height: calc(${props => props.viewport}px - 2rem);
    width: calc(100% - 2rem);
    padding: 1rem;
    z-index: 100;
    transition: background .2s;
    ${props => props.startAnimation && css`
        background: ${blackout};
    `}
`;

const Popup = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 28rem;
    height: 23.6rem;
    padding: 1rem;
    background-color: ${white};
    transform: scale(0.9);
    opacity: 0;
    transition: transform .2s, opacity .2s;
    ${props => props.startAnimation && css`
        transform: scale(1);
        opacity: 1;
    `}
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 1.8rem;
`;

const SVGCar = styled.svg`
    width: 2.4rem;
    fill: ${black};
`;
const Header = styled.h2`
    margin-left: -16rem;
    color: ${green};
    font-size: ${CSSCommon.fontSize.h2};
    font-weight: ${CSSCommon.fontWeight.medium};
`;

const Text = styled.h4`
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.normal};
    margin-top: 2.3rem;
    text-align: center;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 15rem;
    justify-content: space-between;
`;

const Input = styled.input`
    align-self: center;
    text-align: center;
    width: 14.9rem;
    height: 4.8rem;
    border: ${borderBold} ${green};
    border-radius: ${CSSCommon.borderRadius.input};
    font-size: ${CSSCommon.fontSize.input};
    z-index: 90;
`;

class PopupRegistrationNr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            startAnimation: false
        };
    }
    togglePopup = () => {
        this.setState({startAnimation: false});
        setTimeout(() => {
            this.props.setAppState((prevState) => ({
                inputPopup: {
                    renderPopup: !prevState.inputPopup.renderPopup,
                    isEdit: false,
                    isVehicleMain: prevState.inputPopup.isVehicleMain
                }
            }));
        }, 200);
    };
    handleChange = e => {
        if(this.verifyDigitOrChar(e.target.value)){
            this.setState({value: e.target.value.toUpperCase()});
        }
    };
    handleSubmit = () => {
        this.setState({startAnimation: false});
        setTimeout(() => {
            this.props.setAppState(null, {type: "getVehicle", payload: this.state.value});
        }, 200);
    };
    handleKeyPress = e => {
        if (e.nativeEvent.key === 'Enter') {
            if (this.regNrValidator()) {
                this.handleSubmit();
            }
        } else if(e.nativeEvent.key === 'Escape'){
            if(this.state.value === ""){
                this.togglePopup();
            } else {
                this.setState({value: ""})
            }
        }
    };
    verifyDigitOrChar = string => {
        //Ensures that all characters are letters and/or numbers
        string = string.charAt(string.length - 1);
        return (
            (/^[a-zA-Z]+$/.test(string)) || (/\d/.test(string)) || (string === ''));
    };
    verifyLastThree = string => {
        //Ensures that last three characters are either all numerical or all numerical and last digit is letter. Ex: 123 or 12X
        return (/\d/.test(string.charAt(3)) && /\d/.test(string.charAt(4)) && (/\d/.test(string.charAt(5)) || /^[a-zA-Z]+$/.test(string.charAt(5))));
    };
    verifyFirstThree = string => {
        //Ensures that first three characters are letters or digits. Ex: ABC or 123
        return (
            (/^[a-zA-Z]+$/.test(string.charAt(0)) && /^[a-zA-Z]+$/.test(string.charAt(1)) && /^[a-zA-Z]+$/.test(string.charAt(2)))
            || (/\d/.test(string.charAt(0)) && /\d/.test(string.charAt(1)) && (/\d/.test(string.charAt(2)))));
    };
    regNrValidator = () => {
        const value = this.state.value;
        if(value.length === 6 && this.verifyFirstThree(value) && this.verifyLastThree(value)) {
            return true
        }
        return false
    };
    stopPropagation = e => {
        e.stopPropagation();
    };
    componentDidMount() {
        if(!this.state.startAnimation) {
            this.setState({startAnimation: true});
        }
        if(window.history.state === null || !window.history.state.page.toString().includes('render')){
            window.history.pushState({page: 'renderPopup'}, 'renderPopup', );
        }
    };
    componentWillUnmount(){
        if(!this.props.appState.isIOS
            && window.history.state
            && window.history.state.page.toString().includes('render'))
        {
            window.history.back();
        }
    }
    render() {
        const isDisabled = !this.regNrValidator();
        const buttonText = this.props.appState.inputPopup.isEdit ? 'Ändra' : 'Lägg till';
        return (
            <ComponentContainer
                viewport={this.props.appState.viewportHeightPx}
                onClick={this.togglePopup}
                startAnimation={this.state.startAnimation}
            >
                <Popup onClick={this.stopPropagation}
                       startAnimation={this.state.startAnimation}>
                    <HeaderContainer>
                        <SVGCar>
                            {SVG.wheel}
                        </SVGCar>
                        <Header>Fordon</Header>
                        <SVGClose onClick={this.togglePopup}>
                            {SVG.close}
                        </SVGClose>
                    </HeaderContainer>
                    <Text>Ange ditt regnr för att boka, ändra eller betala din besiktning.</Text>
                    <InputContainer>
                        <Input
                            autoFocus
                            type="text"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyPress}
                            placeholder="ABC123"
                            value={this.state.value}
                            maxLength="6"/>
                        <Button
                            disabled={isDisabled}
                            onlyNext
                            onClick={this.handleSubmit}
                        >{buttonText}</Button>
                    </InputContainer>
                </Popup>
            </ComponentContainer>
        );
    }
}

export default PopupRegistrationNr;
