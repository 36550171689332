import React from 'react';
import styled, { css } from 'styled-components';
import { SVG } from "../../svg/SVG.js"
import { CSSCommon } from "../../variables/CSSVariables";
import { TextH5 } from "../../variables/StyledComponents";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const white         = CSSCommon.color.white;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 27.8rem;
    max-width: 30.2rem;
    border: solid 0.1rem ${green};
    padding: 1rem;
    margin-bottom: 0;
    @media(${tabletMin}){
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 1rem;
    }
`;
const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    ${props => props.flexWrap && css`
        flex-wrap: wrap;
        max-width: 80%;
    `}
`;
const H5 = styled(TextH5)`
    width:  100%;
    margin-bottom: 1rem;
    cursor: text;
    user-select: text;
    word-wrap: break-word;
    ${props => props.marginRight && css`
        margin-right: 1rem;
    `}
    ${props => props.noMargin && css`
        margin-bottom: 0;
    `}
    ${props => props.marginTop && css`
        margin-top: 1rem;
        margin-bottom: 0;
    `}
`;
const EditContainer = styled.div`
    display: flex;
    align-items: flex-end;
    align-self: flex-start;
    cursor: pointer;
    margin-bottom: 1rem;
    @media print{    
        display: none !important;
    }
    &:hover{
        text-decoration: underline;
    }
`;
const EditSVG = styled.svg`
    position: relative;
    top: -0.2rem;
    height: 1.2rem;
    width: 1.2rem;
`;
const EditText = styled.button`
    position: relative;
    top: -0.2rem;
    height: 1.3rem;
    margin-left: 0.5rem;
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${black};
    border: none;
    background: ${white};
    padding-top: 0.2rem;
    cursor: pointer;
`;

const ContactsComponent = props => {
    const booking = props.appState.booking;

    const onClickEdit = () => {
        props.setAppState({renderEditContacts: true});
    };
    const firstName = () => {
        return booking.contactInformation.firstName ? <H5 marginRight>{booking.contactInformation.firstName}</H5> : null;
    };
    const lastName = () => {
        return booking.contactInformation.lastName ? <H5 marginRight>{booking.contactInformation.lastName}</H5> : null;
    };
    const emailTop = () => {
        return firstName() === null && lastName() === null ? <H5>{booking.contactInformation.email}</H5> : null;
    };
    const emailBottom = () => {
        return firstName() !== null || lastName() !== null ? <H5>{booking.contactInformation.email}</H5> : null;
    };
    const comment = () => {
        return booking.contactInformation.comment ? <H5 marginTop>{booking.contactInformation.comment}</H5> : null;
    };
    const renderEdit = () => {
        if(booking.items[0].status !== 'CANCELED'){
            return (
                <EditContainer onClick={onClickEdit}>
                    <EditSVG>
                        {SVG.pen}
                    </EditSVG>
                    <EditText>Ändra</EditText>
                </EditContainer>
            )
        }
    };
    return (
        <ComponentContainer>
            <TextContainer>
                <TextContainer flexWrap>
                    {firstName()}
                    {lastName()}
                    {emailTop()}
                </TextContainer>
                {renderEdit()}
            </TextContainer>
            {emailBottom()}
            <H5 noMargin>{booking.contactInformation.phoneNumber}</H5>
            {comment()}
        </ComponentContainer>
    );
};
export default ContactsComponent
























