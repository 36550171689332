import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {SVG} from '../../svg/SVG.js'
import {CSSCommon} from '../../variables/CSSVariables';
import { sendGTMEvent } from '../../resource-functions/HelperFunctions'

import StageHeaderComponent     from '../StageHeaderComponent';
import SummeryComponent         from './SummeryComponent';
import ContactsComponent        from './ContactsComponent';
import ButtonComponent          from './ButtonComponent';
import InformationComponent     from './InformationComponent';
import PopupComponent           from '../PopupComponent';
import EditContactsComponent    from './EditContactsComponent';
import CancelBookingComponent   from './CancelBookingComponent';

const green             = CSSCommon.color.green;
const maxTotalWidth     = CSSCommon.maxTotalWidth;
const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;

const Stage4Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    max-width: ${maxTotalWidth};
    width: 100%;
    padding-top: 1rem;
    @media(${tabletMin}){
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    @media print{   
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 7.5rem;
    }
`;
const SubsectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    max-width: 32.4rem;
    width: 100%;
    page-break-before: always;
    page-break-after: always;
    @media(${tabletMin}){
        margin-top: 0;
     }
    @media print{    
        margin-top: 0;
    }
    ${props => props.secondary && css`
        margin-top: 3rem;
    `}
`;
const PrintHeader = styled.div`
    height: 0;
    display: none;
    @media print{    
        display: flex;
        position: absolute;
        top: 1.5rem;
        left: 0;
        justify-content: center;
        width: 100%;
        height: 2.8rem;
        padding-bottom: 1.5rem;
        border-bottom: 0.3rem solid ${green};
        margin-bottom: 1.5rem;
    }
`;
const PrintLogoWrapper = styled.a`
    position: absolute;
    top: 0;
    left: 0;
`;
const PrintLogo = styled.svg`
    width: 9.9rem;
    height: 2.8rem;
    fill: ${green};
    z-index: 50;
`;
const PrintH1 = styled.h1`
    font-size: ${CSSCommon.fontSize.h1};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${CSSCommon.color.h1};
    background: white;
    margin-top: 0.5rem;
    z-index: 20;
`;

class Stage4Component extends Component {
    constructor(props) {
        super(props);
        this.state = {timeIsDisabled: true};
    }
    print = () => {
        window.scrollTop = 0;
        window.print();
    };
    postPayment = () => {
        if(!this.payIsDisabled){
            this.props.setAppState(null, {type: 'postPayment', payload: null});
        }
    };
    renderEditContactsComponent = (appState, setAppState) => {
        return (
            <PopupComponent
                thin
                overflowAuto
                header='Uppgifter'
                svg='profile'
                type='renderEditContacts' {...{appState, setAppState}}
            >
                <EditContactsComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    renderCancelBookingComponent = (appState, setAppState) => {
        const string = !appState.booking.items[0].paymentStatus.includes('NOT_PAID') && appState.booking.items[0].paymentStatus.includes('PAID')
            ? 'Avboka & återbetala'
            : 'Avboka';
        return (
            <PopupComponent
                thin
                header={string}
                svg='dekra'
                type='renderCancelBooking' {...{appState, setAppState}}
            >
                <CancelBookingComponent string={string} {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    alterHeader = (headerText) => {
        const stage4 = JSON.parse(JSON.stringify(this.props.appState.stage4));
        stage4.header1.text = headerText;
        return stage4.header1
    };
    callback = (data) => {
        this.totalPrice = data;
    };
    componentDidMount(){
        if(this.props.appState.renderAlert.error){
            const alert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
            alert.error = false;
            this.props.setAppState({renderAlert: alert});
        }
        const id = this.props.appState.booking.originalId
            ? this.props.appState.booking.originalId
            : this.props.appState.booking.id;
        document.documentElement.scrollIntoView();
        if (window.history.replaceState) {
            window.history.replaceState({page: 'webBookingLogin'}, 'webBookingLogin', '/?bookingId=' + id);
        }
        sendGTMEvent('stage-4','price', this.totalPrice)
    }
    render() {
        const {appState, setAppState} = this.props;
        const bookingIsCanceled = appState.booking.items[0].status === 'CANCELED';
        const inqueryBoo = appState.bookingIncludeInquiry
        const editContacts = appState.renderEditContacts ? this.renderEditContactsComponent(appState, setAppState) : null;
        const cancelBooking = appState.renderCancelBooking ? this.renderCancelBookingComponent(appState, setAppState) : null;
        const header = bookingIsCanceled ? this.alterHeader('AVBOKAD') :  appState.bookingIncludeInquiry === true ? this.alterHeader('Förfrågan skickad') : appState.stage4.header1;

        // Calendar solved in frontend is not stable. Awaiting backend solution:
        // const calendarIsDisabled = bookingIsCanceled;
        this.payIsDisabled = bookingIsCanceled || !appState.booking.payable;
        return (
            <Stage4Container>
                <PrintHeader>
                    <PrintLogoWrapper href='https://dekra-bilbesiktning.se'>
                        <PrintLogo>
                            {SVG.logo}
                        </PrintLogo>
                    </PrintLogoWrapper>
                    <PrintH1>BOKNINGSBEKRÄFTELSE</PrintH1>
                </PrintHeader>
                <SubsectionContainer>
                    <StageHeaderComponent headerNr={header} red={bookingIsCanceled} {...{appState, setAppState}}/>
                    <SummeryComponent totalPrice={this.callback} {...{appState, setAppState}}/>
                    <StageHeaderComponent headerNr={appState.stage4.header3} {...{appState, setAppState}}/>
                    <ContactsComponent {...{appState, setAppState}}/>
                    <ButtonComponent
                        svg={'print'}
                        type={'renderCalendar'}
                        text='Skriv ut'
                        onClick={this.print}
                        {...{appState, setAppState}}
                    />
                    {appState.bookingIncludeInquiry === false &&
                        <ButtonComponent
                            svg={'creditCard'}
                            type={'renderCalendar'}
                            text='Betala online'
                            disabled={this.payIsDisabled}
                            onClick={this.postPayment}
                            {...{appState, setAppState}}
                        />
                    }
                </SubsectionContainer>
                <SubsectionContainer secondary>
                    <StageHeaderComponent headerNr={appState.stage4.header2} {...{appState, setAppState}}/>
                    <InformationComponent {...{appState, setAppState}}/>
                </SubsectionContainer>
                {editContacts}
                {cancelBooking}
            </Stage4Container>
        );
    }
}
export default Stage4Component;










