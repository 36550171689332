import React, {Component} from 'react';
import styled, { css } from 'styled-components';

//Styled components
import {TextH4} from "../../../variables/StyledComponents";
import {CSSCommon} from "../../../variables/CSSVariables";

const green = CSSCommon.color.green;
const ComponentContainer = styled(TextH4)`
    font-weight: ${CSSCommon.fontWeight.heavy};
    padding: 0 1rem 0.5rem 1rem;
    border-bottom: solid 0.1rem ${green};
    margin-top: 1rem;
    ${props => props.hidden && css`
        display: none;
    `}
`;

class FixedHeaderComponent extends Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.text !== nextProps.text) {
            return true;
        }
        return false;
    };
    render() {
        return (
            <ComponentContainer hidden={this.props.hidden}>
                {this.props.text}
            </ComponentContainer>
        );
    }
};
export default FixedHeaderComponent;































