import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {
    stringBuilderDay,
    stringBuilderMonth,
    stringBuilderDate,
    sendGTMEvent
} from '../../resource-functions/HelperFunctions';
import { DateTime } from 'luxon';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import {StringVariables} from "../../variables/StringVariables";

import StageHeaderComponent                     from "../StageHeaderComponent";
import SearchStationComponent                   from "./search-station-component/SearchStationContainer";
import Stage2ChooseTimeslotComponent            from "./ChooseTimeslotComponent";

const green             = CSSCommon.color.green;
const maxTotalWidth     = CSSCommon.maxTotalWidth;
const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;

const Stage2Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    max-width: ${maxTotalWidth};
    padding-top: 1rem;
    @media(${tabletMin}){
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
`;
const SubsectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    max-width: 32.4rem;
    width: 100%;
`;
const SubsectionText = styled.h5`
    text-align: left;
    width: 30rem;
    height: auto;
    font-size: ${CSSCommon.fontSize.h5};
    ${props => props.marginBottom && css`
        margin-bottom: 1rem;
    `}
`;
const ChoosenTimeslot = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    width: calc(100% - 2.2rem);
    height: 4.8rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    border: solid 0.1rem ${green};
    cursor: default;

`;
const Text = styled.h3`
    font-size: ${CSSCommon.fontSize.h3};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
`;
const MapButtonContainer = styled.div`
    display: flex;
    align-self: flex-start;
    height: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    @media(${tabletMin}){
        display: none;
    }
`;
const TextMapButton = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    margin-left: 0.5rem;
    cursor: pointer;
`;
const SVGMapButton = styled.svg`
    width: 1.2rem;
    fill: black;
`;

class Stage2Component extends Component {
    constructor(props) {
        super(props);
        this.state = {timeIsDisabled: true};
    }
    toggleMap = () => {
        this.props.setAppState({renderMap: true});
    };
    triggerHeightWarning = () => {
        if(!this.props.appState.vehicleMain.dimensions.height
            || (this.props.appState.vehicleSecondary.dimensions
            && !this.props.appState.vehicleSecondary.dimensions.height))
        {
            const alertMessage = JSON.parse(JSON.stringify(this.props.appState.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
            const vehicleMain = this.props.appState.vehicleMain.dimensions
            && !this.props.appState.vehicleMain.dimensions.height
                ? this.props.appState.vehicleMain.registrationNumber
                : '';
            const vehicleSecondary = this.props.appState.vehicleSecondary.dimensions
            && !this.props.appState.vehicleSecondary.dimensions.height
                ? this.props.appState.vehicleSecondary.registrationNumber
                : '';
            const noHeight = StringVariables.warningMessages.noHeight;
            alertMessage.warning = noHeight(vehicleMain, vehicleSecondary);
            renderAlert.warning = true;
            this.props.setAppState({
                alertMessage: alertMessage,
                renderAlert: renderAlert
            });
        }
    };
    hideHeightWarning = () => {
        const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
        renderAlert.warning = false;
        this.props.setAppState({
            renderAlert: renderAlert
        });

    };
    stringBuilder = (date) => {
        const newDate = DateTime.fromISO(date);
        return stringBuilderDay(newDate) + ' ' + newDate.day + ' ' + stringBuilderMonth(newDate);
    };
    renderChooseTimeContainer = (appState, setAppState) => {
        const startTime = appState.choosenTimeslot.interval ? appState.choosenTimeslot.interval.start : false;
        const subsectionText = startTime ? 'Ändra din tid:' : 'Hitta en tid som passar dig bäst.';
        const string = startTime ? stringBuilderDate(startTime) : null;
        const text = startTime ? <SubsectionText marginBottom>Vald tid:</SubsectionText> : null;
        const choosenTimeslot = startTime ? <ChoosenTimeslot><Text>{string}</Text></ChoosenTimeslot> : null;
        return(
            <SubsectionContainer>
                <StageHeaderComponent headerNr={appState.stage2.header2} {...{appState, setAppState}}/>
                {text}
                {choosenTimeslot}
                <SubsectionText>{subsectionText}</SubsectionText>
                <Stage2ChooseTimeslotComponent
                    svg={'calendar'}
                    disabled={!appState.choosenShop}
                    type={'renderCalendar'}
                    text="Kalender" {...{appState, setAppState}}
                />
            </SubsectionContainer>
        )
    };
    componentDidMount(){
        this.triggerHeightWarning();
        this.props.setAppState({renderTerms: false});
        document.documentElement.scrollIntoView();
        if(window.history.state === null || window.history.state.page !== 2){
            if(window.history.state.page.toString().includes('render')){
                window.history.replaceState({page: 2}, 'page2', );
            } else {
                window.history.pushState({page: 2}, 'page2', );
            }
        }
        //Google Tag Manager:
        sendGTMEvent('stage-2');
    }
    componentWillUnmount() {
        this.hideHeightWarning();
    }
    shouldComponentUpdate() {
        if (this.props.appState.renderCheapestTimeslot) {
            return false;
        }
        if (this.props.appState.renderFirstFreeTimeslot) {
            return false;
        }
        if (this.props.appState.renderCalendar) {
            return false;
        }
        return true;
    };
    render() {
        const {appState, setAppState} = this.props;
        const subsectionText =  appState.choosenShop && !appState.renderSearchDropdown ? 'Vald station:' : 'Sök och välj en besiktningsstation i närheten av dig!' ;
        const renderChooseTimeContainer = this.renderChooseTimeContainer(appState, setAppState);
        return (
            <Stage2Container id="stage2-container">
                <SubsectionContainer id="SubsectionContainer">
                    <StageHeaderComponent headerNr={appState.stage2.header1} {...{appState, setAppState}}/>
                    <SubsectionText>{subsectionText}</SubsectionText>
                    <SearchStationComponent {...{appState, setAppState}}/>
                    <MapButtonContainer onClick={this.toggleMap}>
                        <SVGMapButton>
                            {SVG.location}
                        </SVGMapButton>
                        <TextMapButton>Hitta på karta</TextMapButton>
                    </MapButtonContainer>
                </SubsectionContainer>
                {renderChooseTimeContainer}
            </Stage2Container>
        );
    }
}
export default Stage2Component;
