import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import loader from '../../pictures/Dual_Ring_yellow_06_opacity.gif'

import Map from './Map'
import {sendGTMEvent} from "../../resource-functions/HelperFunctions";

const blackout      = CSSCommon.color.blackout;
const green         = CSSCommon.color.green;
const yellow        = CSSCommon.color.yellow;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: ${props => props.viewport}px;
    width: 100vw;
    background: ${blackout};
    z-index: 100;
`;
const Popup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(${props => props.viewport}px - 2rem);
    width:  calc(100vw - 2rem);
    margin: 1rem 1rem 1rem 1rem;
    border: 0;
    z-index: 10;
`;
const CloseContainer = styled.div`
    position: absolute;
    top: calc(10px + 1rem);
    right: calc(10px + 1rem);
    border-radius:  50%;
    z-index: 200;
    @media(${mobileMax}){
        top: calc(10px);
        right: calc(10px);
        padding: 1rem; 
        ${props => props.embedMap && css`
            top: calc(50px);
        `}
    }
    ${props => props.embedMap && css`
        top: calc(50px + 1rem);
    `}
`;
const Close = styled.button`
    align-self: flex-end;
    width: 29px;
    height: 29px;
    background-color: ${green};
    fill: ${yellow};
    border-radius: 100%;
    padding: 3px;
    z-index: 200;
    cursor: pointer;
    &:hover{
        background-color: ${yellow};
        fill: ${green};
    }
    
`;
const LoaderGIF = styled.img`
    position: absolute;
    top: calc(50vh - 3rem);
    left: calc(50vw - 3rem);
    height: 6rem;
    width: 6rem;
`;

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: {
                coords: {
                    latitude: 63.0377078,
                    longitude: 16.8305583
                },
                default: true
            },
            zoom: 5
        };
    }
    closePopup = () => {
        this.props.setAppState({renderMap: false});
    };
    buildLocationString = () => {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyBHXM-QDZomAsPqANP50d6qkBHNAgUj64Y&q=Dekra Bilbesiktning,${this.extractShop().area},${this.extractShop().name}`;
    };
    renderJsMap = (markerElements, appState, setAppState) => {
        return(
            <Map
                isMarkerShown
                googleMapsApiKey="AIzaSyBHXM-QDZomAsPqANP50d6qkBHNAgUj64Y&"
                loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                containerElement={<div style={{ height: `calc(100%)`, width: '100%' }} />}
                mapElement={<div style={{ height: `calc(100%)`, width: '100%' }} />}
                markerElements={markerElements}
                center={this.state.location}
                zoom={this.state.zoom}
                {...{appState, setAppState}}
            />
        )
    };
    renderEmbedMap = () => {
        return(
            <iframe
                title="Map"
                style={{width: '100%', height: '100%', frameborder: '0', border: '0'}}
                src={this.buildLocationString()}
                allowFullScreen>
            </iframe>
        )
    };
    extractShop = () => {
        return this.props.appState.shops.filter((shop) => shop.id === this.props.appState.booking.shopId)[0]
    };
    handleKeyPress = e => {
        if(e.key === 'Escape'){
            this.closePopup();
        }
    };
    stopPropagation = e => {
        e.stopPropagation();
    };

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(pos => {this.setState({location: pos, zoom: 11})});
        if(window.history.state === null || !window.history.state.page.toString().includes('render')){
            window.history.pushState({page: 'renderMap'}, 'renderMap', );
        }
        document.addEventListener('keyup', this.handleKeyPress);

        sendGTMEvent('stage-2','findOnMap','Click')
    };
    componentWillUnmount(){
        if(!this.props.appState.isIOS
            &&window.history.state
            && window.history.state.page.toString().includes('render'))
        {
            window.history.back();
        }
        document.removeEventListener('keyup', this.handleKeyPress);
    }
    render() {
        const {appState, setAppState} = this.props;
        const markerElements = appState.activeStage.stage4 ? [appState.choosenShop] : appState.shops;
        const map = appState.activeStage.stage4 ? this.renderEmbedMap() : this.renderJsMap(markerElements, appState, setAppState);
        return (
            <ComponentContainer
                viewport={this.props.appState.viewportHeightPx}
                onClick={this.closePopup}
            >
                <LoaderGIF src={loader} alt="loader"/>
                <Popup
                    viewport={this.props.appState.viewportHeightPx}
                    onClick={this.stopPropagation}
                >
                    <CloseContainer embedMap={appState.activeStage.stage4} onClick={this.closePopup}>
                        <Close>
                            {SVG.close}
                        </Close>
                    </CloseContainer>
                    {map}
                </Popup>
            </ComponentContainer>
        );
    }
}
export default MapContainer;
