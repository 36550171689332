import React, { Component } from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {TextH4, TextH5} from "../../../variables/StyledComponents";
import {CSSCommon} from "../../../variables/CSSVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const hover         = CSSCommon.color.hover;
const white         = CSSCommon.color.white;
const yellow        = CSSCommon.color.yellow;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.button `
    display: flex;
    align-items: center;    
    text-align: left;
    width: 100%;
    height: 3.6rem;
    padding: 0 0.5rem;
    cursor: pointer;
    @media(${tabletMin}){
        &:hover {
            background: ${hover};
        }
    }
    ${props => props.choosenTimeslot && css`
        background: ${green};
        color: ${white};
        &:hover {
            background: ${green};
        }
    `}
`;
const TextTime = styled(TextH4)`
    font-weight: ${CSSCommon.fontWeight.medium};
    padding: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    ${props => props.firstFree && css`
        background: ${yellow};
        color: ${black};
    `}
    ${props => !props.firstFree && props.choosenTimeslot && css`
        color: ${white};
    `}
`;
const TextShop = styled(TextH5)`
    font-weight: ${CSSCommon.fontWeight.medium};
    flex-grow: 2;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    ${props => props.choosenTimeslot && css`
        color: ${white};
    `}
`;
const TextPrice = styled(TextH4)`
    display: flex;
    font-weight: ${CSSCommon.fontWeight.heavy};
    color: ${green};
    padding: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    ${props => props.cheapest && css`
        background: ${yellow};
        color: ${green};
    `}
    ${props => !props.cheapest && props.choosenTimeslot && css`
        color: ${white};
    `}
`;
const TimeslotMargin = styled.div`
    margin-left: 0.2rem;
`;

class Timeslot extends Component {

    onClick = () => {
        this.props.setAppState(
            {
                choosenTimeslot: this.props.timeslot,
                choosenShop: this.getChoosenShop(),
                isUnchangedRebooking: false,
                renderFirstFreeTimeslot: false,
                renderCheapestTimeslot: false,
                renderCalendar: false
            },
            {
                type: "putBookingAndTimeslot",
                payload: this.props.timeslot
            }
        );
    };
    getChoosenShop = () => {
        let newShop;
        this.props.appState.shops.forEach((shop) => {
            if(shop.id === this.props.timeslot.shopId){
                newShop = shop;
            }
        });
        return newShop
    };
    formatAreaText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    shouldComponentUpdate(nextProps) {
        if (this.props.timeslot !== nextProps.timeslot) {
            return true;
        }
        return false;
    };

    render() {
        const timeslotEqualsChoosenTimeslot = this.props.appState.choosenTimeslot.interval
            ? this.props.timeslot.interval.start === this.props.appState.choosenTimeslot.interval.start
            : false;
        return (
            <ComponentContainer choosenTimeslot={timeslotEqualsChoosenTimeslot} onClick={this.onClick}>
                <TextTime choosenTimeslot={timeslotEqualsChoosenTimeslot}
                          firstFree={this.props.firstFree}>{this.props.timeslot.interval.start.slice(11, 16)}</TextTime>
                <TextShop
                    choosenTimeslot={timeslotEqualsChoosenTimeslot}>{this.formatAreaText(this.props.timeslot.shopName)}</TextShop>
                <TextPrice choosenTimeslot={timeslotEqualsChoosenTimeslot}
                           cheapest={this.props.cheapest}>från {this.props.timeslot.price}<TimeslotMargin/>kr</TextPrice>
            </ComponentContainer>
        );
    }
}
export default Timeslot;































