import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {CSSCommon} from "../../../variables/CSSVariables";

const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const yellow        = CSSCommon.color.yellow;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const Day = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 4.1rem;
    height: 4.1rem;
    margin: 0.1rem 0.05rem;
    padding: 0.2rem;
    border: solid 0.1rem ${grey};
    color: ${green};
    cursor: default;
    opacity: .5;
    @media(${tabletMin}){
        width: 5rem;
        height: 5rem;
    }
    ${props => props.active && css`
        cursor: pointer;
        opacity: 1;
        ${props => !props.choosen && css`
            @media(${tabletMin}){
                &:hover {
                    background: ${hover};
                }
            }
        `}
        ${props => props.choosen && css`
            @media(${tabletMin}){
                background-color: ${green};
                color: ${yellow};
                border: solid 0.1rem ${green};
                border-radius: 0.3rem;
                cursor: default;
             }   
        `}
        ${props => props.choosenMobile && css`
            background-color: ${green};
            color: ${yellow};
            border: solid 0.1rem ${green};
            border-radius: 0.3rem;
            cursor: default;
        `}
    `}
    
`;
const DayTextH5 = styled.p`
    font-size: ${CSSCommon.fontSize.h5};
    text-align: right;
`;

const PriceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: ${CSSCommon.fontSize.h5};
`;
const PriceTextH5 = styled.p`
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.heavy};
`;
const PriceTextExtraSmall = styled.p`
    font-size: 0.8rem;
`;

class CalendarDay extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onClick = () => {
        if(this.hasTimeslot() && this.props.active){
            this.props.onClick();
        }
    };
    hasTimeslot = () => {
        //Render as inactive if day has no timeslots
        return !(this.props.price === null || this.props.price === undefined)
    };
    renderPriceContainer = () => {
        if(this.props.price > 0){
            return(
                <PriceContainer>
                    <PriceTextExtraSmall>fr.</PriceTextExtraSmall>
                    <PriceTextH5>{this.props.price}</PriceTextH5>
                </PriceContainer>
            )
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.choosen !== nextProps.choosen) {
            return true;
        }
        if (this.props.choosenMobile !== nextProps.choosenMobile) {
            return true;
        }
        if (this.props.active !== nextProps.active) {
            return true;
        }
        if (this.props.price !== nextProps.price) {
            return true;
        }
        if (this.props.day !== nextProps.day) {
            return true;
        }
        return false;
    };

    render() {
        const priceContainer = this.props.active ? this.renderPriceContainer() : null;
        const isActive= this.hasTimeslot() && this.props.active;
        return (
            <Day
                id='cal-day'
                choosen={this.props.choosen}
                choosenMobile={this.props.choosenMobile}
                active={isActive}
                onClick={this.onClick}
                tabIndex={!isActive? '-1' : ''}
            >
                <DayTextH5>{this.props.day}</DayTextH5>
                {priceContainer}
            </Day>
        );
    }
}
export default CalendarDay;
