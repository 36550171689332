import React from 'react';
import styled from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const yellow        = CSSCommon.color.yellow;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const AddVehicleContainer = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    min-width: 27.8rem;
    max-width: 30.2rem;
    width: calc(100% - 2.2rem);
    height: 4.8rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border: solid 0.1rem ${grey};
    fill: ${green};
    cursor: pointer;
    @media(${tabletMin}){
        &:hover {
          border: solid 0.1rem ${hover};
          background: ${hover};
          fill: ${yellow};
          stroke: ${yellow};
        }
    }
`;
const SVGAddVehicle = styled.div`
    width: 1.8rem;
    height: 1.8rem;
`;
const AddVehicleType = styled.h5`
    flex-grow: 1;
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
    margin-left: 0.5rem;
    cursor: pointer;
`;

const Stage1AddVehicleComponent = (props) =>{
    const {appState, setAppState} = props;
    const vehicleType = appState.vehicleMain.vehicleKind === 'SLÄP' || !appState.vehicleMain.vehicleKind
        ? 'Lägg till fordon'
        : 'Lägg till släp eller husvagn';
    const isMain = !appState.vehicleMain.registrationNumber;
    const onClick = () => {
        const newAppState = {
            inputPopup: {
                renderPopup: true,
                isEdit: false,
                isVehicleMain: isMain
            }
        };

        if((appState.isRebooking || appState.vehicleMain.preliminary) && !appState.bookingHasChanged) {
            setAppState({renderBookingHasChangedWarning: newAppState})
        } else {
            setAppState(newAppState)
        }

    };
    return(
        <AddVehicleContainer onClick={onClick}>
            <SVGAddVehicle>
                {SVG.plus}
            </SVGAddVehicle>
            <AddVehicleType>{vehicleType}</AddVehicleType>
        </AddVehicleContainer>
    )
};

export default Stage1AddVehicleComponent;
