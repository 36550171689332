import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import { sendGTMEvent } from '../../resource-functions/HelperFunctions'

const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: 100%;
    height: 4.8rem;
    padding: 0 1rem;
    margin: 1rem 0 0 0;
    border: solid 0.1rem ${grey};
    cursor: default;
    opacity: .15;
    ${props => !props.disabled && css`
        opacity: 1;
        cursor: pointer;
        @media(${tabletMin}){
            &:hover {
              border: solid 0.1rem ${hover};
              background: ${hover};
            }
        }
    `}
`;
const SVGChooseTimeslot = styled.svg`
    width: 1.8rem;
    height: 1.8rem;
`;
const Text = styled.h4`
    margin-left: 1rem;
    font-size: ${CSSCommon.fontSize.h4};
    color: ${green};
    ${props => !props.disabled && css`
        cursor: pointer;
    `}
`;

class Stage2SearchStationComponent extends Component {
    onClick = () => {
        if(!this.props.disabled
            && !(this.props.appState.renderCheapestTimeslot
                || this.props.appState.renderFirstFreeTimeslot
                || this.props.appState.renderCalendar))
        {
            sendGTMEvent('stage-2','timeSelectType',this.props.type)
            this.props.setAppState({[this.props.type]: !this.props.appState[this.props.type]});

        }
    };
    renderSVGChooseTimeslot = () => {
            return(
                <SVGChooseTimeslot>
                    {SVG[this.props.svg]}
                </SVGChooseTimeslot>
            )
    };
    shouldComponentUpdate(nextProps) {
        if (this.props.disabled !== nextProps.disabled) {
            return true;
        }
        if (this.props.appState.renderCheapestTimeslot !== nextProps.appState.renderCheapestTimeslot) {
            return true;
        }
        if (this.props.appState.renderFirstFreeTimeslot !== nextProps.appState.renderFirstFreeTimeslot) {
            return true;
        }
        if (this.props.appState.renderCalendar !== nextProps.appState.renderCalendar) {
            return true;
        }
        return false;
    };
    render(){
        return(
            <ComponentContainer disabled={this.props.disabled} onClick={this.onClick.bind(this)}>
                {this.renderSVGChooseTimeslot()}
                <Text disabled={this.props.disabled}>{this.props.text}</Text>
            </ComponentContainer>
        )
    }
}
export default Stage2SearchStationComponent;
