import React from 'react';
import styled from 'styled-components';
//Styled components
import { Button } from "../../variables/StyledComponents";
import { CSSCommon } from "../../variables/CSSVariables";

const white         = CSSCommon.color.white;
const maxRowWidth   = CSSCommon.maxRowWidth;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 1rem;
    background: ${white};
    box-shadow: 0 -1rem 1rem ${white};
    z-index: 2;
    @media print{    
        display: none !important;
    }
`;
const ButtonColumn = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 70.8rem;
    @media(${tabletMin}){
        justify-content: space-between;
        width: calc(100% - 10rem);
        margin: 0 5rem;
    }
`;
const ButtonGroup = styled.div`
    background: ${white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0;
    max-width: ${maxRowWidth};
    width: calc(100% - 2rem);
    @media(${tabletMin}){
        margin-left: auto;
    }
`;
const ButtonComponent = (props) => {
    const {appState, setAppState} = props;
    const isDisabled = !appState.booking.items[0].canBeCanceled | appState.booking.items[0].status === 'CANCELED';
    const bookingIsPaid = !appState.booking.items[0].paymentStatus.includes('NOT_PAID')
        && appState.booking.items[0].paymentStatus.includes('PAID');
    const bookingHasSecondaryVehicle = !!appState.booking.items[1];
    const cancelBooking = () => {
        setAppState({renderCancelBooking: true});
    };
    const handleBooking = () => {
        const id = appState.booking.originalId ? appState.booking.originalId : appState.booking.id;
        isDisabled || appState.newlyMadeBooking || bookingHasSecondaryVehicle
            ? window.location = window.location.origin
            : window.location = `${window.location.origin}?rebooking=true&bookingId=${id}`;
    };
    const leftButtonText = bookingIsPaid ? 'Avboka & återbetala' : 'Avboka';
    const rightButtonText = isDisabled || bookingIsPaid || appState.newlyMadeBooking || bookingHasSecondaryVehicle ? 'Skapa ny bokning' : 'Boka om';
    return (
        <ButtonContainer>
            <ButtonColumn>
                <ButtonGroup>
                    <Button disabled={isDisabled} onClick={cancelBooking}>{leftButtonText}</Button>
                    <Button onClick={handleBooking}>{rightButtonText}</Button>
                </ButtonGroup>
            </ButtonColumn>
        </ButtonContainer>
    );
};

export default ButtonComponent
