import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {TextH6} from "../../../variables/StyledComponents";
import {CSSCommon} from "../../../variables/CSSVariables";
import {StringVariables} from "../../../variables/StringVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const hover         = CSSCommon.color.hover;
const white         = CSSCommon.color.white;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const DropdownItem = styled.button`
    width: 100%;
    padding: 0.7rem 0.5rem 0.7rem 0.5rem;
    text-align: left;
    cursor: pointer;
    ${props => !props.choosen && css`
        @media(${tabletMin}){
            &:hover {
                background-color: ${hover};
            }
        }
    `}
    ${props => !props.disabled && props.choosen && css`
        background-color: ${green}; 
    `}
    ${props => props.focused && css`
        background-color: ${hover}; 
    `}
    ${props => props.disabled && css`
        cursor: default;
        &:hover {
            background-color: ${white};
        }
    `}
`;
const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;
const TextH4 = styled.h4`
    font-size: ${CSSCommon.fontSize.h4};
    color: ${green};
    ${props => !props.disabled && props.choosen && css`
        color: ${white};
    `}
    ${props => !props.disabled && css`
        cursor: pointer;
    `}
`;
const TextH5 = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
    ${props => !props.disabled && props.choosen && css`
        color: ${white};
    `}
    ${props => !props.disabled && css`
        cursor: pointer;
    `}
`;
const H6 = styled(TextH6)`
    cursor: pointer;
    ${props => !props.disabled && props.choosen && css`
        color: ${white};
    `}
`;

class Stage2StationComponent extends Component{

    onClick = () => {
        //Disable if loader message
        if(this.props.shop.name !== StringVariables.stage2.dropdownLoaderMessage){
            this.props.onClick();
        }
    };
    formatAreaText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    componentDidMount() {
        //Scroll choosen shop into view.
        if(this.props.choosen){setTimeout(() => {
            document.getElementById(`station-${this.props.id}`).scrollIntoView({behavior: "smooth", block: "center"});
        }, 50);
        }
    }
    shouldComponentUpdate(nextProps) {
        if(this.props.shop.name !== nextProps.shop.name){
            return true;
        }
        if(this.props.active !== nextProps.active){
            return true;
        }
        if(this.props.distance !== nextProps.distance){
            return true;
        }
        if(this.props.focused !== nextProps.focused){
            return true;
        }
        return false;
    }

    render(){
        const shop = this.props.shop;
        //Disable if loader message:
        const disabled = this.props.shop.name === StringVariables.stage2.dropdownLoaderMessage;
        const textH5 = shop.addressLine1
            ? <TextH5 choosen={this.props.choosen} disabled={disabled}>{this.formatAreaText(shop.addressLine1)}, {this.formatAreaText(shop.area)}</TextH5>
            : <TextH5 choosen={this.props.choosen} disabled={disabled}>{this.formatAreaText(shop.area)}</TextH5>;
        const textHeight = !shop.isArea ? <H6 choosen={this.props.choosen}>Max fordonshöjd: {shop.maxHeight / 1000} m</H6> : null;
        const textH6 = this.props.distance && !shop.isArea ? <H6 choosen={this.props.choosen}>{Math.round(this.props.distance)} km</H6> : null;

        return(
            <DropdownItem
                id={`station-${this.props.id}`}
                choosen={this.props.choosen}
                disabled={disabled}
                focused={this.props.focused}
                onClick={this.onClick}
            >
                <TextContainer>
                    <TextH4 choosen={this.props.choosen} disabled={disabled}>{this.formatAreaText(shop.name)}</TextH4>
                    {textH6}
                </TextContainer>
                {textH5}
                {textHeight}
            </DropdownItem>
        )
    }
}

export default Stage2StationComponent;
