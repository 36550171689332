import React, { Component } from 'react';
import styled from 'styled-components';
import {Button, TextH4} from "../variables/StyledComponents";

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 1.8rem);
    min-height: 3.8rem;
    padding: 0 0.9rem 1rem;
`;
const H4 = styled(TextH4)`
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    align-self: center;
`;

class PreliminaryBookingAllreadyTakenPopupComponent extends Component {
    confirm = () => {
        const activeStage = JSON.parse(JSON.stringify(this.props.appState.activeStage));
        activeStage.stage1 = false;
        activeStage.stage2 = true;
        activeStage.stage3 = false;
        activeStage.stage4 = null;

        this.props.appState.booking.status = "NEW"

        this.props.setAppState({activeStage: activeStage, renderPreliminaryBookingAllreadyTakenPopup: false, });
    };
    render() {
        return (
            <ComponentContainer>
                <H4>
                    Vänligen gå in på vår bokningssida och välj
                    en ny tid som passar dig.
                    Välj station och tid i kalendern. Fyll i dina
                    kontaktuppgifter. Välj betalsätt och slutför
                    bokningen.
                </H4>

                <Button onClick={this.confirm} onKeyDown={this.handleKeyPress}>Boka ny tid här!</Button>

            </ComponentContainer>
        );
    }
}
export default PreliminaryBookingAllreadyTakenPopupComponent






























