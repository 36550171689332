import React, { Component } from 'react';
import styled from 'styled-components';
import { StringVariables } from '../../../variables/StringVariables'
import { clearActiveStage1 } from '../../../resource-functions/HelperFunctions'
import { sendGTMEvent } from '../../../resource-functions/HelperFunctions'

import Product from "./Product";

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

class ProductComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.isMain = this.props.main ? 'vehicleMain' : 'vehicleSecondary';
    }
    onClick = idx => {
        let newVehicle = JSON.parse(JSON.stringify(this.props.appState[this.isMain]));
        newVehicle.products[idx].recommended = !newVehicle.products[idx].recommended;

        let bookingIncludeInquiry = JSON.parse(JSON.stringify(this.props.appState.bookingIncludeInquiry));
        let isInquiryProductChosen = newVehicle.products[idx].type === "INQUIRY_ON_WEB";

        if(bookingIncludeInquiry && isInquiryProductChosen) {
            isInquiryProductChosen = false;
        } else if (!bookingIncludeInquiry && isInquiryProductChosen) {
            isInquiryProductChosen = true;
        } else if (bookingIncludeInquiry && !isInquiryProductChosen) {
            isInquiryProductChosen = true;
        }

        console.log('isInquiryProductChosen: ' + isInquiryProductChosen);

        if (!newVehicle.products[idx].recommended && newVehicle.products[idx].recommendedByTS) {
            const alertMessage = JSON.parse(JSON.stringify(this.props.appState.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
            alertMessage.warning = StringVariables.warningMessages.recommendedProduct(newVehicle);
            renderAlert.warning = true;
            this.props.setAppState({
                alertMessage: alertMessage,
                renderAlert: renderAlert
            });
        } else {
            if(isInquiryProductChosen === true) {

                if(newVehicle.products[idx].type === "INQUIRY_ON_WEB") {
                    const alertMessage = JSON.parse(JSON.stringify(this.props.appState.alertMessage));
                    const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
                    alertMessage.info = StringVariables.infoMessages.inquiryProduct(newVehicle.products[idx]);

                    renderAlert.info = true;
                    this.props.setAppState({
                        alertMessage: alertMessage,
                        renderAlert: renderAlert
                    });
                }
            }

        }

        sendGTMEvent('stage-1','productClicked',newVehicle.products[idx].shortDescription);

        newVehicle = this.productSorter(newVehicle);

        //[choosenShop] To clear choosen shop on product change
        const newAppState = {
            activeStage: clearActiveStage1(this.props.appState.activeStage),
            bookingHasChanged: true,
            choosenShop: false,
            choosenTimeslot: '',
            displayedShops: [
                {
                    name: StringVariables.stage2.dropdownLoaderMessage,
                    addressLine1: '',
                    area: '',
                    isArea: true
                }
            ],
            isUnchangedRebooking: false,
            shops: [],
            timeslots: '',
            [this.isMain]: newVehicle,
            bookingIncludeInquiry : isInquiryProductChosen,
            payWithKlarna : !isInquiryProductChosen
        };
        if ((this.props.appState.isRebooking || this.props.appState.vehicleMain.preliminary) && !this.props.appState.bookingHasChanged) {
            this.props.setAppState({ renderBookingHasChangedWarning: newAppState });
        } else {
            this.props.setAppState(newAppState);
            //To pervent jumping forward using browser buttons if stage has been cleared
            window.history.pushState({ page: 1 }, 'page1');
        }
    };
    productSorter = vehicle => {
        const choosenProducts = [];
        const otherProducts = [];
        vehicle.products.forEach((product) => {
            if (product.recommended) {
                choosenProducts.push(product);
            } else {
                otherProducts.push(product);
            }
        });
        vehicle.products = choosenProducts.concat(otherProducts);
        return vehicle;
    };
    renderProducts = () => {
        const vehicle = this.props.appState[this.isMain];
        return this.props.appState[this.isMain].products.map((product, idx) => {
            //[isLast] Needed for proper hover functionality
            const isLast = vehicle.products.length - 1 === idx || !this.props.appState[this.isMain].products[idx + 1].recommended;
            return (
                <Product
                    key={idx}
                    product={product}
                    vehicle={vehicle}
                    isLast={isLast}
                    isMain={this.isMain}
                    onClick={this.onClick.bind(this, idx)}
                    index={idx}
                />
            )
        })
    };
    componentWillMount() {
        const products = [];
        const vehicle = this.props.appState[this.isMain];
        vehicle.products.forEach((product) => {
            if (product.recommended) {
                products.unshift(product);
            } else {
                products.push(product);
            }
        });
        vehicle.products = products;
        this.props.setAppState({ [this.isMain]: vehicle });
    }
    render() {
        return (
            <ComponentContainer>
                {this.renderProducts()}
            </ComponentContainer>
        )

    }
}

export default ProductComponent;
