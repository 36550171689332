import 'raf/polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { detectIE } from './resource-functions/HelperFunctions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './index.css';
import './Normalize.css';
import App                      from './App';
import AppState                 from './AppState'
import NotFound                 from './components/404NotFoundComponent';
import UnsupportedBrowser       from './components/UnsupportedBrowserComponent';
import  { unregister }          from './registerServiceWorker';

const DekraApp = () => {
    if (detectIE()) {
        return <UnsupportedBrowser/>
    } else {
        return(
            <AppState debug>
                {({appState, setAppState}) => {
                    return <App {...{appState, setAppState}}/>;
                }}
            </AppState>
        )
    }
};
ReactDOM.render(
    <Router>
        <Routes>
            <Route exact path="/" element={<DekraApp/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </Router>,
    document.getElementById('root')
);
unregister();
