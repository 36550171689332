import { Component } from 'react';
import { DateTime } from 'luxon';
import {
    getVehicleAPI,
    getShopsAPI,
    getTimeslotsAPI,
    getBookingAPI,
    postBookingAPI,
    putBookingAPI,
    putTimeslotAPI,
    postPaymentAPI,
    getKlarnaReceiptAPI,
    postContactInformationAPI,
    getEditBookingAPI,
    getRebookingAPI,
    postPreliminaryBookingAPI,
    postResendConfirmationEmailAPI,
    putBookingCanceledAPI
} from "./AppAPI";
import { clearActiveStage1, sendGTMEvent } from './resource-functions/HelperFunctions'
import { StringVariables } from "./variables/StringVariables";
import { CSSCommon } from "./variables/CSSVariables";

class AppState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertMessage: {
                warning: '',
                error: '',
                info: '',
                persistentWarning: '',
                persistentReleaseBanner: StringVariables.infoMessages.releaseBanner
            },
            activeStage: {
                stage1: true,
                stage2: null,
                stage3: null,
                stage4: null
            },
            bookedTimeslot: '',
            booking: {},
            bookingHasChanged: true,
            bookingTemplate: {
                clientIp: null,
                contactInformation: null,
                desiredArea: null,
                id: null,
                items: [],
                originalId: null,
                payable: false,
                rebookable: false,
                shopId: null,
                status: "NEW"
            },
            choosenDate: DateTime.local(),
            choosenShop: false,
            choosenTimeslot: '',
            contactInformation: '',
            displayedMonth: DateTime.local(),
            displayedShops: [
                {
                    name: StringVariables.stage2.dropdownLoaderMessage,
                    addressLine1: '',
                    area: '',
                    isArea: true
                }
            ],
            headerComponentHeight: false,
            headerComponentHeightAlert: false,
            headerHeight: false,
            inputPopup: {
                renderPopup: false,
                isEdit: false,
                isVehicleMain: true
            },
            isIOS: false,
            klarnaFrame: false,
            payWithKlarna: true,
            previousleyVisited: '',
            renderAlert: {
                warning: false,
                error: false,
                info: false,
                persistentWarning: false
            },
            renderBackButton: false,
            renderBookingHasChangedWarning: false,
            renderCalendar: false,
            renderCheapestTimeslot: false,
            renderEditBooking: false,
            renderEditContacts: false,
            renderFirstFreeTimeslot: false,
            renderInfo: false,
            renderKlarna: false,
            renderLoader: false,
            renderMap: false,
            renderPreliminaryBookingPopup: false,
            renderPreliminaryBookingAllreadyTakenPopup: false,
            renderSearchDropdown: false,
            renderTerms: false,
            renderTimeslotLoader: false,
            renderTsUnavailable: false,
            renderInquiryPopup: false,
            stage1: {
                header1: {
                    SVG: 'car',
                    text: 'Fordon & produkt',
                    info: true,
                    edit: false
                }
            },
            stage2: {
                header1: {
                    SVG: 'garage',
                    text: 'Besiktningsstation',
                    info: false,
                    edit: false
                },
                header2: {
                    SVG: 'clock',
                    text: 'Tid',
                    info: false,
                    edit: false
                }
            },
            stage3: {
                header1: {
                    SVG: 'car',
                    text: 'Fordon & produkt',
                    info: false,
                    edit: false
                },
                header2: {
                    SVG: 'locationTime',
                    text: 'Besiktningsstation & tid',
                    info: false,
                    edit: false
                },
                header3: {
                    SVG: 'profile',
                    text: 'Kontaktuppgifter',
                    info: false,
                    edit: false
                },
                header4: {
                    SVG: 'creditCard',
                    text: 'Välj betalsätt',
                    info: false,
                    edit: false
                },
                header5: {
                    klarna: {
                        SVG: 'shoppingBag',
                        text: 'Kassa',
                        info: false,
                        edit: false
                    },
                    payAtStation: {
                        SVG: 'shoppingBag',
                        text: 'Att betala med kort på station',
                        info: false,
                        edit: false
                    }
                }
            },
            stage4: {
                header1: {
                    SVG: 'checkmark',
                    text: 'Bokning bekräftad',
                    info: false,
                    edit: false
                },
                header2: {
                    SVG: 'info',
                    text: 'Information om din bokning',
                    info: false,
                    edit: false
                },
                header3: {
                    SVG: 'info',
                    text: 'Kontaktuppgifter',
                    info: false,
                    edit: false
                }
            },
            shops: [],
            timeslots: [],
            timeslotTicker: 0,
            toggleCalendar: false,
            vehicleMain: {},
            vehicleSecondary: {},
            vehicleTsUnavailable: false,
            viewportHeight: false,
            viewportHeightPx: 0,
            viewportMobile: false,
            bookingIncludeInquiry: false
        };
    }

    setAppState = (updater, callback) => {
        this.setState(updater, this.APICallback(callback));
    };
    closePopup = () => {
        this.setState(() => ({
            inputPopup: {
                renderPopup: false,
                isEdit: false,
                isVehicleMain: this.state.inputPopup.isVehicleMain
            }
        }));
    };
    toggleLoader = isOn => {
        if (isOn) {
            this.setState(() => ({ renderLoader: true }));
        } else if (isOn === false) {
            this.setState(() => ({ renderLoader: false }));
        } else {
            this.setState(() => ({ renderLoader: !this.state.renderLoader }));
        }
    };

    closeErrors = () => {
        const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
        const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));

        renderAlert.error = false;
        alertMessage.error = '';

        this.setState({
            alertMessage: alertMessage,
            renderAlert: renderAlert
        });
    };

    APICallback = (callback) => {
        if (callback) {
            switch (callback.type) {
                case "getVehicle":
                    this.getVehicle(callback.payload);
                    break;
                case "createBookingAndGetShops":
                    this.createBookingAndGetShops();
                    break;
                case "getTimeslots":
                    this.getTimeslots(callback.payload);
                    break;
                case "putTimeslot":
                    this.putTimeslot(callback.payload);
                    break;
                case "putBookingAndTimeslot":
                    this.putBookingAndTimeslot(callback.payload);
                    break;
                case "confirmBooking":
                    this.confirmBooking();
                    break;
                case "cancelBooking":
                    this.cancelBooking();
                    break;
                case "postPayment":
                    this.postPayment();
                    break;
                case "postContactInformation":
                    this.postContactInformation(callback.payload);
                    break;
                case "getEditBooking":
                    this.getEditBooking(callback.payload);
                    break;
                case "getWebBookingLogin":
                    this.getWebBookingLogin(callback.payload);
                    break;
                case "vehicleTsUnavailable":
                    this.addVehicleVerifier(callback.payload);
                    break;
                case "postResendConfirmationEmail":
                    this.postResendConfirmationEmail(callback.payload);
                    break;
                default:
                    break;
            }
        }
    };
    //(1) GetVehicle start
    getVehicle = payload => {
        this.closePopup();
        this.toggleLoader(true);
        this.closeErrors();
        getVehicleAPI(payload)
            .then((response) => {
                const vehicle = response.data;
                vehicle.verifiedVehicle = true;
                vehicle.products.forEach(x => { x.recommendedByTS = x.recommended });
                this.addVehicleVerifier(vehicle);
                if (!(this.state.isUnchangedRebooking || response.data.preliminary)) {
                    this.toggleLoader(false)
                }
            })
            .catch((error) => {
                //Check if TS is unresponsive
                if (error.response && (error.response.status === 503 || error.response.status === 502 || error.response.status === 504)) {
                    getVehicleAPI("unverified/" + payload).then((response) => {
                        const vehicle = response.data;
                        vehicle.verifiedVehicle = true;
                        const isDuplicateRegNr = this.state.vehicleMain.registrationNumber === vehicle.registrationNumber
                            || this.state.vehicleSecondary.registrationNumber === vehicle.registrationNumber;
                        if (isDuplicateRegNr) {
                            const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                            const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                            alertMessage.warning = StringVariables.warningMessages.sameRegNr;
                            renderAlert.warning = true;
                            this.setState({
                                alertMessage: alertMessage,
                                renderAlert: renderAlert
                            });
                        } else if (vehicle.hasBooking && !this.state.isUnchangedRebooking) {
                            this.setState({
                                renderEditBooking: vehicle.registrationNumber
                            })
                        } else {
                            this.setState({
                                choosenShop: false,
                                choosenTimeslot: '',
                                displayedShops: [
                                    {
                                        name: StringVariables.stage2.dropdownLoaderMessage,
                                        addressLine1: '',
                                        area: '',
                                        isArea: true
                                    }
                                ],
                                renderAlert: { error: false },
                                renderTsUnavailable: true,
                                shops: [],
                                timeslots: '',
                                vehicleTsUnavailable: vehicle
                            });
                        }
                        this.toggleLoader(false);
                        //Render alert
                        const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                        const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                        alertMessage.persistentWarning = StringVariables.warningMessages.tsUnresponsive;
                        renderAlert.persistentWarning = true;
                        this.setState({
                            alertMessage: alertMessage,
                            renderAlert: renderAlert
                        });
                    }).catch((error) => {
                        const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                        const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                        console.log(error);
                        alertMessage.error = error.response.data.message;
                        renderAlert.error = true;
                        this.setState({
                            alertMessage: alertMessage,
                            renderAlert: renderAlert
                        });
                    })
                } else {
                    console.log(error);
                    const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                    const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                    alertMessage.error = error.response.data.message;
                    renderAlert.error = true;
                    this.setState({
                        alertMessage: alertMessage,
                        renderAlert: renderAlert
                    });
                    this.toggleLoader(false);
                }
            })
    };
    isSameKind = vehicle => {
        if (!this.state.vehicleMain.kind) {
            return false;
        } else if (this.state.vehicleMain.kind === 'SLÄP' && vehicle.kind === 'SLÄP') {
            return true;
        } else if (this.state.vehicleMain.kind !== 'SLÄP' && vehicle.kind !== 'SLÄP') {
            return true;
        }
    };
    addVehicleVerifier = vehicle => {
        const isMain = this.state.inputPopup.isVehicleMain ? 'vehicleMain' : 'vehicleSecondary';
        const isNotBookableOnWeb = vehicle.products.length < 1;
        if (isNotBookableOnWeb) {
            const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
            alertMessage.info = vehicle.registrationNumber + StringVariables.infoMessages.noProduct;
            renderAlert.info = true;
            this.setState({
                alertMessage: alertMessage,
                renderAlert: renderAlert,
                renderLoader: false
            });
        } else if (this.isSameKind(vehicle)) {
            const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
            alertMessage.warning = StringVariables.warningMessages.vehicleRestriction;
            renderAlert.warning = true;
            this.setState({
                alertMessage: alertMessage,
                renderAlert: renderAlert,
                renderLoader: false
            });
        } else if (vehicle.hasBooking && !vehicle.preliminary && !this.state.isUnchangedRebooking) {
            this.setState({
                renderEditBooking: vehicle.registrationNumber
            })
        } else {
            const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
            renderAlert.warning = false;
            if (vehicle.trafficStatus === 'AVREG') {
                vehicle.products = [];
                alertMessage.warning = StringVariables.warningMessages.vehicleIsDeregistered;
                renderAlert.warning = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert,
                    renderLoader: false
                });
            }
            if (this.state.isUnchangedRebooking && !this.state.vehicleMain.registrationNumber) {
                const products = [];
                //Marks choosen products as recommended and places them upp top.
                this.state.booking.items[0].products.forEach((product1, idx1) => {
                    vehicle.products.forEach((product2, idx2) => {
                        if (idx1 === 0) {
                            vehicle.products[idx2].recommended = false;
                        }
                        if (product1.id === product2.id) {
                            vehicle.products[idx2].recommended = true;
                            products.unshift(vehicle.products[idx2]);
                        } else {
                            products.push(vehicle.products[idx2]);
                        }
                    });
                });
                vehicle.products = products;
                this.setState({ bookingHasChanged: false });
                this.createBookingAndGetShops();
            } else {
                this.setState({ bookingHasChanged: true, choosenShop: false, isUnchangedRebooking: false })
            }
            if (vehicle.preliminary) {
                this.postPreliminaryBooking(vehicle.registrationNumber);
            }
            this.setState({
                [isMain]: vehicle,
                activeStage: clearActiveStage1(this.state.activeStage),
                alertMessage: alertMessage,
                choosenTimeslot: '',
                displayedShops: [
                    {
                        name: StringVariables.stage2.dropdownLoaderMessage,
                        addressLine1: '',
                        area: '',
                        isArea: true
                    }
                ],
                renderAlert: renderAlert,
                shops: [],
                timeslots: ''
            });
        }
        if (vehicle.discounts && vehicle.discounts[0]) {
            const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
            alertMessage.info = StringVariables.infoMessages.vehicleHasDiscount(vehicle);
            renderAlert.info = true;
            this.setState({
                alertMessage: alertMessage,
                renderAlert: renderAlert
            });

        }
    };
    checkRecommendedProductByTS = () => {

    }
    //GetVehicle end
    //(2) PostBooking and GetShops start
    createBookingAndGetShops = () => {
        if (!this.state.isUnchangedRebooking) {
            this.setActiveStage(2)
        }

        //If new booking:
        if (!this.state.booking.id) {
            const payload = this.state.isUnchangedRebooking ? this.state.booking : this.addItemsToTemplate();

            postBookingAPI(payload)
                .then((response) => {
                    this.getShops(response.data.id);
                    this.setState({
                        booking: response.data,
                        bookingHasChanged: false
                    });
                })
                .catch((error) => {
                    const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                    const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                    alertMessage.error = error.response ? error.response.data.message : '';
                    renderAlert.error = true;
                    this.setState({
                        alertMessage: alertMessage,
                        renderAlert: renderAlert
                    });
                })
        }
        //If booking exists but has changed:
        else if (this.state.bookingHasChanged) {
            const id = this.state.booking.id;
            const payload = this.addItemsToTemplate();

            putBookingAPI(id, payload)
                .then((response) => {
                    this.getShops(response.data.id);
                    this.setState({
                        booking: response.data,
                        bookingHasChanged: false
                    });
                })
                .catch((error) => {
                    const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                    const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                    alertMessage.error = error.response.data.message;
                    renderAlert.error = true;
                    this.setState({
                        alertMessage: alertMessage,
                        renderAlert: renderAlert
                    });
                })
        }
    };
    addItemsToTemplate = () => {
        let template = JSON.parse(JSON.stringify(this.state.bookingTemplate));

        if (this.getURLParameter().rebooking || this.state.vehicleMain.preliminary) {
            template = JSON.parse(JSON.stringify(this.state.booking));
            template.items = [];
            template.shopId = null;
            template.status = "NEW"
        }

        if (this.state.vehicleMain.registrationNumber) {
            template.items.push(this.extractItems(this.state.vehicleMain, true));
        }
        if (this.state.vehicleSecondary.registrationNumber) {
            template.items.push(this.extractItems(this.state.vehicleSecondary));
        }
        return template;
    };
    extractItems = (item, isMain) => {
        const newItem = this.getURLParameter().rebooking && isMain ? JSON.parse(JSON.stringify(this.state.booking.items[0])) : {};
        newItem.startTime = null;
        newItem.products = this.extractProductNr(item.products);
        newItem.registrationNumber = item.registrationNumber;
        newItem.trafficStatus = item.trafficStatus;
        newItem.vehicleDimensions = item.dimensions;
        newItem.vehicleKind = item.vehicleKind;
        newItem.verifiedVehicle = item.verifiedVehicle;
        return newItem
    };
    extractProductNr = products => {
        const newProducts = [];
        products.forEach((product) => {
            if (product.recommended) {
                newProducts.push({ id: product.id, productType: product.type });
            }
        });
        return newProducts;
    };

    checkIfInquiryProduct = products => {
        let val = "false";

        products.forEach((product) => {
            if (product.type === "INQUIRY_ON_WEB") {
                val = "true";
            }
        });

        return val
    };

    getShops = id => {
        getShopsAPI(id)
            .then((response) => {
                let choosenShop = '';
                if (this.state.isUnchangedRebooking || this.state.vehicleMain.preliminary) {
                    //Set choosenShop
                    response.data.forEach((shop) => {
                        if (shop.id === this.state.booking.shopId) {
                            choosenShop = shop;
                        }
                    });
                    this.getTimeslots({ choosenShop: { id: this.state.booking.shopId }, get31Days: true, get28Days:false});
                }
                this.setState({
                    choosenShop: choosenShop,
                    displayedShops: response.data.concat(this.extractArea(response.data)),
                    shops: response.data.concat(this.extractArea(response.data)),
                    timeslotTicker: 0
                });

                this.shopsLoaded = true;
                if (this.webBookingLoaded) {
                    this.setStage4Active();
                    this.toggleLoader(false);
                }
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    extractArea = shops => {
        //See if there are more than on shop from same area. Returns array with multi-shop areas.
        const array = [];
        shops.forEach((shop) => {
            //Om den inte finns i lower och inte finns i upper => tryck
            if (array.indexOf(shop.area.toLowerCase()) === -1 && array.indexOf(shop.area.toUpperCase()) === -1) {
                array.push(shop.area.toLowerCase());
            }
            //Om den finns i lower och inte finns i upper => tryck
            else if (array.indexOf(shop.area.toLowerCase()) !== -1 || array.indexOf(shop.area.toUpperCase()) === -1) {
                array[array.indexOf(shop.area.toLowerCase())] = shop.area.toUpperCase();
            }
        });
        const areas = [];
        array.forEach(item => {
            if (item === item.toUpperCase()) {
                areas.push({ area: 'Alla stationer', name: this.formatAreaText(item), isArea: true })
            }
        });
        return areas;
    };
    formatAreaText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    //PostBooking and GetShops end
    //(3) GetTimeslots start
    getTimeslots = payload => {
        this.setState({ renderTimeslotLoader: true });
        //Necessary for first time of getTimeslot since state haven't been set in time for request
        const choosenShop = payload.choosenShop ? payload.choosenShop : this.state.choosenShop;
        let displayedMonth = payload.displayedMonth ? payload.displayedMonth : DateTime.local();
        const get31Days = payload.get31Days ? payload.get31Days : false;
        const get28Days = payload.get28Days ? payload.get28Days : false;
        getTimeslotsAPI(choosenShop, this.state.booking, displayedMonth, get31Days, get28Days)
            .then((response) => {
                let renderLoader = false;
                let choosenTimeslot = this.state.choosenTimeslot;
                let responseData;
                if (get31Days && this.state.timeslots[0]) {
                    responseData = this.state.timeslots.concat(response.data);
                } else {
                    responseData = response.data;
                }
                displayedMonth = this.state.displayedMonth;

                //If not mobile view
                if (!this.state.toggleCalendar) {
                    let toggle = true;
                    const choosenTimeslotInterval = this.state.choosenTimeslot.interval;
                    if (!choosenTimeslotInterval || !this.state.initialCalendarRender) {
                        const choosenDate = choosenTimeslotInterval ? DateTime.fromISO(choosenTimeslotInterval.start) : false;
                        response.data.forEach((timeslot) => {
                            const timeslotDate = DateTime.fromISO(timeslot.date);
                            if (toggle && timeslot.lowestPrice !== null && (!choosenTimeslotInterval || timeslotDate.month !== choosenDate.month)) {
                                displayedMonth = timeslotDate;
                                toggle = false;
                            } else if (choosenTimeslotInterval && timeslotDate.month === choosenDate.month) {
                                displayedMonth = choosenDate;
                            }
                        });
                    }
                }

                if (!this.state.activeStage.stage4 && this.state.timeslotTicker === 0) {
                    choosenTimeslot = {
                        interval: null,
                        price: null,
                        shopName: null,
                        shopId: null
                    };

                    if(this.state.vehicleMain.preliminary) {
                        let defaultDuration = this.state.booking.items[0].defaultDuration;

                        if(this.state.booking.items[0].startTime != null) {
                            choosenTimeslot = {
                                interval: {start: null, end: null},
                                price: this.state.booking.items[0].price,
                                shopName: this.state.choosenShop.name,
                                shopId: this.state.choosenShop.id
                            };

                            choosenTimeslot.interval.start = this.state.booking.items[0].startTime;
                            choosenTimeslot.interval.end = (DateTime.fromISO(choosenTimeslot.interval.start).plus({ milliseconds: defaultDuration })).toISO({ includeOffset: false });

                            this.setState({ renderPreliminaryBookingPopup: true });
                        } else {
                            choosenTimeslot = {
                                price: this.state.booking.items[0].price,
                                shopName: this.state.choosenShop.name,
                                shopId: this.state.choosenShop.id
                            };

                            this.setState({ renderPreliminaryBookingAllreadyTakenPopup: true })
                        }

                    } else if (this.state.isUnchangedRebooking) {

                        if(this.state.booking.items[0].startTime != null) {
                            choosenTimeslot = {
                                interval: {start: null, end: null},
                                price: this.state.booking.items[0].price,
                                shopName: this.state.choosenShop.name,
                                shopId: this.state.choosenShop.id
                            };
                        }


                        choosenTimeslot.interval.start = this.state.booking.items[0].startTime;
                        choosenTimeslot.price = this.state.booking.items[0].price;
                        choosenTimeslot.shopName = this.state.choosenShop.name;
                        choosenTimeslot.shopId = this.state.choosenShop.id;
                        let defaultDuration = this.state.booking.items[0].defaultDuration;

                        choosenTimeslot.interval.end = (DateTime.fromISO(choosenTimeslot.interval.start).plus({ milliseconds: defaultDuration })).toISO({ includeOffset: false });

                        this.putBookingAndTimeslot(choosenTimeslot);
                        renderLoader = true;
                    }
                }

                this.setState({
                    choosenTimeslot: choosenTimeslot,
                    displayedMonth: displayedMonth,
                    initialCalendarRender: false,
                    timeslots: responseData,
                    timeslotTicker: this.state.timeslotTicker + 1,
                    renderLoader: renderLoader,
                    renderTimeslotLoader: false
                });
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert,
                    renderLoader: false,
                    renderTimeslotLoader: false
                });
            })
    };
    //GetTimeslots end
    //(4) PutBooking and PutTimeslot start
    putBookingAndTimeslot = timeslot => {
        this.toggleLoader(true);
        const id = this.state.booking.id;
        const payload = this.addShopToTemplate(timeslot.shopId);
        putBookingAPI(id, payload)
            .then((response) => {
                this.setState({
                    booking: response.data
                });
                this.putTimeslot(timeslot);
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    choosenTimeslot: '',
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    addShopToTemplate = shopId => {
        const booking = JSON.parse(JSON.stringify(this.state.booking));
        booking.items.forEach((item) => {
            item.startTime = null;
        });
        booking.shopId = shopId;
        return booking;
    };
    putTimeslot = timeslot => {
        const booking = JSON.parse(JSON.stringify(this.state.booking));
        this.state.booking.items.forEach((item, idx) => {
            this.addStartTimeToBookingItem(timeslot, idx);
            const bookingId = this.state.booking.id;
            const bookingItemId = item.id;
            const payload = this.addStartTimeToBookingItem(timeslot, idx);
            booking.items[idx] = payload;
            putTimeslotAPI(bookingId, bookingItemId, payload)
                .then(() => {
                    this.setState({
                        bookedTimeslot: this.state.choosenTimeslot,
                        booking: booking
                    });
                    if (!this.state.isUnchangedRebooking) { this.toggleLoader(false) }
                    //If two vehicles only fire the second time:
                    if (idx === this.state.booking.items.length - 1) {
                        this.getBooking();
                    }
                })
                .catch((error) => {
                    const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                    const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                    alertMessage.warning = error.response !== undefined ? error.response.data.message : error.message;
                    renderAlert.warning = true;
                    this.setState({
                        alertMessage: alertMessage,
                        renderAlert: renderAlert,
                        choosenTimeslot: ''
                    });
                    this.toggleLoader(false);
                })
        });
    };
    addStartTimeToBookingItem = (timeslot, idx) => {
        const bookingItems = JSON.parse(JSON.stringify(this.state.booking.items));
        let startTime = idx === 1
            ? DateTime.fromISO(timeslot.interval.start).plus(bookingItems[idx - 1].defaultDuration).toISO({ includeOffset: false })
            : timeslot.interval.start;
        let bookingItem = bookingItems[idx];
        bookingItem.startTime = startTime;
        return bookingItem;
    };
    getBooking() {
        getBookingAPI(this.state.booking.id)
            .then((response) => {
                this.setState({
                    booking: response.data
                });
                if (this.state.isUnchangedRebooking) {
                    this.setState({
                        activeStage: {
                            stage1: true,
                            stage2: false,
                            stage3: false,
                            stage4: null
                        }
                    });
                    this.toggleLoader(false)
                }
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
            })
    };
    //PutBooking and PutTimeslot start end
    //(5) ConfirmBooking and PostPayment start
    confirmBooking = () => {
        this.toggleLoader(true);
        const bookingId = this.state.booking.id;
        const payload = this.prepareBooking();
        putBookingAPI(bookingId, payload)
            .then((response) => {
                this.setState({
                    booking: response.data
                });
                if (this.state.payWithKlarna) {
                    this.postPayment();
                } else {
                    this.toggleLoader(false);
                }

                if(this.state.booking.status === 'BOOKED') {
                    sendGTMEvent('Booking','status', this.state.booking.status)
                }

                this.setActiveStage(4);
                window.history.pushState(null, '', '?bookingId=' + response.data.id);
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    prepareBooking = () => {
        const booking = JSON.parse(JSON.stringify(this.state.booking));
        booking.contactInformation = JSON.parse(JSON.stringify(this.state.contactInformation));
        booking.contactInformation.sendConfirmationEmail = true;

        const hasBookingIncludeInquiry = JSON.parse(JSON.stringify(this.state.bookingIncludeInquiry));

        if(hasBookingIncludeInquiry) {
            booking.status = 'INQUIRY';
        } else {
            booking.status = 'BOOKED';
        }

        return booking;
    };
    postPayment = () => {

        const missingEmail = this.state.booking.contactInformation.email == null ? 'Ange epost för att betala online. Klicka på Ändra vid kontaktuppgifter.' : '';

        if(missingEmail) {
            const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
            alertMessage.warning = missingEmail;
            renderAlert.warning = true;

            this.setState({
                alertMessage: alertMessage,
                renderAlert: renderAlert,
                renderLoader: false
            });
        } else {
            this.toggleLoader(true);
            const id = this.state.booking.id;
            postPaymentAPI(id)
            .then((response) => {
                this.setState({
                    klarnaFrame: response.data,
                    renderKlarna: true
                });
                this.toggleLoader(false);
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
        }
    };
    //ConfirmBooking and PostPayment end
    //(6) GetKlarnaReceipt start
    getKlarnaReceipt = (currentBookingId, checkoutId) => {
        this.toggleLoader(true);
        getKlarnaReceiptAPI(currentBookingId, checkoutId)
            .then((response) => {
                this.setState({
                    klarnaFrame: response.data,
                    renderKlarna: true
                });
                this.toggleLoader(false);
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert,
                    renderTimeslotLoader: false
                });
                this.toggleLoader(false);
            })
    };
    //GetKlarnaReceipt end
    //(7) PostContactInformation start
    postContactInformation = (booking) => {
        this.toggleLoader(true);
        const id = this.state.booking.id;
        const contacts = booking.contactInformation;
        // const contacts = this.state.booking.contactInformation;
        postContactInformationAPI(id, contacts)
            .then(() => {
                this.setState({
                    booking: booking,
                });
                this.toggleLoader(false);
                this.getBooking();
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //PostContactInformation end
    //(8) CancelBooking start
    cancelBooking = () => {
        this.toggleLoader(true);
        const booking = JSON.parse(JSON.stringify(this.state.booking));

        putBookingCanceledAPI(booking.id)
            .then(() => {
                const booking = JSON.parse(JSON.stringify(this.state.booking));
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                const messageString = !booking.items[0].paymentStatus.includes('NOT_PAID') && booking.items[0].paymentStatus.includes('PAID')
                    ? 'Avbokningen är genomförd och återbetald'
                    : 'Avbokningen är genomförd';

                alertMessage.warning = messageString;
                renderAlert.warning = true;
                this.setState({
                    booking: booking,
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });

                this.toggleLoader(false);

                this.getBooking();


            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.warning = error.response !== undefined ? error.response.data.message : error.message;
                renderAlert.warning = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //CancelBooking end

    //(9) GetWebBookingLogin start
    getWebBookingLogin = (bookingId) => {
        this.toggleLoader(true);
        this.getShops(bookingId);
        getBookingAPI(bookingId)
            .then((response) => {
                this.setState({
                    booking: response.data
                });
                this.webBookingLoaded = true;
                if (this.shopsLoaded) {
                    this.setStage4Active();
                    this.toggleLoader(false);
                }
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //GetWebBookingLogin end
    //(10) GetEditBooking start
    getEditBooking = (payload) => {
        this.toggleLoader(true);
        getEditBookingAPI(this.state.renderEditBooking, payload)
            .then((response) => {
                this.setState({
                    booking: response.data
                });
                this.webBookingLoaded = true;
                this.getShops(response.data.id);
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //GetEditBooking end
    //(11) GetRebooking start
    getRebooking = bookingId => {
        this.toggleLoader(true);
        getRebookingAPI(bookingId)
            .then((response) => {
                //Get rid of null values:
                const contactInformation = response.data.contactInformation;
                for (const key in contactInformation) {
                    if (contactInformation.hasOwnProperty(key) && contactInformation[key] === null) {
                        contactInformation[key] = '';
                    }
                }
                this.setState({
                    booking: response.data,
                    contactInformation: contactInformation,
                    isRebooking: true,
                    isUnchangedRebooking: true
                });
                const hasRebookingItem = response.data.items[0];
                if (hasRebookingItem) {
                    this.getVehicle(response.data.items[0].registrationNumber);
                } else {
                    this.toggleLoader(false);
                }
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //GetRebooking end
    //(12) PostPreliminaryBooking start
    postPreliminaryBooking = regNr => {
        this.toggleLoader(true);
        postPreliminaryBookingAPI(regNr)
            .then((response) => {
                const vehicle = JSON.parse(JSON.stringify(this.state.vehicleMain));
                const products = [];
                response.data.items[0].products.forEach((product1, idx1) => {
                    vehicle.products.forEach((product2, idx2) => {
                        if (idx1 === 0) {
                            vehicle.products[idx2].recommended = false;
                        }
                        if (product1.id === product2.id) {
                            vehicle.products[idx2].recommended = true;
                            products.unshift(vehicle.products[idx2]);
                        } else {
                            products.push(vehicle.products[idx2]);
                        }
                    });
                });
                vehicle.products = products;
                this.setState({
                    booking: response.data,
                    isUnchangedRebooking: false,
                    bookingHasChanged: false,
                    vehicleMain: vehicle
                });
                this.getShops(response.data.id);
            })
            .catch((error) => {
                console.log(error);
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //PostPreliminaryBooking end
    //(13) postResendConfirmationEmail start
    postResendConfirmationEmail = (email) => {
        this.toggleLoader(true);
        const regNr = this.state.renderEditBooking;
        const payload = { email: email };
        postResendConfirmationEmailAPI(regNr, payload)
            .then(() => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.info = 'Bokningsbekräftelse har skickats ut igen till den e-post som angavs vid bokningstillfället';
                renderAlert.info = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
            .catch((error) => {
                const alertMessage = JSON.parse(JSON.stringify(this.state.alertMessage));
                const renderAlert = JSON.parse(JSON.stringify(this.state.renderAlert));
                alertMessage.error = error.response.data.message;
                renderAlert.error = true;
                this.setState({
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                });
                this.toggleLoader(false);
            })
    };
    //postResendConfirmationEmail end


    handleScrollAndResize = () => {
        const tabletMin = CSSCommon.mediaBreakpointsInt.tabletMin;
        this.setProgressBarHeight();
        if (this.headerHeight === 0 && this.state.headerHeight === false) {
            this.setState({ headerHeight: true });
        } else if (this.headerHeight !== 0 && this.state.headerHeight === true) {
            this.setState({ headerHeight: false });
        }
        //used to trigger header fixed position
        if (this.progressBarHeight >= this.headerComponentHeight && this.state.headerComponentHeight === false) {
            this.setState({ headerComponentHeight: true });
        } else if (this.progressBarHeight < this.headerComponentHeight && this.state.headerComponentHeight === true) {
            this.setState({ headerComponentHeight: false });
        }
        //Used to trigger alert fixed position
        if (this.progressBarHeight >= (this.headerComponentHeight - this.borderWidth) && this.state.headerComponentHeightAlert === false) {
            this.setState({ headerComponentHeightAlert: true });
        } else if (this.progressBarHeight < (this.headerComponentHeight - this.borderWidth) && this.state.headerComponentHeightAlert === true) {
            this.setState({ headerComponentHeightAlert: false });
        }
        if (document.documentElement.getBoundingClientRect().width < tabletMin && !this.state.viewportMobile) {
            this.setState({ viewportMobile: true });
        } else if (document.documentElement.getBoundingClientRect().width >= tabletMin && this.state.viewportMobile) {
            this.setState({ toggleCalendar: false, viewportMobile: false });
        }
        if (document.documentElement.getBoundingClientRect().height < tabletMin && !this.state.viewportMobile) {
            this.setState({ viewportHeight: true });
        } else if (document.documentElement.getBoundingClientRect().height >= tabletMin && this.state.viewportMobile) {
            this.setState({ viewportHeight: false });
        }
        if (window.innerHeight !== this.state.viewportHeightPx) {
            //Because Safari-iOS sucks. vh doesn't work properly on iOs. Hate...
            //Safari-iOS shall now be known by its proper name: "new fucking IE"
            this.setState({ viewportHeightPx: window.innerHeight });
        }
    };

    setProgressBarHeight = () => {
        const headerHeight = document.getElementById('header').getBoundingClientRect().bottom;
        this.headerComponentHeight = document.getElementById('header-component').getBoundingClientRect().bottom;
        this.borderWidth = document.getElementById('header-component').offsetHeight - document.getElementById('header-component').clientHeight;
        this.progressBarHeight = document.getElementById('progress-bar-container').getBoundingClientRect().height;
        this.headerHeight = headerHeight >= 0 ? headerHeight : 0;
    };

    toggleBodyOverflowOnPopup = () => {
        if (this.state.inputPopup.renderPopup ||
            this.state.renderLoader ||
            this.state.renderInfo ||
            (this.state.renderSearchDropdown && this.state.viewportMobile) ||
            this.state.renderFirstFreeTimeslot ||
            this.state.renderCheapestTimeslot ||
            this.state.renderCalendar ||
            this.state.renderMap ||
            this.state.renderKlarna ||
            this.state.renderEditContacts ||
            this.state.renderCancelBooking ||
            this.state.renderTerms ||
            this.state.renderTsUnavailable ||
            this.state.renderEditBooking ||
            this.state.renderPreliminaryBookingPopup ||
            this.state.renderPreliminaryBookingAllreadyTakenPopup ||
            this.state.renderBookingHasChangedWarning) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    };

    setStage4Active = () => {
        const newActiveStage = JSON.parse(JSON.stringify(this.state.activeStage));
        newActiveStage.stage1 = false;
        newActiveStage.stage2 = false;
        newActiveStage.stage3 = false;
        newActiveStage.stage4 = true;
        this.setState({ activeStage: newActiveStage });
    };
    //SetActiveStage start
    setActiveStage = (stageNr) => {
        const activeStage = JSON.parse(JSON.stringify(this.state.activeStage));
        if (!this.isActive(4)) {
            switch (stageNr) {
                case 1:
                    activeStage.stage1 = true;
                    activeStage.stage2 = activeStage.stage2 !== null ? false : null;
                    activeStage.stage3 = activeStage.stage3 !== null ? false : null;
                    break;
                case 2:
                    activeStage.stage1 = false;
                    activeStage.stage2 = true;
                    activeStage.stage3 = activeStage.stage3 !== null ? false : null;
                    break;
                case 3:
                    activeStage.stage1 = false;
                    activeStage.stage2 = false;
                    activeStage.stage3 = true;
                    break;
                case 4:
                    activeStage.stage1 = false;
                    activeStage.stage2 = false;
                    activeStage.stage3 = false;
                    activeStage.stage4 = true;
                    break;
                default:
                    break;
            }
            this.setState({ activeStage: activeStage });
        }
    };
    isActive = (stageNr) => {
        if (this.state.activeStage['stage' + stageNr]) {
            return true
        } else if (this.state.activeStage['stage' + stageNr] === false) {
            if (this.state.activeStage['stage' + (stageNr + 1)]) {
                return true;
            } else if (this.state.activeStage['stage' + (stageNr + 2)]) {
                return true;
            } else if (this.state.activeStage['stage' + (stageNr + 3)]) {
                return true;
            }
            return false;
        }
    };
    //SetActiveStage end
    //URLParameterRouter start
    URLParameterRouter = () => {
        const URLParameter = this.getURLParameter();
        //If receipt parameters
        if (URLParameter.bookingId && URLParameter.checkoutId) {
            this.getKlarnaReceipt(URLParameter.bookingId, URLParameter.checkoutId);
            this.getWebBookingLogin(URLParameter.bookingId);
        }
        //If rebboking parameters
        else if (URLParameter.rebooking) {
            this.getRebooking(URLParameter.bookingId);
        }
        //If booking confirmation parameters
        else if (URLParameter.bookingId) {
            this.getWebBookingLogin(URLParameter.bookingId);
        }
        //If regNr or empty parameters
        else {
            this.getVehicleFromURL();
        }
    };
    getURLParameter = () => {
        //retrieve the booking ID from url
        const urlParameter = this.createURLParameter();
        this.checkoutId = urlParameter.checkoutId;
        return urlParameter
    };
    createURLParameter = () => {
        const urlParameter = {};
        const sPageURL = window.location.search.substring(1);
        const sURLVariables = sPageURL.split('&');
        for (let i = 0; i < sURLVariables.length; i++) {
            const keysAndValues = sURLVariables[i].split('=');
            urlParameter[keysAndValues[0]] = decodeURIComponent(keysAndValues[1]);
        }
        return urlParameter
    };
    getVehicleFromURL = () => {
        const regNr = this.createURLParameter().regNr;
        if (regNr) {
            this.getVehicle(regNr);
        } else {
            this.setState((prevState) => ({
                inputPopup: {
                    renderPopup: true,
                    isEdit: prevState.inputPopup.isEdit,
                    isVehicleMain: prevState.inputPopup.isVehicleMain
                }
            }));
        }
    };
    //URLParameterRouter end
    onPopState = e => {
        const page = e.state ? e.state.page : e.state;
        let inputPopup = JSON.parse(JSON.stringify(this.state.inputPopup));
        inputPopup.renderPopup = false;
        if (page === 'webBookingLogin' || !page) {
            window.history.pushState(null, 'page4', `/${window.location.search}`);
            this.setState({ renderEditContacts: false, renderKlarna: false, renderMap: false });
        } else if (page.toString().includes('renderPopup')) {
            inputPopup.renderPopup = true;
            this.setState({ inputPopup: inputPopup });
        } else if (page.toString().includes('render')) {
            this.setState({ [page]: true });
        } else {
            this.setActiveStage(page);
            this.setState({
                renderCalendar: false,
                renderCheapestTimeslot: false,
                renderEditBooking: false,
                renderFirstFreeTimeslot: false,
                renderInfo: false,
                renderKlarna: false,
                renderMap: false,
                renderSearchDropdown: this.state.viewportMobile ? false : this.state.renderSearchDropdown,
                renderTerms: false,
                inputPopup: inputPopup
            });
        }
    };
    isIOS = () => {
        if ((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform))) {
            this.setState({ isIOS: true });
        }
    };
    componentDidMount() {
        this.handleScrollAndResize();
        window.addEventListener('scroll', this.handleScrollAndResize, { passive: true });
        window.addEventListener('resize', this.handleScrollAndResize);
        this.URLParameterRouter();
        this.isIOS();
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollAndResize, { passive: true });
        window.removeEventListener('resize', this.handleScrollAndResize);
    }
    componentDidUpdate() {
        this.toggleBodyOverflowOnPopup();
        //If iOS than no browser history. Doesn't work on iOS...
        if (!this.state.isIOS) {
            window.onpopstate = e => { this.onPopState(e) }
        }
    }
    render() {
        return this.props.children({
            appState: this.state,
            setAppState: this.setAppState,
            nextProp: this.props.nextProps
        });
    }
}

export default AppState;