import React, { Component } from 'react';
import styled from 'styled-components';
import {SVG} from "../../../svg/SVG.js"
import {CSSCommon} from "../../../variables/CSSVariables";
import {Button , SVGContainer, TextH3, TextH5} from "../../../variables/StyledComponents";

import DropdownComponent from "./DropdownComponent";

const green             = CSSCommon.color.green;
const fontWeightHeavy   = CSSCommon.fontWeight.heavy;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 3.8rem;
    padding: 0.9rem;
    padding-top: 1.5rem;
`;
const H3 = styled(TextH3)`
    font-weight: ${fontWeightHeavy};
    color: ${green};
`;
const H5 = styled(TextH5)`
    margin-bottom: 2rem;
`;
const SVGCont = styled(SVGContainer)`
    margin-right: 1rem;
`;

const SVGContDanger = styled(SVGContainer)`
    align-self: center;
    width: 5.7rem;
    height: 4.8rem;
    margin-bottom: 1rem;
`;
const WideButton = styled(Button)`
    max-width: 100%;
`;
const VehicleContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

class EditBookingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderReference: {}
        }
    }
    handleSubmit = () => {
        const payload = this.vehicleTsUnavailable();
        this.props.setAppState({renderTsUnavailable: false, choosenVehicleTsUnavailable: false}, {type: 'vehicleTsUnavailable', payload: payload});
    };
    vehicleTsUnavailable = () => {
        const vehicle = this.props.appState.choosenVehicleTsUnavailable;
        return (
            {
                hasBooking: vehicle.hasBooking,
                dataExpired: false,
                products: vehicle.products,
                color: '',
                category: '',
                dimensions: {
                    grossWeight: null,
                    length: null,
                    width: null,
                    height: null,
                    wheelBase: null,
                    towHitchDistanceNational: null,
                    towHitchDistanceEU: null
                },
                makeAndModel: '',
                inspectionTo: '',
                nextInspectionTo: false,
                trafficStatus: '',
                drivingBanInEffect: '',
                registrationNumber: this.props.appState.vehicleTsUnavailable.registrationNumber.toUpperCase(),
                vehicleKind: vehicle.code,
                kind: vehicle.code,
                modelYear: '',
                bodyCode: ''
            }
        )
    };
    handleKeyPress = e => {
        if (e.nativeEvent.key === 'Enter') {
            this.handleSubmit();
        } else if(e.nativeEvent.key === 'Escape'){
            this.props.setAppState({renderTsUnavailable: false});
        }
    };
    render() {
        const {appState, setAppState} = this.props;
        const disabled = !this.props.appState.choosenVehicleTsUnavailable;
        return (
            <ComponentContainer>
                <SVGContDanger>
                    {SVG.danger}
                </SVGContDanger>
                <H5>
                    Det verkar som om att vi för tillfället inte kan kontakta transportstyrelsen för fordonsdata.
                    Kontrollera att registreringsnummret är rätt och välj i så fall fordonstyp för att gå vidare.
                </H5>
                <VehicleContainer>
                    <SVGCont>
                        {SVG.car}
                    </SVGCont>
                    <H3>{this.props.appState.vehicleTsUnavailable.registrationNumber.toUpperCase()}</H3>
                </VehicleContainer>
                <DropdownComponent {...{appState, setAppState}}/>
                <WideButton disabled={disabled} onlyNext onClick={this.handleSubmit}>Lägg till</WideButton>
            </ComponentContainer>
        );
    }
}
export default EditBookingComponent






























