import React from 'react';
import styled, {css} from 'styled-components';
import {CSSCommon} from "../../variables/CSSVariables";
import {TextH3, TextH4} from "../../variables/StyledComponents";

const green         = CSSCommon.color.green;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 27.8rem;
    max-width: 30.2rem;
    min-height: 3.8rem;
    border: solid 0.1rem ${green};
    padding: 1rem;
    margin-bottom: 2rem;
    @media(${tabletMin}){
        min-height: 5.2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    ${props => props.secondary && css`
        border-top: none;
        margin-top: -2rem;
    `}
`;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const H4 = styled(TextH4)`
    padding-top: 0.5rem;
    margin-left: 0;
`;
const ProductH4 = styled(TextH4)`
    padding-top: 0.5rem;
`;

const VehicleComponent = props => {
    const textVehicle = () => {
        const type = props.secondary ? 'vehicleSecondary' : 'vehicleMain';
        return (
            <TextContainer>
                <TextH3>{props.appState[type].registrationNumber}</TextH3>
                <H4>{props.appState[type].makeAndModel}</H4>
            </TextContainer>
        )
    };
    const textProduct = () => {
        const vehicleNumber = props.secondary ? 'vehicleSecondary' : 'vehicleMain';
        const regNr = props.appState[vehicleNumber].registrationNumber;
        const regNrBooking = props.appState.booking.items[0].registrationNumber;
        const type = regNr === regNrBooking ? 0 : 1;
        return(
            props.appState.booking.items[type].products.map((product, idx)=>{
                return <ProductH4 key={idx}>{product.shortDescription}</ProductH4>
            })
        )
    };
    return (
        <ComponentContainer secondary={props.secondary}>
            {textVehicle()}
            {textProduct()}
        </ComponentContainer>
    );
};
export default VehicleComponent






























