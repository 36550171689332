import React, { Component } from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {SVGClose} from "../../variables/StyledComponents";
import {StringVariables} from '../../variables/StringVariables'
import {clearActiveStage1} from '../../resource-functions/HelperFunctions'
import {CSSCommon} from "../../variables/CSSVariables";
import {SVG} from "../../svg/SVG.js"

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 27.8rem;
    max-width: 30.2rem;
    width: calc(100% - 2.2rem);
    padding: 1rem;
    border: solid 0.1rem ${green};
`;
const VehicleHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    @media(${tabletMin}){
        padding-bottom: 1rem;
    }
`;
const VehicleHeader = styled.h3`
    font-size: ${CSSCommon.fontSize.h3};
    font-weight: ${CSSCommon.fontWeight.heavy};
    color: ${green};
`;
const VehicleDrivingBan = styled(VehicleHeader)`
    margin-left: -8rem;
    color: red;
`;
const VehicleLastInspectionDate = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
    border-bottom: solid 0.2rem ${grey};
    padding-bottom: 1rem;
    @media(${tabletMin}){
        padding-bottom: 1.5rem;
    }
`;
const VehicleMake = styled.h4`
    padding-top: 1rem;
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
    @media(${tabletMin}){
        padding-top: 1.5rem;
    }
`;
const VehicleInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 2.9rem;
    padding-top: 0.5rem;
    @media(${tabletMin}){
        padding-top: 1rem;
    }
`;
const VehicleDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 13rem;
`;
const VehicleType = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
`;
const VehicleColor = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
`;
const VehicleModelYear = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
`;
const VehicleWeight = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
`;
const SVGVehicle = styled.svg`
    width: 6.6rem;
    height: 2.7rem;
    margin-right: 2rem;
    fill: ${black};
    ${props => props.drivingBan && css`
        fill: red;
    `}
`;
const SVGCloseVehicle = styled(SVGClose)`
    z-index: 9;
`;

class Stage1VehicleComponent extends Component {
    constructor(props) {
        super(props);
        this.vehicle = '';
    }
    removeVehicle = () => {
        const newAppState = {
            activeStage: clearActiveStage1(this.props.appState.activeStage),
            bookingHasChanged: true,
            choosenShop: false,
            choosenTimeslot: '',
            displayedShops: [
                {
                    name: StringVariables.stage2.dropdownLoaderMessage,
                    addressLine1: '',
                    area: '',
                    isArea: true
                }
            ],
            isUnchangedRebooking: false,
            bookingIncludeInquiry: false,
            renderAlert: {error: false},
            shops: [],
            timeslots: '',
            vehicleSecondary: {}
        };
        if(this.props.main && this.props.appState.vehicleSecondary.kind){
            newAppState.vehicleMain = this.props.appState.vehicleSecondary;
        } else if(!this.props.appState.vehicleSecondary.kind) {
            newAppState.vehicleMain = {};
        }
        if((this.props.appState.isRebooking || this.props.appState.vehicleMain.preliminary) && !this.props.appState.bookingHasChanged) {
            this.props.setAppState({renderBookingHasChangedWarning: newAppState});
        } else {
            this.props.setAppState(newAppState);
            //To reset browser history
            window.history.pushState({page: 1}, 'page1', );
        }
    };
    vehicleTypeString() {
        let type = '';
        switch (this.vehicle.kind) {
            case 'PB':
                type = 'PERSONBIL';
                break;
            case 'LB':
                type = 'LASTBIL';
                break;
            case 'MC':
                type = 'MOTORCYKEL';
                break;
            case 'MOPED':
                type = 'MOPED';
                break;
            case 'TR':
                type = 'TRAKTOR';
                break;
            case 'SLÄP':
                type = 'SLÄPVAGN';
                break;
            default:
                break;
        }
        return type;
    }
    SVGType() {
        let type = '';
        switch (this.vehicle.kind) {
            case 'PB':
                type = SVG.car;
                break;
            case 'LB':
                type = SVG.truck;
                break;
            case 'MC':
                type = SVG.mc;
                break;
            case 'MOPED':
                type = SVG.mc;
                break;
            case 'TR':
                type = SVG.tractor;
                break;
            case 'SLÄP':
                type = SVG.trailer;
                break;
            default:
                break;
        }
        return type;
    }
    componentDidMount() {
        //Scroll secondary vehicle into view when added.
        if(!this.props.main && this.props.appState.viewportMobile){
            document.getElementById('vehicle-container').scrollIntoView();
            document.documentElement.scrollTop -= document.getElementById('progress-bar-container').getBoundingClientRect().height + 10;
        }
    }
    shouldComponentUpdate(nextProps) {
        if (this.vehicle.registrationNumber !== nextProps.appState[this.isMain].registrationNumber) {
            return true;
        }
        return false;
    }
    render() {
        this.isMain = this.props.main ? 'vehicleMain' : 'vehicleSecondary';
        this.vehicle = this.props.main ? this.props.appState.vehicleMain : this.props.appState.vehicleSecondary;
        const className = !this.props.main ? 'vehicle-container' : '';
        const drivingBanInEffect = this.vehicle.drivingBanInEffect ? 'KÖRFÖRBUD' : null;
        const inspectionDate = this.vehicle.inspectionTo ? 'Sista besiktningsdatum - ' + this.vehicle.inspectionTo : '';
        const weight = this.vehicle.dimensions.grossWeight ? this.vehicle.dimensions.grossWeight + ' kg' : '';
        return (
            <ComponentContainer id={className} tsUnavailable={!this.vehicle.inspectionTo}>
                <VehicleHeaderContainer>
                    <VehicleHeader>{this.vehicle.registrationNumber}</VehicleHeader>
                    <VehicleDrivingBan>{drivingBanInEffect}</VehicleDrivingBan>
                    <SVGCloseVehicle onClick={this.removeVehicle}>
                        {SVG.close}
                    </SVGCloseVehicle>
                </VehicleHeaderContainer>
                <VehicleLastInspectionDate>{inspectionDate}</VehicleLastInspectionDate>
                <VehicleMake>{this.vehicle.makeAndModel}</VehicleMake>
                <VehicleInfoContainer>
                    <VehicleDetailsContainer>
                        <VehicleType>{this.vehicleTypeString()}</VehicleType>
                        <VehicleColor>{this.vehicle.color}</VehicleColor>
                        <VehicleModelYear>{this.vehicle.modelYear}</VehicleModelYear>
                        <VehicleWeight>{weight}</VehicleWeight>
                    </VehicleDetailsContainer>
                    <SVGVehicle drivingBan={drivingBanInEffect}>
                        {this.SVGType()}
                    </SVGVehicle>
                </VehicleInfoContainer>
            </ComponentContainer>
        )
    }
}
export default Stage1VehicleComponent;
