import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import validator from 'email-validator';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import {Button, Input, Label, SVGLabel, SVGContainer, TextH3, TextH5} from "../../variables/StyledComponents";

const green             = CSSCommon.color.green;
const fontWeightHeavy   = CSSCommon.fontWeight.heavy;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.9rem;
    padding-top: 0.5rem;
`;
const TransformContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 3.8rem;
    transition: all .25s;
    ${props => props.bookingConfirmation && css`
        transform: translateX(-0.9rem);
    `}
`;
const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 3.8rem;
    ${props => props.marginLeft && css`
        margin-left: 0.9rem;
        padding-bottom: 1.3rem;
    `}
    ${props => props.marginRight && css`
        margin-right: 0.9rem;
    `}
`;
const InputContainer = styled.div`
    margin: 2rem 0;
`;
const LabelEditBooking = styled(Label)`
    margin-top: -1.5rem;
`;
const H3 = styled(TextH3)`
    font-weight: ${fontWeightHeavy};
    color: ${green};
`;
const H5 = styled(TextH5)`
    cursor: pointer;
    ${props => props.green && css`
        color: ${green};
    `}
`;
const TextContainer = styled.div`
    display: flex;
    white-space: pre;
    &:hover {
        text-decoration: underline;
    }
`;
const SVGCont = styled(SVGContainer)`
    margin-right: 1rem;
`;
const WideButton = styled(Button)`
    max-width: 100%;
    margin-top: 1.7rem;
`;
const VehicleContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
`;

class EditBookingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            orderReference: '',
            validateEmail: null,
            validateOrderReference: null
        }
    }
    onClick = () => {
        if(this.props.appState.renderResendBookingConfirmation){
            if(this.state.validateEmail === null){
                this.setState({validateEmail: validator.validate(this.state.email)})
            }
            if(validator.validate(this.state.email)){
                this.props.setAppState({renderEditBooking: false, renderResendBookingConfirmation: false}, {
                    type: 'postResendConfirmationEmail',
                    payload: this.state.email
                });
            }
        } else {
            if (this.state.validateOrderReference === null) {
                this.setState({validateOrderReference: this.state.orderReference.length > 8})
            }
            if (this.state.orderReference.length > 8) {
                this.props.setAppState({renderEditBooking: false}, {
                    type: 'getEditBooking',
                    payload: this.state.orderReference
                });
            }
        }
    };
    alterOrderReference = (e) => {
        this.setState({orderReference: e.target.value});
    };
    alterEmail = (e) => {
        this.setState({email: e.target.value});
    };
    toggleResendBookingConfirmation = () => {
        this.props.setAppState({renderResendBookingConfirmation: true});
    };
    handleKeyPress = e => {
        if (e.nativeEvent.key === 'Enter') {
            this.onClick();
        }
    };
    orderReferenceIsValid = () => {
        if (this.state.validateOrderReference === null){
            return true
        } else {
            return this.state.orderReference.length > 8
        }
    };
    emailIsValid = () => {
        if (this.state.validateEmail === null){
            return true
        } else {
            return validator.validate(this.state.email)
        }
    };
    componentWillUnmount(){
        window.history.pushState({page: 1}, 'page1', );
        this.props.setAppState({renderResendBookingConfirmation: false});
    }
    componentDidMount(){
       document.getElementById('edit-booking-component').scrollIntoView();
    }
    render() {
        const buttonText = this.props.appState.renderResendBookingConfirmation ? 'Skicka' : 'Öppna bokning';
        return (
            <ComponentContainer id='edit-booking-component'>
                <VehicleContainer>
                    <SVGCont>
                        {SVG.car}
                    </SVGCont>
                    <H3>{this.props.appState.renderEditBooking.toUpperCase()}</H3>
                </VehicleContainer>
                <TransformContainer bookingConfirmation={this.props.appState.renderResendBookingConfirmation}>
                    { !this.props.appState.renderResendBookingConfirmation ? 
                        <SubContainer marginRight>
                            <TextH5>
                                Det finns en befintlig bokning på detta registeringsnummer,
                                vänligen ange ditt bokningsnummer för att se, ändra eller
                                betala din bokning. Dessa uppgifter har skickats till din
                                angivna e-post.
                            </TextH5>
                            <InputContainer>
                                <LabelEditBooking
                                    isValid={this.orderReferenceIsValid()}
                                >
                                    <SVGLabel>{SVG.danger}</SVGLabel>
                                    Fyll i korrekt bokningsnummer:
                                </LabelEditBooking>
                                <Input
                                    autoFocus
                                    type="tel"//To trigger number keyboard on mobile
                                    placeholder='Bokningsnummer'
                                    maxLength='9'
                                    isValid={this.orderReferenceIsValid()}
                                    onKeyDown={this.handleKeyPress}
                                    onChange={e => this.alterOrderReference(e)}
                                />
                            </InputContainer>
                            <TextContainer onClick={this.toggleResendBookingConfirmation}>
                                <H5>Klicka </H5>
                                <H5 green>här</H5>
                                <H5> om du har glömt ditt bokningsnummer.</H5>
                            </TextContainer>
                        </SubContainer> : 
                        <SubContainer marginLeft>
                            <TextH5>Här kan du få din bokningsbekräftelse skickad till dig genom att ange samma e-post som du
                                angav vid bokningstillfället.
                            </TextH5>
                            <InputContainer>
                                <LabelEditBooking
                                    isValid={this.emailIsValid()}
                                >
                                    <SVGLabel>{SVG.danger}</SVGLabel>
                                    Fyll i korrekt e-post:
                                </LabelEditBooking>
                                <Input
                                    autoFocus
                                    type="email"
                                    placeholder='E-post'
                                    isValid={this.emailIsValid()}
                                    onKeyDown={this.handleKeyPress}
                                    onChange={e => this.alterEmail(e)}
                                />
                            </InputContainer>
                        </SubContainer> 
                    }
                </TransformContainer>
                <WideButton onlyNext onClick={this.onClick}>{buttonText}</WideButton>
            </ComponentContainer>
        );
    }
}
export default EditBookingComponent






























