import React, { Component } from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {SVGClose, SVGContainer} from "../variables/StyledComponents";
import {SVG} from "../svg/SVG.js"
import {CSSCommon} from "../variables/CSSVariables";

const blackout      = CSSCommon.color.blackout;
const green         = CSSCommon.color.green;
const margin        = CSSCommon.margin.normal;
const white         = CSSCommon.color.white;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    align-items: center;
    justify-content: center;
    padding: 1rem;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: calc(${props => props.viewport}px - 2rem);
    width: calc(100% - 2rem);
    z-index: 100;
    transition: background .2s;
    ${props => props.startAnimation && css`
        background: ${blackout};
    `}
`;
const Popup = styled.div`
    width: 100%;
    min-width: 28rem;
    max-width: 45rem;
    max-height: calc(${props => props.viewport}px - 0rem);
    padding: 0 1px 1px 1px;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: ${white};
    transform: scale(0.9);
    opacity: 0;
    transition: transform .2s, opacity .2s;
    @media(${tabletMin}) {
        max-height: calc(${props => props.viewport}px - 1.9rem);
    }
    ${props => props.thin && css`
        max-width: 30.4rem;
    `}
    ${props => props.cal && css`
        max-height: calc(${props => props.viewport}px - 1.9rem);
    `}
    ${props => props.startAnimation && css`
        transform: scale(1);
        opacity: 1;
    `}
    ${props => props.terms && css`
        max-width: 80rem;
    `}
    ${props => props.klarna && css`
        max-width: 53rem;
    `}
    ${props => props.tsUnavailable && css`
        overflow: unset;
    `}
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 4.3rem;
    padding: 0 1rem;
    z-index: 100;
`;
const HeaderDivider = styled.div`
    display: flex;
    height: 1.8rem;
    padding: 1rem 0 1.5rem 0;
`;
const SVGHeader = styled(SVGContainer)`
    height: 1.8rem;
    width: 1.8rem;
    padding-bottom: 10px;
    ${props => props.green && css`
        fill: ${green};
    `}
`;
const SVGPopupClose = styled(SVGClose)`
    margin: 1rem 0 0.5rem 1rem;
    z-index: 0;
    @media(${mobileMax}) {
        position: relative;
        left: 1rem;
    	width: 3.6rem;
    	height: 3.1rem;  
    	padding: 1rem 1rem 0.5rem 1rem;
    	margin: 0;
    } 
    ${props => props.terms && css`
        @media(${tabletMin}) {
            width: 2.5rem;
            height: 2.5rem;
        }
    `}
`;
const Header = styled.h2`
    font-size: ${CSSCommon.fontSize.h2};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
    margin-left: ${margin};
`;
const BodyContainer = styled.div`
    overflow: hidden;
    ${props => props.overflowAuto && css`
        max-height: calc(${props => props.viewport}px - 6.2rem);
        overflow-y: auto;
    `}
`;
//Sole purpose of PseudoButton is for tabbing. Takes focus so that next tab is into popup without displaying ugly
//focus border on close button. Silly i know.
const PseudoButton = styled.button `
    width: 0;
    height: 0;
    &:focus {
       border: none;
    }
`;

class PopupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {startAnimation: false};
    }
    closePopup = () => {
        this.setState({startAnimation: false});
        setTimeout(() => {
            this.props.setAppState({[this.props.type]: false});
        }, 200);
    };
    stopPropagation = e => {
        e.stopPropagation();
    };
    handleKeyPress = e => {
        if(e.key === 'Escape'){
            this.closePopup();
        }
    };
    componentDidMount() {
        if(!this.state.startAnimation) {
            this.setState({startAnimation: true});
        }
        if(window.history.state === null || !window.history.state.page.toString().includes('render')){
            window.history.pushState({page: this.props.type}, this.props.type, );
        }
        document.getElementById('button-component-cancel').focus();
        document.addEventListener('keyup', this.handleKeyPress);
    };
    componentWillUnmount(){
        setTimeout(() => {
            if(!this.props.appState.isIOS
                && !this.props.appState.activeStage.stage1
                && window.history.state
                && window.history.state.page.toString().includes('render'))
            {
                window.history.back();
            }
        }, 200);
        document.removeEventListener('keyup', this.handleKeyPress);
    }
    render() {
        return (
            <ComponentContainer
                viewport={this.props.appState.viewportHeightPx}
                onClick={this.closePopup}
                startAnimation={this.state.startAnimation}
            >
                <Popup
                    viewport={this.props.appState.viewportHeightPx}
                    cal={this.props.cal}
                    thin={this.props.thin}
                    terms={this.props.terms}
                    klarna={this.props.klarna}
                    tsUnavailable={this.props.tsUnavailable}
                    onClick={this.stopPropagation}
                    startAnimation={this.state.startAnimation}
                >
                    <HeaderContainer>
                        <HeaderDivider>
                            <SVGHeader green={this.props.green}>
                                {SVG[this.props.svg]}
                            </SVGHeader>
                            <Header> {this.props.header}</Header>
                        </HeaderDivider>
                        <PseudoButton id="button-component-cancel"/>
                        <SVGPopupClose
                            onClick={this.closePopup}
                            terms={this.props.terms}
                        >
                            {SVG.close}
                        </SVGPopupClose>
                    </HeaderContainer>
                    <BodyContainer
                        viewport={this.props.appState.viewportHeightPx}
                        overflowAuto={this.props.overflowAuto}
                    >
                        {this.props.children}
                    </BodyContainer>
                </Popup>
            </ComponentContainer>
        );
    }
}
export default PopupComponent;
