import React, { Component } from 'react';
import styled from 'styled-components';
//Styled components
import {SVGContainer} from "../../variables/StyledComponents";
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const black     = CSSCommon.color.black;
const green     = CSSCommon.color.green;
const margin    = CSSCommon.margin.normal;

const InfoContainer = styled.div`
    padding: 0 1rem;
    overflow-x: hidden;
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 1.8rem;
    padding: 1rem 0 2rem 0;
`;
const HeaderDivider = styled.div`
    display: flex;
    height: 1.8rem;
`;
const Header = styled.h2`
    font-size: ${CSSCommon.fontSize.h2};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
    margin-left: ${margin};
`;
const SubHeader = styled.h4`
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${green};
    margin-bottom: 0.5rem;
`;
const Body = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
    margin-bottom: 1rem;
`;

class InfoComponent extends Component {
    SVGAndText = (kind) => {
        let svg = '';
        let text = '';
        switch (kind) {
            case 'PB':
                svg = SVG.car;
                text = 'Personbil';
                break;
            case 'LB':
                svg = SVG.truck;
                text = 'Lastbil';
                break;
            case 'MC':
                svg = SVG.mc;
                text = 'Motorcykel';
                break;
            case 'TR':
                svg = SVG.tractor;
                text = 'Traktor';
                break;
            case 'SLÄP':
                svg = SVG.trailer;
                text = 'Släpvagn';
                break;
            default:
                break
        }
        return {...{svg, text}};
    };
    formatText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    renderProducts = (vehicle) => {
        return vehicle.products.map((product, idx) => {
            return (
                <div key={idx}>
                    <SubHeader>{product.shortDescription}</SubHeader>
                    <Body>{product.longDescription}</Body>
                </div>
            )
        })
    };
    renderHeader = (type) => {
        return (
            <HeaderContainer>
                <HeaderDivider>
                    <SVGContainer>
                        {this.SVGAndText(type).svg}
                    </SVGContainer>
                    <Header>{this.formatText(this.SVGAndText(type).text)}</Header>
                </HeaderDivider>
            </HeaderContainer>
        )
    };
    render() {
        const {appState} = this.props;
        const vehicleMainHeader = appState.vehicleMain.registrationNumber
            ? this.renderHeader(appState.vehicleMain.kind) : null;
        const vehicleMainProducts = appState.vehicleMain.registrationNumber
            ? this.renderProducts(appState.vehicleMain) : null;
        const vehicleSecondaryHeader = appState.vehicleSecondary.registrationNumber
            ? this.renderHeader(appState.vehicleSecondary.kind) : null;
        const vehicleSecondaryProducts = appState.vehicleSecondary.registrationNumber
            ? this.renderProducts(appState.vehicleSecondary) : null;
        return (
            <InfoContainer>
                    {vehicleMainHeader}
                    {vehicleMainProducts}
                    {vehicleSecondaryHeader}
                    {vehicleSecondaryProducts}
            </InfoContainer>
        );
    }
}
export default InfoComponent;
