import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { clearActiveStage2, sendGTMEvent } from '../../../resource-functions/HelperFunctions'
//Styled components
import { SVGClose, TextH6 } from "../../../variables/StyledComponents";
import { DateTime } from 'luxon';
import { SVG } from "../../../svg/SVG.js"
import { CSSCommon } from "../../../variables/CSSVariables";
import { StringVariables } from "../../../variables/StringVariables";
import Station from "./Station";

const black             = CSSCommon.color.black;
const blackout          = CSSCommon.color.blackout;
const green             = CSSCommon.color.green;
const grey              = CSSCommon.color.grey;
const hover             = CSSCommon.color.hover;
const white             = CSSCommon.color.white;
const yellow            = CSSCommon.color.yellow;
const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax         = CSSCommon.mediaBreakpoints.mobileMax;
const tabletMinInt      = CSSCommon.mediaBreakpointsInt.tabletMin;

const ComponentContainer = styled.div`
    ${props => props.renderSearch && css`
    margin-bottom: -10rem;
    z-index: 9;
        @media(${mobileMax}){
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            height: ${props => props.viewport}px;
            width: 100%;
            background: ${blackout};
            z-index: 50;
        }
    `}
`;
const SearchStationContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: calc(${props => props.viewport}px - 3.2rem);
    padding: 0.5rem;
    border: solid 0.1rem ${white};
    background-color: ${white};
    fill: ${green};
    cursor: default;
    ${props => props.scroll && css`
        height: 100%;
    `}
    ${props => props.renderSearch && css`
        width:  calc(100vw - 2rem);
        margin: 1rem 1rem 1rem 1rem;
        @media(${tabletMin}){
            min-height: 5rem;
            width: auto;
            max-height: calc(${props => props.viewport}px - ${props => props.top}px + ${props => props.headerHidden}px - 11.8rem);
            margin: 1rem 0 0 0;
            border-color: ${green};
            z-index: 10;
        }
    `}
    ${props => !props.renderSearch && css`
        justify-content: center;
        min-height: 4.8rem;
        padding: 0 1rem;
        margin-top: 1rem;
        border: solid 0.1rem ${grey};
        cursor: pointer;
        @media(${tabletMin}){
            &:hover {
                border: solid 0.1rem ${hover};
                background-color: ${hover};
                fill: ${yellow};
            }
        }
    `}
    ${props => props.choosen && css`
        border: solid 0.1rem ${green};
    `}
`;
const SearchButtonContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: 100%;
    min-height: 2.8rem;
    padding: 1rem 0;
    text-align: left;
    cursor: pointer;
`;
const SearchInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 1rem);
    min-height: 3.1rem;
    padding: 0 0.5rem;
    background-color: ${grey};
    border-radius: 0.3rem;
`;
const Input = styled.input`
    flex-grow: 1;
    width: 21.8rem;
    height: 3.1rem;
    padding: 0 1rem;
    border: 0;
    border-color: ${grey};
    background-color: ${grey};
    font-size: ${CSSCommon.fontSize.h4};
`;
const SVGSearch = styled.svg`
    width: 2rem;
    height: 2rem;
    padding-top: 0.5rem;
`;
const SVGHideSearch = styled(SVGClose)`
    align-self: center;
    transform: rotate(180deg);
`;
const SVGChooseTimeslot = styled.svg`
    width: 1.8rem;
    height: 1.8rem;
    fill: ${black};
    margin-right: 1rem;
    padding-bottom: 0.1rem;
    ${props => props.hidden && css`
        display: none;
    `}
`;
const SVGDisplaySearch = styled.div`
    width: 1.6rem;
    height: 0.9rem;
    margin-left: auto;
`;
const TextH4 = styled.h4`
    font-size: ${CSSCommon.fontSize.h4};
    color: ${green};
    ${props => !props.disabled && css`
        cursor: pointer;
    `}
`;
const TextH5 = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    color: ${black};
    padding-top: 0.5rem;
    ${props => !props.disabled && css`
        cursor: pointer;
    `}
`;
const TextBoldH5 = styled.button`
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
    font-weight: ${CSSCommon.fontWeight.medium};
    height: 1.7rem;
    cursor: pointer;
    @media(${tabletMin}){
        &:hover {
            text-decoration: underline;
        }
    }
    ${props => props.active && css`
        text-decoration: underline;
    `}
`;
const DropdownItemContainer = styled.div`
    overflow: auto;
    cursor: pointer;
`;
const DropdownTextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: default;
`;
const DropdownItem = styled.div`
`;
const H6 = styled(TextH6)`
    padding: 0.2rem 0 0 0;
`;
const MapButtonContainer = styled.button`
    display: flex;
    align-self: flex-start;
    height: 1.2rem;
    margin-top: 0.5rem;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    @media(${mobileMax}){
        display: none;
    }
    ${props => props.active && css`
        text-decoration: underline;
    `}
`;
const TextMapButton = styled.h5`
    font-size: ${CSSCommon.fontSize.h5};
    margin-left: 0.5rem;
    cursor: pointer;
`;
const SVGMapButton = styled.svg`
    height: 1.2rem;
    width: 1.2rem;
    fill: black;
`;
class SearchStationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top: '0',
            value: '',
            selectedIdx: -1,
            activeShop: '',
            visibleIndex: [],
            location: '',
            shopDistance: []
        };
    }
    chooseShop = idx => {
        const choosenShop = this.props.appState.displayedShops[idx];
        const payload = {
            choosenShop: choosenShop,
            get31Days: true
        };
        this.props.setAppState(
            {
                //Clears various values on shop change:
                activeStage: clearActiveStage2(this.props.appState.activeStage),
                choosenShop: choosenShop,
                choosenTimeslot: '',
                displayedMonth: DateTime.local(),
                isUnchangedRebooking: false,
                timeslots: [],
                timeslotTicker: 0,
                previousleyVisited: ''
            },
            {
                type: "getTimeslots",
                payload: payload
            });
        //To pervent jumping forward using browser buttons if stage has been cleared
        if(this.props.appState.activeStage.stage3 === false){
            window.history.pushState({page: 2}, 'page2', );
        }
        this.toggleDropdown();
        sendGTMEvent('stage-2', 'stationSelect', choosenShop.name)
    };
    toggleDropdown = () => {
        if(this.props.appState.renderTimeslotLoader){
            this.props.setAppState({renderLoader: true})
        } else {
            setTimeout(()=> {

                let stage2Container = document.getElementById('stage2-container');

                if(stage2Container) {
                    this.setState({
                        top: document.getElementById('stage2-container').getBoundingClientRect().top,
                        selectedIdx: -1,
                        activeShop: ''
                    })
                }
                }, 5);

            this.props.setAppState((prevState) => ({renderSearchDropdown: !prevState.renderSearchDropdown}));

            this.sortByProximity = false;
        }
        this.searchShop();
    };
    toggleMap = () => {
        this.props.setAppState({renderMap: true});
    };
    getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2-lat1); // deg2rad below
        const dLon = this.deg2rad(lon2-lon1);
        const a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = R * c; // Distance in km
        return d;
    };
    deg2rad = (deg) => {
        return deg * (Math.PI/180)
    };
    sortShopsByProximity = location => {
        const newShops = [];
        const shopDistance = [];
        let notAdded;
        if(location !== '') {
            this.props.appState.displayedShops.forEach((shop) => {
                notAdded = true;
                const distance = this.getDistanceFromLatLonInKm(shop.latitude, shop.longitude, location.latitude, location.longitude);
                if(newShops.length === 0) {
                    newShops.push(shop);
                    shopDistance.push(distance);
                } else {
                    newShops.forEach((newShop, idx) => {
                        if(notAdded){
                            //!shop.isArea is to exclude areas from distance view
                            if(!shop.isArea && distance < this.getDistanceFromLatLonInKm(newShop.latitude, newShop.longitude, location.latitude, location.longitude))
                            {
                                newShops.splice(idx, 0, shop);
                                shopDistance.splice(idx, 0, distance);
                                notAdded = false;
                            } else if (!shop.isArea && newShops.length === (idx + 1)) {
                                newShops.push(shop);
                                shopDistance.push(distance);
                                notAdded = false;
                            }
                        }
                    })
                }
            })
        }
        this.sortByProximity = true;
        this.setState({shopDistance: shopDistance});
        this.props.setAppState({displayedShops: newShops});
    };
    showShopsByProximity = () => {
        const geolocation = navigator.geolocation;
        if (!geolocation) {
            const alertMessage = JSON.parse(JSON.stringify(this.props.appState.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
            alertMessage.error = StringVariables.errorMessage.errorRetriving;
            renderAlert.error = true;
            this.props.setAppState(
                {
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                }
            );
        }

        const getPosition = new Promise((resolve, reject) => {
            geolocation.getCurrentPosition(resolve, reject);
        });

        getPosition.then((position) => {
            this.sortShopsByProximity(position.coords)
        })
        .catch((err) => {
            console.error(err.message);
            const alertMessage = JSON.parse(JSON.stringify(this.props.appState.alertMessage));
            const renderAlert = JSON.parse(JSON.stringify(this.props.appState.renderAlert));
            alertMessage.warning = StringVariables.warningMessages.allowPositioning;
            renderAlert.warning = true;
            this.props.setAppState(
                {
                    alertMessage: alertMessage,
                    renderAlert: renderAlert
                }
            )
        });
    };
    sortShops = shops => {
        const area = [];
        if(this.state.value.length > 0){
            shops.forEach((shop, idx)=>{
                if(shop.isArea){
                    area.push(...shops.splice(idx, 1));
                }
            });
        }
        shops.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
        });
        if(area.length > 0 && this.state.value.length > 0){shops.unshift(...area)}
        return shops
    };
    searchShop = e => {
        this.sortByProximity = false;
        const searchTerm = e ? e.target.value : '';
        if(this.props.appState.shops[0]){
                const shops = this.props.appState.shops.filter(shop =>
                shop.name.toLowerCase().startsWith(searchTerm.toLowerCase().trim())
                || (shop.addressLine1 ? shop.addressLine1.toLowerCase().startsWith(searchTerm.toLowerCase().trim()) : null)
                || shop.area.toLowerCase().startsWith(searchTerm.toLowerCase().trim())
                || (shop.shopAssociatedAreas ? shop.shopAssociatedAreas.find(associated => associated.name.toLowerCase().trim().startsWith(searchTerm.toLowerCase().trim())) : false)
                );
            this.props.setAppState({displayedShops: shops});
        }
        document.getElementById('dropdown-item-container').scrollTop = 0;
        this.setState({value: searchTerm});
    };
    formatText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    calculateNrOfVisibleItemsInDropdown = () => {
        const height = document.getElementById('dropdown-item-container').getBoundingClientRect().height;
        const closeToHeight = document.getElementById('station-0').getBoundingClientRect().height;
        const itemHeight = document.getElementById('station-1') ? document.getElementById('station-1').getBoundingClientRect().height : null;
        return Math.floor((height - closeToHeight) / itemHeight);
    };
    keyUpCursorCorrector = () => {
        document.getElementById(-1).setSelectionRange(this.state.value.length, this.state.value.length)
    };
    handleKeyPress = (event) => {
        let activeShop;
        let idx = this.state.selectedIdx;
        const nrOfRows = this.calculateNrOfVisibleItemsInDropdown();
        const searchboxLength = this.props.appState.displayedShops.length;
        let visibleIndex = this.state.visibleIndex;

        switch (event.key) {
            case 'ArrowUp':
                if (searchboxLength >= 0) {
                    if (idx < 1) {
                        idx = searchboxLength;
                        visibleIndex = [idx - nrOfRows + 1, idx + 1];
                        document.getElementById(`station-${searchboxLength}`).scrollIntoView();
                        document.documentElement.scrollTop = 0;
                    } else if (idx === visibleIndex[0] && idx !== 0) {
                        idx--;
                        visibleIndex = [idx, (idx + nrOfRows - 1)];
                        document.getElementById(`station-${idx}`).scrollIntoView();
                        document.documentElement.scrollTop = 0;
                    } else {
                        idx--;
                    }
                    activeShop = (idx !== -1) ? idx : null;
                    this.setState({
                        selectedIdx: idx,
                        visibleIndex: visibleIndex,
                        activeShop: activeShop
                    }, this.keyUpCursorCorrector);
                    event.preventDefault();
                }
                break;
            case 'ArrowDown':
                if (searchboxLength >= 0) {
                    if (idx === 0) {
                        idx++;
                        visibleIndex = [0, nrOfRows];
                        document.getElementById(`station-${idx}`).scrollIntoView(false);
                    } else if(idx === searchboxLength) {
                        idx = 0;
                        document.getElementById(`station-${idx}`).scrollIntoView(false);
                    } else if(idx === visibleIndex[1]) {
                        idx++;
                        visibleIndex = [(idx - nrOfRows + 1), idx];
                        document.getElementById(`station-${idx}`).scrollIntoView(false);
                    } else {
                        idx++;
                    }
                    activeShop = (idx !== -1) ? idx : null;
                    this.setState({
                        selectedIdx: idx,
                        visibleIndex: visibleIndex,
                        activeShop: activeShop
                    })
                }
                break;
            case 'ArrowRight':
                if(idx === 0){
                    this.setState({activeShop: 'station-map'})
                }
                break;
            case 'ArrowLeft':
                if(this.state.activeShop === 'station-map'){
                    this.setState({activeShop: 0})
                }
                break;
            case 'Enter':
                if (idx > 0
                    && this.props.appState.displayedShops[0].name
                    !== StringVariables.stage2.dropdownLoaderMessage)
                {
                    this.chooseShop(idx - 1);
                    this.setState({selectedIdx: -1})
                } else if(this.state.activeShop === 'station-map') {
                    this.toggleMap();
                } else if(idx === 0) {
                    this.showShopsByProximity();
                }
                break;
            case 'Escape':
                if(this.state.value === ''){
                    this.toggleDropdown();
                } else {
                    let e = {target: {value: ''}};
                    this.searchShop(e);
                }
                break;
            default:
                activeShop = (this.state.activeShop !== "") ? this.state.activeShop : this.state.searchbox;
                this.setState({
                    selectedIdx: -1,
                    searchbox: activeShop,
                    activeShop: ""
                })
        }
    };
    onMouseEnter = () => {
        this.setState({activeShop: -1, selectedIdx: -1})
    };
    stopPropagation = e => {
        e.stopPropagation();
    };
    viewportMobile = () => {
        return document.body.clientWidth < tabletMinInt;
    };
    isIE11 = () => {
        const ua = window.navigator.userAgent;
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) === 11;
        }
    };
    isOverflown = () => {
        if(this.isIE11()){
            const element = document.getElementById('search-station-container');
            const clientHeight = element.clientHeight;
            let height = 0;
            Array.from(element.children).forEach((elem, idx) => {
                height += this.scroll && idx === 1 ? elem.scrollHeight : elem.clientHeight;
            });
            this.scroll = height > clientHeight - 10;
        }
    };
    isIOS = () => {
        return (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
    };
    renderSearchStationComponent = () => {
        return(
            <SearchInputContainer>
                <SVGSearch>
                    {SVG.search}
                </SVGSearch>
                <Input id="-1"
                       autoFocus={!(this.isIOS() || this.viewportMobile())}
                       type="text"
                       onChange={this.searchShop}
                       value={this.state.value}
                       onKeyDown={this.handleKeyPress}/>
                <SVGHideSearch onClick={this.toggleDropdown}>
                    {SVG.dropdownArrow}
                </SVGHideSearch>
            </SearchInputContainer>
        )
    };
    renderSearchButtonContainer = () =>{
        const shop = this.props.appState.choosenShop;
        let textH4;
        let textH5;
        let textH6;
        if(!shop.isArea){
            textH4 = shop.id ? <TextH4>{this.formatText(shop.name)}</TextH4> : <TextH4>Välj besiktningsstation</TextH4>;
            textH5 = shop.id ? <TextH5>{this.formatText(shop.addressLine1)+', '+shop.postalCode+' '+this.formatText(shop.city)}</TextH5> : null;
            textH6 = shop.id ? <H6>Max fordonshöjd: {shop.maxHeight / 1000} m</H6> : null;
        }else if(shop.isArea){
            textH4 = <TextH4>{this.formatText(shop.name)}</TextH4>;
            textH5 = <TextH5>{this.formatText(shop.area)}</TextH5>;
        }

        return(
            <SearchButtonContainer onClick={this.toggleDropdown}>
                <SVGChooseTimeslot hidden={shop.id}>
                    {SVG.garage}
                </SVGChooseTimeslot>
                <DropdownItem>
                    {textH4}
                    {textH5}
                    {textH6}
                </DropdownItem>
                <SVGDisplaySearch>
                    {SVG.dropdownArrow}
                </SVGDisplaySearch>
            </SearchButtonContainer>
        )
    };
    renderDropdownItems = () => {
        let shops;
        shops = this.sortByProximity ? this.props.appState.displayedShops : this.sortShops(this.props.appState.displayedShops);
        return shops.map((shop, idx) => {
            const id = idx + 1;
            const choosen = shop === this.props.appState.choosenShop;
            const focused = this.state.activeShop === id;
            const distance = this.sortByProximity ? this.state.shopDistance[idx]  : null;
            return (
                <Station
                    id={id}
                    key={idx}
                    choosen={choosen}
                    focused={focused}
                    shop={shop}
                    distance={distance}
                    onClick={this.chooseShop.bind(this, idx)}
                />
            )
        })
    };
    renderDropdownTextContainer = () => {
        const proximityActive = this.state.activeShop === 0;
        const mapActive = this.state.activeShop === 'station-map';
        return (
            <DropdownTextContainer>
                <TextBoldH5
                    id='station-0'
                    active={proximityActive}
                    onClick={this.showShopsByProximity}
                >Sortera på avstånd</TextBoldH5>
                <MapButtonContainer
                    id='station-map'
                    active={mapActive}
                    onClick={this.toggleMap}
                >
                    <SVGMapButton>
                        {SVG.location}
                    </SVGMapButton>
                    <TextMapButton>Hitta på karta</TextMapButton>
                </MapButtonContainer>
            </DropdownTextContainer>
        )
    };
    componentDidUpdate(nextProps){
        //Adjusts size of dropdown when alert is added or removed
        if(this.props.appState.renderAlert !== nextProps.appState.renderAlert && nextProps.appState.renderAlert !== undefined){
            this.setState({top: document.getElementById('stage2-container').getBoundingClientRect().top})
        }
        //Needed for IE11
        this.isOverflown();

        if(this.props.appState.viewportMobile && !window.history.state.page.toString().includes('renderSearchDropdown')){
            window.history.pushState({page: 'renderSearchDropdown'}, 'renderSearchDropdown', );
        }

    }
    componentDidMount(){
        if(!this.props.appState.viewportMobile && !this.props.appState.choosenShop && !this.props.appState.renderSearchDropdown){
            this.toggleDropdown();
        }
    }
    componentWillUnmount(){
        this.props.setAppState((prevState) => ({renderSearchDropdown: false}));
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.appState.renderSearchDropdown !== nextProps.appState.renderSearchDropdown) {
            return true;
        }
        if (this.props.appState.displayedShops !== nextProps.appState.displayedShops) {
            return true;
        }
        if (this.props.appState.viewportHeightPx !== nextProps.appState.viewportHeightPx) {
            return true;
        }
        if (this.props.appState.choosenShop !== nextProps.appState.choosenShop) {
            return true;
        }
        if (this.props.appState.renderAlert !== nextProps.appState.renderAlert) {
            return true;
        }
        if (this.state.value !== nextState.value) {
            return true;
        }
        if (this.state.top !== nextState.top) {
            return true;
        }
        if (this.state.selectedIdx !== nextState.selectedIdx) {
            return true;
        }
        if (this.state.visibleIndex !== nextState.visibleIndex) {
            return true;
        }
        if (this.state.activeShop !== nextState.activeShop) {
            return true;
        }
        return false;
    };

    render() {
        const {appState} = this.props;
        const searchState = appState.renderSearchDropdown
            ? this.renderSearchStationComponent()
            : this.renderSearchButtonContainer();
        const dropdownItems = appState.renderSearchDropdown
            ? this.renderDropdownItems()
            : null;
        const dropdownTextContainer = appState.renderSearchDropdown
            ? this.renderDropdownTextContainer()
            : null;
        const headerHidden = appState.headerHeight ? 22 : 0;
        return(
            <ComponentContainer
                viewport={this.props.appState.viewportHeightPx}
                renderSearch={this.props.appState.renderSearchDropdown}
                onClick={this.toggleDropdown}
            >
                <SearchStationContainer
                    id="search-station-container"
                    viewport={this.props.appState.viewportHeightPx}
                    choosen={this.props.appState.choosenShop.id ? true : null}
                    top={this.state.top}  //props => props.top || '0'
                    headerHidden={headerHidden}
                    renderSearch={this.props.appState.renderSearchDropdown}
                    scroll={this.scroll}
                    onClick={this.stopPropagation}
                >
                    {searchState}
                    <DropdownItemContainer id="dropdown-item-container" onMouseEnter={this.onMouseEnter}>
                        {dropdownTextContainer}
                        {dropdownItems}
                    </DropdownItemContainer>
                </SearchStationContainer>
            </ComponentContainer>
        );
    }
}

export default SearchStationComponent;
