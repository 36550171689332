import React, { Component } from 'react';
import styled from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import {Button , SVGContainer, TextH4} from "../../variables/StyledComponents";

const green         = CSSCommon.color.green;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 3.8rem;
    padding: 1rem 0.9rem;
`;
const H4 = styled(TextH4)`
    color: ${green};
    margin-top: 2rem;
    margin-bottom: 5rem;
    align-self: center;
`;
const SVGCont = styled(SVGContainer)`
    width: 5.7rem;
    height: 4.8rem;
`;

class ContactsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booking: {}
        }
    }
    cancelBooking = () => {
        document.documentElement.scrollIntoView();
        this.props.setAppState({renderCancelBooking: false}, {type: "cancelBooking", payload: null});
    };
    handleKeyPress = e => {
        if (e.nativeEvent.key === 'Enter') {
            this.cancelBooking();
        } else if(e.nativeEvent.key === 'Escape'){
            this.props.setAppState({renderCancelBooking: false});
        }
    };
    render() {
        return (
            <ComponentContainer>
                <SVGCont>
                    {SVG.danger}
                </SVGCont>
                <H4>Vill du verkligen avboka din besiktningstid?</H4>
                <Button onlyNext onClick={this.cancelBooking} onKeyDown={this.handleKeyPress}>{this.props.string}</Button>
            </ComponentContainer>
        );
    }
}
export default ContactsComponent






























