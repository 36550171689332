import React, {Component} from 'react';
import {DateTime} from 'luxon';
import styled, {css} from 'styled-components';
//Styled components
import {stringBuilderDay, stringBuilderMonth} from '../../../resource-functions/HelperFunctions';
import {TextH4} from "../../../variables/StyledComponents";
import {SVG} from "../../../svg/SVG.js"
import {CSSCommon} from "../../../variables/CSSVariables";
import Loader from '../../../pictures/Dual_Ring_yellow_06_opacity.gif'

import Calendar from "./Calendar";
import TimeslotComponent from '../timeslot-components/TimeslotContainer';

const green         = CSSCommon.color.green;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.loader && css`
        opacity: .2;
    `}
`;
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;
    max-width: 35.8rem;
`;
const ChangeMonthContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 31.2rem;
    height: 3.1rem;
`;
const TextBold = styled(TextH4)`
    font-weight: ${CSSCommon.fontWeight.heavy};
`;
const SVGContainer = styled.button`
    width: 2.8rem;
    height: 1.6rem;
    padding-right: 1.9rem;
    cursor: pointer;
    z-index: 90;
    transition-property: height, fill;
    transition-duration: .5s;
    ${props => props.left && css`
        transform: rotate(180deg);
    `}
    ${props => props.inactive && css`
        height: 0;
        cursor: default;
    `}
`;
const SVGArrowDown = styled.svg`
    align-self: center;
    height: 0;
    fill: white;
    z-index: 100;
    transition-property: height, fill;
    transition-duration: .5s;
    ${props => props.toggle && css`
        @media(${mobileMax}){
            width: 6.7rem;
            height: 1.2rem;
            fill: ${green};
            margin-top: -0.6rem;
            padding-bottom: 0.8rem;
        }
    `}
`;
const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
`;
const LoaderGIF = styled.img`
    height: 6rem;
    width: 6rem;
`;

class CalendarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { toggle: false };
        this.previousleyVisited = '';
        this.changeDay = this.changeDay.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
    }
    toggleCalendar = () => {
        this.props.setAppState((prevState) => ({toggleCalendar: !prevState.toggleCalendar}));
    };
    getMoreTimeslots = newMonth => {
        const payload = {displayedMonth: newMonth};
        this.props.setAppState({}, {type: "getTimeslots", payload: payload});
    };
    getInitialTimeslots = (currentMonthHasNoTimeslots) => {
        let newMonth;
        if(this.props.appState.choosenTimeslot.interval){
            newMonth = DateTime.fromISO(this.props.appState.choosenTimeslot.interval.start).startOf('month')
        } else if(currentMonthHasNoTimeslots){
            newMonth = this.props.appState.displayedMonth;
        } else {
            newMonth = DateTime.local();
        }
        this.props.setAppState({timeslots:[]}, {type: "getTimeslots", payload: {displayedMonth: newMonth}});
    };
    changeMonth = forward => {
        let newMonth;
        const year = this.props.appState.displayedMonth.year;
        const month = this.props.appState.displayedMonth.month;

        if(forward){
            if(month === 12) {
                newMonth = DateTime.local(year + 1, 1);
            } else {
                newMonth = DateTime.local(year, month + 1);
            }
        } else {
            if(month === 1) {
                newMonth = DateTime.local(year - 1, 12);
            } else {
                newMonth = DateTime.local(year, month - 1);
            }
        }

        this.getMoreTimeslots(newMonth);
        this.props.setAppState({displayedMonth: newMonth});
    };
    changeDay = forward => {
        let newDay;
        const year = this.props.appState.displayedMonth.year;
        const month = this.props.appState.displayedMonth.month;
        const day = this.props.appState.displayedMonth.day;
        if(forward){
            if(month === 12 && day === 31) {
                newDay = DateTime.local(year + 1, 1, 1);
                this.changeMonth(forward);
            } else if(day === this.props.appState.displayedMonth.endOf('month').day){
                newDay = DateTime.local(year, month + 1, 1);
                this.changeMonth(forward);
            } else {
                newDay = DateTime.local(year, month, day +1)
            }
        } else {
            if(month === 1 && day === 1) {
                newDay = DateTime.local(year - 1, 12, 31);
                this.changeMonth(forward);
            } else if(day === 1){
                newDay = DateTime.local(year, month - 1, DateTime.local(year, month - 1).endOf('month').day);
                this.changeMonth(forward);
            } else {
                newDay = DateTime.local(year, month, day - 1);
            }
        }
        this.props.setAppState({displayedMonth: newDay});
    };
    stringBuilder = type => {
        //Type = true for "month year", = false for "day month", = null for day month year
        const month = this.props.appState.displayedMonth;
        const monthStr = stringBuilderMonth(month);
        const dayStr = stringBuilderDay(month);
        if(type) {
            return monthStr + ' ' + month.year;
        } else if(type === null) {
            return dayStr + ' ' + month.day + ' ' + monthStr + ' ' + month.year;
        } else {
            return dayStr + ' ' + month.day + ' ' + monthStr;
        }
    };
    swipeStart = (e) => {
        this.swipeX = e.changedTouches[0].clientX;
        this.swipeY = e.changedTouches[0].clientY;
    };
    swipeEnd = (e) => {
        if(!this.props.appState.renderTimeslotLoader){
            const displayedMonth = this.props.appState.displayedMonth;
            const toggleCalendar = this.props.appState.toggleCalendar;
            //If diagonal swipe the longest swipe is primary:
            if(Math.abs(this.swipeX - e.changedTouches[0].clientX)
                > Math.abs(this.swipeY - e.changedTouches[0].clientY))
            {
                this.swipeY = false;
            }
            //Swipe down:
            if(this.swipeY
                && (document.getElementById('50').scrollTop === 0)
                && (this.swipeY + 15) < e.changedTouches[0].clientY
                && !this.scroll)
            {
                if(toggleCalendar){
                    this.toggleCalendar();
                }
            }
            //Swipe right:
            else if(!this.swipeY && (this.swipeX + 15) < e.changedTouches[0].clientX){
                if(toggleCalendar) {
                    if(displayedMonth > DateTime.local()) {
                        this.changeDay(false);
                    }
                } else {
                    if(!((displayedMonth.year === DateTime.local().year)
                            && (displayedMonth.month === DateTime.local().month))) {
                        this.changeMonth(false);
                    }
                }
            }
            //Swipe left:
            else if(!this.swipeY && (this.swipeX - 15) > e.changedTouches[0].clientX){
                if(toggleCalendar) {
                    this.changeDay(true);
                } else {
                    this.changeMonth(true);
                }
            }
            //Ensures that swipe down action only happens when no scroll has happend in sam swipe.
            this.scroll = document.getElementById('50').scrollTop !== 0;
        }
    };
    renderArrowLeft = () => {
        const displayedMonth = this.props.appState.displayedMonth;
        if(this.props.appState.toggleCalendar) {
            if(displayedMonth > DateTime.local()) {
                return (
                    <SVGContainer left onClick={this.changeDay.bind(this, false)}>
                        {SVG.arrowRight}
                    </SVGContainer>
                )
            } else {
                return (
                    <SVGContainer left inactive tabIndex='-1'/>
                )
            }
        } else {
            if((displayedMonth.year === DateTime.local().year)
                && (displayedMonth.month === DateTime.local().month)) {
                return (
                    <SVGContainer left inactive tabIndex='-1'/>
                )
            } else {
                return (
                    <SVGContainer left onClick={this.changeMonth.bind(this, false)}>
                        {SVG.arrowRight}
                    </SVGContainer>
                )
            }
        }
    };
    renderArrowRight = () => {
        if(this.props.appState.toggleCalendar) {
            return(
                <SVGContainer onClick={this.changeDay.bind(this,true)}>
                    {SVG.arrowRight}
                </SVGContainer>
            )
        } else {
            return(
                <SVGContainer onClick={this.changeMonth.bind(this,true)}>
                    {SVG.arrowRight}
                </SVGContainer>
            )
        }
    };
    renderLoader = () => {
        return(
            <LoaderContainer
                top={this.state.top}
                width={this.state.width}
                height={this.state.height}
            >
                <LoaderGIF src={Loader} alt="loader"/>
            </LoaderContainer>
        )
    };

    componentDidMount(){
        const currentMonthHasNoTimeslots = this.props.appState.displayedMonth.month !== DateTime.local().month;
        if(this.props.appState.timeslotTicker > 1 || this.props.appState.vehicleMain.preliminary || currentMonthHasNoTimeslots){
            this.getInitialTimeslots(currentMonthHasNoTimeslots);
            this.setState({toggle: true})
        }
        this.setState({displayedMonth: DateTime.local()});
        this.props.setAppState({toggleCalendar: false});

        document.getElementById('cal-time-cont').addEventListener('touchstart', this.swipeStart, false);
        document.getElementById('cal-time-cont').addEventListener('touchend', this.swipeEnd, false);
    }
    componentDidUpdate(){
        //Requests new timeslots if current month contains no timeslots
        const activeMonth = this.props.appState.displayedMonth.month !== DateTime.local().month;
        if(!this.state.toggle && activeMonth){
            this.getInitialTimeslots(activeMonth);
            this.setState({toggle: true})
        }
    }
    componentWillUnmount(){
        this.props.setAppState({timeslots:[], timeslotTicker: 2});
        this.setState({toggle: false})
    }
    render() {
        const {appState, setAppState} = this.props;
        const headerText = !appState.toggleCalendar ? this.stringBuilder(true) : this.stringBuilder(null);
        const arrowLeft = this.renderArrowLeft();
        const arrowRight = this.renderArrowRight();
        const loader = appState.renderTimeslotLoader ? this.renderLoader() : null;

        return (
            <ComponentContainer id="cal-time-cont">
                <SubContainer id="cal-cont" loader={loader}>
                    <HeaderContainer>
                        <ChangeMonthContainer>
                            {arrowLeft}
                            <TextBold>{headerText}</TextBold>
                            {arrowRight}
                        </ChangeMonthContainer>
                    </HeaderContainer>
                    <SVGArrowDown toggle={appState.toggleCalendar} onClick={this.toggleCalendar}>
                        {SVG.arrowDown}
                    </SVGArrowDown>
                    <Calendar
                        displayedMonth={appState.displayedMonth}
                        height={this.height}
                        {...{appState, setAppState}}
                    />
                </SubContainer>
                <TimeslotComponent loader={loader}
                                   calendar={true}
                                   cheapest={true}
                                   firstFree={false}
                                   {...{appState, setAppState}}
                />
                {loader}
            </ComponentContainer>
        );
    }
}
export default CalendarContainer;
