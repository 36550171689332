import React, { Component } from 'react';
import { DateTime } from 'luxon';
import styled, {css} from 'styled-components';
//Styled components
import { stringBuilderDay, stringBuilderMonth } from '../../../resource-functions/HelperFunctions';
import {TextH4} from "../../../variables/StyledComponents";
import {CSSCommon} from "../../../variables/CSSVariables";

import Timeslot from "./Timeslot";

const green         = CSSCommon.color.green;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div``;
const SubContainer = styled.div`
    max-height: calc(${props => props.viewport}px - 10.9rem);
    height: ${props => props.height}px;
    overflow: auto;
    transition-property: height;
    transition-duration: .5s;
    @media(${tabletMin}){
        max-height: calc(${props => props.viewport}px - ${props => props.offset}px - 9.8rem);
    }
    ${props => !props.toggle && css`
        @media(${mobileMax}){
            overflow: hidden;
            height: 0;
        }
    `}
    ${props => props.loader && css`
        opacity: .2;
    `}
`;
const Header = styled(TextH4)`
    font-weight: ${CSSCommon.fontWeight.heavy};
    padding: 0 1rem 0.5rem 1rem;
    border-bottom: solid 0.1rem ${green};
    ${props => props.calendar && css`
        padding-top: 1.5rem;
        @media(${mobileMax}){
            display: none;
        }
    `}
    ${props => props.loader && css`

        opacity: .2;
    `}
`;
const TimeslotsContainer = styled.div`
    height: ${props => props.height};
    overflow-x: hidden;
    overflow-y: auto;
`;
const NoTimeslotMessage = styled(TextH4)`
    color: ${green};
    padding: 1rem;
`;

class TimeslotContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0
        };
    };

    getChoosenDateTimeslots = () => {
        let choosenDateTimeslots = false;
        const displayedMonth = this.props.appState.displayedMonth;
        let choosenDate = DateTime.local(displayedMonth.year, displayedMonth.month, displayedMonth.day);
        this.props.appState.timeslots.forEach((timeslots)=> {
            const timeslotDate = DateTime.fromISO(timeslots.date);
            if (timeslotDate.day === choosenDate.day
                && timeslotDate.month === choosenDate.month
                && timeslotDate.year === choosenDate.year
                && timeslots.lowestPrice)
            {
                choosenDateTimeslots = timeslots.timeSlots;
            }
        });
        return choosenDateTimeslots;
    };

    getLowestPrice = (choosenDateTimeslots) => {
        let lowestPrice;
        choosenDateTimeslots.forEach((timeslot)=> {
            if(timeslot.price < lowestPrice || lowestPrice === undefined){
                lowestPrice = timeslot.price;
            }
        });
        return lowestPrice;
    };

    stringBuilder = () => {
        const month = !this.activeDate ? this.props.appState.displayedMonth : this.activeDate;
        const monthStr = stringBuilderMonth(month);
        const dayStr = stringBuilderDay(month);

        // console.log('stringBuilder');
        // if(document.getElementById(50)){document.getElementById(50).scrollTop = 0};
        return dayStr + ' ' + month.day + ' ' + monthStr;
    };

    renderTimeslots = (appState, setAppState) => {
        let choosenDateTimeslots = this.getChoosenDateTimeslots();
        let lowestPrice = choosenDateTimeslots ? this.getLowestPrice(choosenDateTimeslots) : false;

        //To render loader message:
        if(this.props.appState.toggleCalendar && !choosenDateTimeslots) {
            return <NoTimeslotMessage>Inga lediga tider</NoTimeslotMessage>;
        } else if(!this.props.appState.toggleCalendar && !choosenDateTimeslots){
            return null;
        }

        return(
            choosenDateTimeslots.map((timeslot, idx)=> {
                const firstFree = idx === 0 && this.props.firstFree;
                const cheapest = timeslot.price === lowestPrice && this.props.cheapest;
                return <Timeslot id={idx}
                                 key={idx}
                                 cheapest={cheapest}
                                 firstFree={firstFree}
                                 timeslot={timeslot}
                                 {...{appState, setAppState}}/>
            })
        )
    };

    componentDidUpdate(nextProps, prevState){
        const height = document.getElementById(1).getBoundingClientRect().height;
        if(height !== this.state.height) {
            this.setState({height: height});
        }
        
        const offset = Math.floor(document.getElementById('cal-cont').getBoundingClientRect().height);

        if(prevState.offset !== offset) {
            this.setState({offset: offset});
        }

        //Resets scroll position to 0 when switching day:
        if (this.props.appState.displayedMonth !== nextProps.appState.displayedMonth) {
            document.getElementById(50).scrollTop = 0
        }
    }
    componentDidMount() {
        this.setState({height: document.getElementById(1).getBoundingClientRect().height});
        this.setState({offset:  Math.floor(document.getElementById('cal-cont').getBoundingClientRect().height)});
    }
    render() {
        const {appState, setAppState} = this.props;
        const dayAndMonth = this.stringBuilder(false);
        return (

            <ComponentContainer>
                <Header id={3} calendar={this.props.calendar}
                        loader={this.props.loader}>{dayAndMonth}</Header>
                <SubContainer
                    id={50} offset={this.state.offset}
                    viewport={this.props.appState.viewportHeightPx}
                    height={this.state.height}
                    toggle={appState.toggleCalendar}
                    loader={this.props.loader}
                >
                    <TimeslotsContainer id={1}>
                        {this.renderTimeslots(appState, setAppState)}
                    </TimeslotsContainer>
                </SubContainer>
            </ComponentContainer>
        );
    }
}
export default TimeslotContainer;
