import React from 'react';
import styled, {css} from 'styled-components';
import {CSSCommon} from "../../variables/CSSVariables";
import {TextH4, TextH5} from "../../variables/StyledComponents";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 27.8rem;
    max-width: 30.2rem;
    min-height: 3.8rem;
    border: solid 0.1rem ${green};
    padding: 1rem;
    margin-bottom: 1rem;
    @media(${tabletMin}){
        min-height: 5.2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
    }
`;
const SubsectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TextContainer = styled.div`
`;
const H4 = styled(TextH4)`
    color: ${black};
    margin-top: 1rem;
    cursor: text;
    user-select: text;
    ${props => props.green && css`
        color: ${green};
        margin-bottom: 0.5rem;
    `}
    ${props => props.marginTop && css`
        margin-top: 0;
    `}
`;
const H5 = styled(TextH5)`
    margin-top: 0.2rem;
    cursor: text;
    user-select: text;
    white-space: pre-wrap;
    ${props => props.bold && css`
        font-weight: ${CSSCommon.fontWeight.heavy};
        margin-top: 1rem;
    `}
    ${props => props.paddingLeft && css`
        padding-left: 1.5rem;
    `}
`;
const H5Link = styled.a`
    position: relative;
    top: 0.2rem;
	height: ${CSSCommon.fontSize.h5};
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.normal};
    color:  ${green};
    cursor: pointer;
    text-decoration: none;
    white-space: pre-wrap;
    &:hover{
        text-decoration: underline;
    }
`;
const TextContainerLink = styled.div`
    display: flex; 
    flex-wrap: wrap;
`;
const PhoneNrWrapper = styled.a`
    cursor: default;
    text-decoration: none;
`;
const Img = styled.img`
    width: 100%;
    margin-top: 1rem;
`;

const SummeryComponent = props => {
    const {appState} = props;
    const extractShop = () => {
        return(
            appState.shops.filter((shop) => shop.id === appState.booking.shopId)[0]
        )
    };
    const formatText = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    const choosenShop = extractShop();
    const imageURL = `${window.location.origin}/bb-web/api/v2/shops/${choosenShop.id}/image`;
    return (
        <ComponentContainer>
            <H4 green marginTop>Välkommen till DEKRA {formatText(choosenShop.name)}!</H4>
            <H5>Då du anländer till butiken, parkera utanför och gå in och anmäl din ankomst hos våra
                besiktningstekniker som hjälper dig. Välkommen!
            </H5>
            <H4 green>Hitta hit!</H4>
            <SubsectionContainer>
                <TextContainer>
                    <H5 bold>Adress</H5>
                    <H5>{formatText(choosenShop.addressLine1)},</H5>
                    <H5>{choosenShop.postalCode} {formatText(choosenShop.city)}</H5>
                </TextContainer>
            </SubsectionContainer>
            <H5 bold>Telefon</H5>
            <PhoneNrWrapper href='tel:0771-441 441' tabIndex='-1'>
                <H5 pointer>Kundtjänst: 0771-441 441 (vardagar 07:00 - 17:00)</H5>
            </PhoneNrWrapper>
            <PhoneNrWrapper href={'tel:' + choosenShop.phoneNumber} tabIndex='-1'>
                <H5 pointer>Kontakta stationen direkt på: {choosenShop.phoneNumber}</H5>
            </PhoneNrWrapper>
            <H5 bold>Öppettider</H5>
            <H5>{choosenShop.openingHours}</H5>
            <Img src={imageURL}/>
            <H4 green>Övrig viktig information</H4>
            <H5>- Parkeringsplatser (tänk på)</H5>
            <H5 paddingLeft>- P-skiva</H5>
            <H5 paddingLeft>- Drop-in i mån av tid</H5>
            <H4 green>Återbetalning av bokning</H4>
            <TextContainerLink>
                <H5 paddingRight>Har du valt </H5>
                <H5Link href='https://www.klarna.com/se/kundservice/' target="_blank">Klarna </H5Link>
                <H5 paddingRight>som betalsätt men avbokat </H5>
                <H5 paddingRight>din </H5>
                <H5 paddingRight>tid, </H5>
                <H5 paddingRight>hanterar </H5>
                <H5Link href='https://www.klarna.com/se/kundservice/' target="_blank">Klarna </H5Link>
                <H5 paddingRight>din återbetalning.</H5>
            </TextContainerLink>
        </ComponentContainer>
    );
};
export default SummeryComponent






























