import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { clearActiveStage2 } from '../../resource-functions/HelperFunctions'

const Map = ((props) => {

    const mapStyles = {
        height: "100vh",
        width: "100%"};

    const onClick = (shop) => {
        const payload = {choosenShop: shop, get31Days: true};
        props.setAppState({
            activeStage: clearActiveStage2(props.appState.activeStage),
            choosenShop: shop,
            choosenTimeslot: '',
            isUnchangedRebooking: false,
            timeslots: [],
            renderMap: false,
            renderSearchDropdown: false
        },
        {
            type: "getTimeslots",
            payload: payload
        });
    };

    const markers = () => {
        return(
            props.markerElements.map((shop, idx) => {
                if(!shop.isArea){
                    return(
                        <Marker
                            key={idx}
                            position={{lat: +shop.latitude, lng: +shop.longitude}}
                            onClick={onClick.bind(this, shop)}
                        />
                    )
                }
            })
        )
    };

    return (
        <LoadScript
            googleMapsApiKey={props.googleMapsApiKey}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={props.zoom}
                center={{lat: props.center.coords.latitude, lng: props.center.coords.longitude}}>
                {markers()}
            </GoogleMap>
        </LoadScript>
    );

});

export default Map