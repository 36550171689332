import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const white         = CSSCommon.color.white;

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 6rem;
    z-index: 20;
    ${props => props.scrollTop && css `
        position: fixed;
        top: 0;
        left: 0;
    `}
`;
const ProgressBarDivider = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${white};
    box-shadow: 0 1rem 0.5rem ${white};
    z-index: 20;
`;
const ProgressBar = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    height: 3.6rem;
    max-width: 39.1rem;
    padding: 1.3rem 1rem 0.1rem 1rem;
    z-index: 20;
    will-change: transform;
    background: ${white};
`;
const Gradient = styled.div`
    width: 100%;
    height: 1.3rem;
`;
const GraphicCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 100%;
    background-color: ${grey};
    fill: ${black};
    ${props => props.isActive === false && css `
        background-color: ${hover};
        cursor: pointer;
    `}
    ${props => props.isActive && css `
        background-color: ${green};
        fill: ${white};
        cursor: pointer;
    `}
    ${props => props.last && css `
        cursor: default;
    `}
`;
const GraphicLine = styled.div`
    flex-grow: 1;
    height: 0.7rem;
    margin: -0.1rem;
    z-index: -1;
    background-color: ${grey};
    ${props => props.isActive === false && css`
        background-color: ${hover};
    `}
    ${props => props.isActive && css`
        background-color: ${green};
    `}
`;
const SVGContainer = styled.svg`
    width: 2rem;
    height: 2rem;
`;

class ProgressBarComponent extends Component {
    setActiveStage = (stageNr) => {
        const activeStage = JSON.parse(JSON.stringify(this.props.appState.activeStage));
        if(!this.isActive(4)){
            switch(stageNr) {
                case 1:
                    if(this.isActive(1) !== undefined){
                        activeStage.stage1 = true;
                        activeStage.stage2 = activeStage.stage2  !== null ? false : null;
                        activeStage.stage3 = activeStage.stage3  !== null ? false : null;
                    }
                    break;
                case 2:
                    if(this.isActive(2) !== undefined) {
                        activeStage.stage1 = false;
                        activeStage.stage2 = true;
                        activeStage.stage3 = activeStage.stage3  !== null ? false : null;
                    }
                    break;
                case 3:
                    if(this.isActive(3) !== undefined) {
                        activeStage.stage1 = false;
                        activeStage.stage2 = false;
                        activeStage.stage3 = true;
                    }
                    break;
                default:
                    break;
            }
            this.props.setAppState({activeStage: activeStage});
        }
    };

    isActive = (stageNr) => {
        if(this.props.appState.activeStage['stage' + stageNr]){
            return true
        } else if(this.props.appState.activeStage['stage' + stageNr] === false){
            if(this.props.appState.activeStage['stage' + (stageNr + 1)]){
                return true;
            } else if(this.props.appState.activeStage['stage' + (stageNr + 2)]){
                return true;
            } else if(this.props.appState.activeStage['stage' + (stageNr + 3)]){
                return true;
            }
            return false;
        }
    };

    render() {
        const scrollTop = this.props.appState.headerHeight;

        return (
            <ProgressBarContainer id="progress-bar-container"
                                  scrollTop={scrollTop}
            >
                <ProgressBarDivider>
                    <ProgressBar>
                        <GraphicCircle
                            last={this.isActive(4) || (this.isActive(1) && !this.isActive(2))}
                            isActive={this.isActive(1)}
                            onClick={this.setActiveStage.bind(this, 1)}
                        >
                            <SVGContainer>
                                {SVG.wheel}
                            </SVGContainer>
                        </GraphicCircle>
                        <GraphicLine isActive={this.isActive(2)}/>
                        <GraphicCircle
                            last={this.isActive(4) || (this.isActive(2) && !this.isActive(3))}
                            isActive={this.isActive(2)}
                            onClick={this.setActiveStage.bind(this, 2)}
                        >
                            <SVGContainer>
                                {SVG.locationTime}
                            </SVGContainer>
                        </GraphicCircle>
                        <GraphicLine isActive={this.isActive(3)}/>
                        <GraphicCircle
                            last={this.isActive(4) || (this.isActive(3))}
                            isActive={this.isActive(3)}
                            onClick={this.setActiveStage.bind(this, 3)}
                        >
                            <SVGContainer>
                                {SVG.shoppingBag}
                            </SVGContainer>
                        </GraphicCircle>
                        <GraphicLine isActive={this.isActive(4)}/>
                        <GraphicCircle last isActive={this.isActive(4)}>
                            <SVGContainer>
                                {SVG.checkmark}
                            </SVGContainer>
                        </GraphicCircle>
                    </ProgressBar>
                </ProgressBarDivider>
                <Gradient/>
            </ProgressBarContainer>
        );
    }
}

export default ProgressBarComponent;
