import React, { Component } from 'react';
import styled, { css } from 'styled-components';
//Styled components
import { SVG } from "../../svg/SVG.js"
import { CSSCommon } from "../../variables/CSSVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const hover         = CSSCommon.color.hover;
const yellow        = CSSCommon.color.yellow;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    height: 100%;
`;
const TermsContainer = styled.div`
    height: calc(${props => props.viewport}px - 8.8rem - 1px);
    margin-bottom: -1px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    ${props => props.isIOS && css`
        overflow: auto;
    `}
`;
const TermsFrame = styled.iframe`
    height: calc(100% - 1px);
    width: 100%;
    margin-bottom: -1px;
    border: none;
    ${props => props.klarna && css`
        @media(${mobileMax}){
            margin: 0 1rem;
            width: calc(100% - 2rem);
            overflow: hidden;
        }
    `}
  
`;
const TabContainer = styled.div`
    display: flex;
    height: 2.6rem;
    padding-left: 0.9rem;
    border-bottom: solid ${green} 0.1rem;
`;
const Tab = styled.button`
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
    position: relative;
    padding: 0.5rem 0.5rem;
    border: solid ${green} 0.1rem;
    border-bottom: none;
    margin-left: 0.5rem;
    border-left: solid ${green} 0.1rem;
    cursor: pointer;
    ${props => props.choosen && css`
        background-color: ${green};
        color: ${yellow};
        cursor: default ;
    `}
    ${props => !props.choosen && css`
        &:hover {
            background: ${hover};
            border-color: ${hover};
        }
    `}
    ${props => props.left && css`
        margin-left: 0;
    `}
`;
const OpenExternally = styled.div`
    align-self: flex-end;
    position: relative;
    top: 12px;
    right: 12px;
    height: 24px;
    width: 24px;
    min-width: 0;
    margin: 0;
    margin-left: auto;
    margin-bottom: -40px;
    padding: 8px;
    background-color: ${green};
    fill: ${yellow};
    cursor: pointer;
    z-index: 100;
    &:hover {
        background-color: ${yellow};
        fill: ${green};
    }
    @media(${tabletMin}){
        top: 60px;
        right: 30px;
    }
`;
const SVGOpenExternally = styled.button`
    width: 24px;
    height: 24px;
    padding: 3px;
`;

class PopupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: 1
        };
        this.GDPRUrl = 'https://dekra-bilbesiktning.se/om-oss/anvandarvillkor/';
        this.dekraUrl = 'https://dekra-bilbesiktning.se/om-oss/allmanna-villkor/';
        this.klarnaUrl = 'https://cdn.klarna.com/1.0/shared/content/legal/terms/5450/sv_se/checkout';
    }
    closePopup = () => {
        this.props.setAppState({renderTerms: false});
    };
    toggle = tabNr => {
        this.setState({toggle: tabNr});
    };
    openLink = () => {
        let url;
        if(this.state.toggle === 1){
            url = this.GDPRUrl;
        } else if(this.state.toggle === 2){
            url = this.dekraUrl;
        } else if(this.state.toggle === 3){
            url = this.klarnaUrl;
        }
        const win = window.open(url, '_blank');
        win.focus();
    };
    dekraIframe = () => {
        return <TermsFrame src={this.dekraUrl}/>
    };
    GDPRIframe = () => {
        return <TermsFrame src={this.GDPRUrl}/>
    };
    klarnaIframe = () => {
        return <TermsFrame klarna src={this.klarnaUrl}/>
    };
    componentDidMount(){
        if(this.props.appState.renderTerms !== (true || this.state.toggle)) {
            this.setState({toggle: this.props.appState.renderTerms});
        }
    }
    render() {
        const iframeGDPR    = this.state.toggle === 1 ? this.GDPRIframe() : null;
        const iframeDekra   = this.state.toggle === 2 ? this.dekraIframe() : null;
        const iframeKlarna  = this.state.toggle === 3 ? this.klarnaIframe() : null;
        return (
            <ComponentContainer>
                <TabContainer>
                    <Tab left choosen={this.state.toggle === 1} onClick={this.toggle.bind(this, 1)}>Användarvillkor</Tab>
                    <Tab choosen={this.state.toggle === 2} onClick={this.toggle.bind(this, 2)}>Allmänna villkor</Tab>
                    <Tab choosen={this.state.toggle === 3} onClick={this.toggle.bind(this, 3)}>Klarna</Tab>
                </TabContainer>
                <OpenExternally onClick={this.openLink}>
                    <SVGOpenExternally>
                        {SVG.externalLink}
                    </SVGOpenExternally>
                </OpenExternally>
                <TermsContainer viewport={this.props.appState.viewportHeightPx} isIOS={this.props.appState.isIOS}>
                    {iframeGDPR}
                    {iframeDekra}
                    {iframeKlarna}
                </TermsContainer>
            </ComponentContainer>
        );
    }
}
export default PopupComponent;
