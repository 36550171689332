import React from 'react';
import styled, { css } from 'styled-components';
import { formatText, stringBuilderDate } from "../../resource-functions/HelperFunctions";
import { CSSCommon } from "../../variables/CSSVariables";
import { TextH3, TextH4, TextH5 } from "../../variables/StyledComponents";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 27.8rem;
    max-width: 30.2rem;
    min-height: 3.8rem;
    border: solid 0.1rem ${green};
    padding: 1rem;
    margin-bottom: 1rem;
    @media(${tabletMin}){
        min-height: 5.2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
    }
    ${props => props.preliminary && css`
        width: calc(100% - 2.2rem);
        min-width: 25.6rem;
        margin-bottom: 1.5rem;
    `}
`;
const SubsectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
`;
const TextContainer = styled.div`
    display: flex;
    ${props => props.preliminary && css`
        display: none;
    `}
    ${props => props.minWidth && css`
        min-width: 15rem;
    `}
    ${props => !props.marginBottom && css`
        flex-direction: column;
    `}
    ${props => props.row && css`
        flex-direction: row;
    `}
    ${props => props.marginTop && css`
        margin-top: 0.5rem;
    `}
    ${props => props.marginBottom && css`
        padding-bottom: 0.5rem;
    `}
    ${props => props.fullWidth && css`
        width: 100%;
    `}
`;
const H3 = styled(TextH3)`
    color: ${black};
    margin-bottom: 1rem;
    cursor: text;
    user-select: text;
    ${props => props.marginBottom && css`
        margin-bottom: 0.5rem;
    `}
`;
const H4 = styled(TextH4)`
    color: ${black};
    margin-right: 0.5rem;
    margin-top: 1rem;
    cursor: text;
    user-select: text;
    ${props => props.marginTop && css`
        margin-top: 0.5rem;
    `}
    ${props => props.green && css`
        color: ${green};
    `}
`;
const H4Heavy = styled(TextH4)`
    font-weight: ${CSSCommon.fontWeight.heavy};
    margin-top: 0.5rem;
    cursor: text;
    user-select: text;
    ${props => props.marginTop && css`
        margin-top: 1rem;
    `}
`;
const H5 = styled(TextH5)`
    margin-top: 1.2rem;
    cursor: text;
    user-select: text;
`;

const SummeryComponent = props => {
    const { appState } = props;
    const sortBookingItems = () => {
        const bookingItems = appState.booking.items;
        if(bookingItems[1] && bookingItems[0].vehicleKind === 'SLÄP'){
            return bookingItems.reverse()
        } else {
            return bookingItems
        }
    };
    const bookingItems = sortBookingItems();
    const renderProductsMain = () => {
        return(
            bookingItems[0].products.map((product, idx)=> {
                return (
                    <H4 key={idx} green>{product.shortDescription}</H4>
                )
            })
        )
    };
    const renderProductsSecondary = () => {
        return(
            bookingItems[1].products.map((product, idx)=> {
                return (
                    <H4 key={idx} green>{product.shortDescription}</H4>
                )
            })
        )
    };
    const extractShop = () => {
        return(
            appState.shops.filter((shop) => shop.id === appState.booking.shopId)[0]
        )
    };
    const extractPrice = () => {
        let totalPrice = 0;
        bookingItems.forEach((item) => {
            totalPrice -= item.products[0].paymentTransactions[0]
            && (item.products[0].paymentTransactions[0].status === 'PAID' || item.products[0].paymentTransactions[0].status === 'PAID_AND_ACTIVATED')
                ? item.discount
                : item.bookingDiscount;
            totalPrice += item.price;
        });
        return totalPrice
    };

    const extractPreliminaryPrice = () => {
        let totalPrice = 0;
        bookingItems.forEach((item) => {
            totalPrice -= item.discount
            totalPrice += item.price;
        });
        return totalPrice
    };

    const getPaymentStatus = () => {
        if (appState.booking.items[0].paymentStatus.includes('REFUNDED')) {
            return 'Återbetald'
        } else if(appState.booking.items[0].status.includes('CANCELED') || (appState.booking.items[0].paymentStatus.includes('NOT_PAID') && !appState.booking.payable)){
            return ''
        } else if(appState.booking.items[0].paymentStatus.includes('NOT_PAID') && appState.booking.payable){
            return 'Betala med kort på station'
        } else if(appState.booking.items[0].paymentStatus.includes('PAID')){
            return 'Betalsätt Klarna'
        }
    };

    const getFirstTimeslotStartTime = () => {
        let startTime = bookingItems[0].startTime;

        bookingItems.forEach((item) => {
            if(new Date(startTime) > new Date(item.startTime)) {
                startTime = item.startTime;
            }
        });

        return startTime;
    }

    const choosenShop = extractShop();
    const choosenTimeslot = getFirstTimeslotStartTime();//bookingItems[0].startTime;
    const totalPrice =  !props.preliminary ? extractPrice() : extractPreliminaryPrice() ;
    if(!props.preliminary){
        props.totalPrice(totalPrice)
    }
    const paymentStatus = getPaymentStatus();
    const renderRegNrMain = <H4 green>{bookingItems[0].registrationNumber}</H4>;
    const renderRegNrSecondary = bookingItems[1] ? <H4 green>{bookingItems[1].registrationNumber}</H4> : null;
    return (
        <ComponentContainer preliminary={props.preliminary}>
            <H3>{formatText(choosenShop.name)}, {choosenShop.area !== undefined ? formatText(choosenShop.area) : null}</H3>
            <H3 marginBottom>{stringBuilderDate(choosenTimeslot)}</H3>
            <TextContainer preliminary={props.preliminary} row marginBottom>
                <H4 marginTop>Bokningsnummer: </H4>
                <H4Heavy>{appState.booking.orderReference}</H4Heavy>
            </TextContainer>
            <SubsectionContainer>
                <TextContainer row>
                    <TextContainer minWidth>
                        <H4Heavy>Fordon</H4Heavy>
                        {renderRegNrMain}
                    </TextContainer>
                    <TextContainer fullWidth>
                        <H4Heavy>Produkt</H4Heavy>
                        {renderProductsMain()}
                    </TextContainer>
                </TextContainer>
                <TextContainer row>
                    <TextContainer minWidth>
                        {renderRegNrSecondary}
                    </TextContainer>
                    <TextContainer fullWidth>
                        {bookingItems[1] && renderProductsSecondary()}
                    </TextContainer>
                </TextContainer>
                <TextContainer row>
                    <TextContainer row minWidth>
                        <H4>Totalpris: </H4>
                        <H4Heavy marginTop>{totalPrice} kr {props.preliminary ? " (bokad och förbetald)" :""}  </H4Heavy>
                    </TextContainer>
                    <H5>{paymentStatus}</H5>
                </TextContainer>
            </SubsectionContainer>
        </ComponentContainer>
    );
};
export default SummeryComponent

















