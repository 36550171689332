import React, { Component } from 'react';
import styled from 'styled-components';
import { CSSCommon } from "../../variables/CSSVariables";

import StageHeaderComponent from "../StageHeaderComponent";
import Stage1VehicleComponent from "./VehicleComponent"
import ProductComponent from "./product-component/ProductContainer"
import AddVehicle from "./AddVehicle"
import InfoComponent from "./InfoComponent";
import PopupComponent from "../PopupComponent";
import { sendGTMEvent } from '../../resource-functions/HelperFunctions'

const maxTotalWidth = CSSCommon.maxTotalWidth;
const maxRowWidth = CSSCommon.maxRowWidth;
const mobileMax = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    max-width: ${maxTotalWidth};
    padding-top: 1rem;
`;
const VehicleAndProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    min-width: 30rem;
    max-width: ${maxRowWidth};
    width: 100%;
`;
const VehicleSuperContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media(${mobileMax}){
        flex-direction: column;
        align-items: center;
    }
`;

class Stage1Component extends Component {
    renderVehicle = (appState, setAppState, isMain) => {
        return (
            <VehicleAndProductContainer>
                <Stage1VehicleComponent main={isMain} {...{ appState, setAppState }} />
                <ProductComponent main={isMain} {...{ appState, setAppState }} />
            </VehicleAndProductContainer>
        )
    };
    renderAddVehicle = (appState, setAppState, isMain) => {
        return <AddVehicle main={isMain} {...{ appState, setAppState }} />;
    };
    renderPopupComponent = (appState, setAppState) => {
        return (
            <PopupComponent
                overflowAuto
                header="Produktinfo"
                svg="info"
                type="renderInfo"
                {...{ appState, setAppState }}
            >
                <InfoComponent {...{ appState, setAppState }} />
            </PopupComponent>
        )
    };
    renderVehicleMain = (appState, setAppState) => {
        //Decides whether to render vehicle or addVehicle
        if (appState.vehicleMain.registrationNumber) {
            return this.renderVehicle(appState, setAppState, true);
        } else {
            return this.renderAddVehicle(appState, setAppState, true);
        }
    };
    renderVehicleSecondary = (appState, setAppState) => {
        //Decides whether to render vehicle or addVehicle and whether or not first
        // vehicle is a trailer
        if (appState.vehicleSecondary.registrationNumber) {
            return this.renderVehicle(appState, setAppState, false);
        } else if (appState.vehicleMain.kind === 'SLÄP') {
            return this.renderAddVehicle(appState, setAppState, true);
        } else if (appState.vehicleMain.kind) {
            return this.renderAddVehicle(appState, setAppState, false);
        }
    };
    componentDidMount() {
        document.documentElement.scrollIntoView();
        if (window.history.state === null) {
            window.history.replaceState({ page: 1 }, 'page1');
        } else if (window.history.state.page !== 1) {
            window.history.pushState({ page: 1 }, 'page1');
        }

        sendGTMEvent('stage-1');
    }
    shouldComponentUpdate(nextProps) {
        if (this.props.appState.vehicleMain !== nextProps.appState.vehicleMain) {
            return true;
        }
        if (this.props.appState.vehicleSecondary !== nextProps.appState.vehicleSecondary) {
            return true;
        }
        if (this.props.appState.renderInfo !== nextProps.appState.renderInfo) {
            return true;
        }
        if (this.props.appState.viewportHeightPx !== nextProps.appState.viewportHeightPx) {
            return true;
        }
        return false;
    }
    render() {
        const { appState, setAppState } = this.props;
        const vehicleMain = this.renderVehicleMain(appState, setAppState);
        const vehicleSecondary = this.renderVehicleSecondary(appState, setAppState);
        const popupComponent = appState.renderInfo ? this.renderPopupComponent(appState, setAppState) : null;
        return (
            <ComponentContainer id="Stage1Container">
                {popupComponent}
                <StageHeaderComponent
                    headerNr={appState.stage1.header1}
                    {...{ appState, setAppState }}
                />
                <VehicleSuperContainer>
                    {vehicleMain}
                    {vehicleSecondary}
                </VehicleSuperContainer>
            </ComponentContainer>
        );
    }
}
export default Stage1Component;
