import React, { Component } from 'react';
import styled from 'styled-components';
import {CSSCommon} from "../../variables/CSSVariables";

import StageHeaderComponent     from "../StageHeaderComponent";
import VehicleComponent         from "./VehicleComponent";
import StationAndTimeComponent  from "./StationAndTimeComponent";
import ContactsComponent        from "./ContactsComponent";
import PaymentComponent         from "./PaymentComponent";
import SummeryComponent         from "./SummeryComponent";
import { sendGTMEvent }          from '../../resource-functions/HelperFunctions'

const maxTotalWidth     = CSSCommon.maxTotalWidth;
const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;

const Stage3Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    max-width: ${maxTotalWidth};
    width: 100%;
    padding-top: 1rem;
    @media(${tabletMin}){
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
`;
const SubsectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    max-width: 32.4rem;
    width: 100%;
`;

class Stage3Component extends Component {
    constructor(props) {
        super(props);
        this.state = {timeIsDisabled: true};
    }
    componentDidMount(){
        document.documentElement.scrollIntoView();
        if(window.history.state === null || window.history.state.page !== 3){
            if(window.history.state.page.toString().includes('render')){
                window.history.replaceState({page: 3}, 'page3', );
            } else {
                window.history.pushState({page: 3}, 'page3', );
            }
        }
        sendGTMEvent('stage-3','','');
    }
    render() {
        const {appState, setAppState} = this.props;
        const vehicleMain = <VehicleComponent {...{appState, setAppState}}/>;
        const vehicleSecondary = appState.vehicleSecondary.registrationNumber
            ? <VehicleComponent secondary {...{appState, setAppState}}/>
            : null;
        return (
            <Stage3Container>
                <SubsectionContainer>
                    <StageHeaderComponent headerNr={appState.stage3.header1} {...{appState, setAppState}}/>
                    {vehicleMain}
                    {vehicleSecondary}
                    <StageHeaderComponent headerNr={appState.stage3.header2} {...{appState, setAppState}}/>
                    <StationAndTimeComponent {...{appState, setAppState}}/>
                    <PaymentComponent {...{appState, setAppState}}/>
                </SubsectionContainer>
                <SubsectionContainer>
                    <StageHeaderComponent headerNr={appState.stage3.header3} {...{appState, setAppState}}/>
                    <ContactsComponent {...{appState, setAppState}}/>
                    <PaymentComponent isMobile {...{appState, setAppState}}/>
                    <SummeryComponent {...{appState, setAppState}}/>
                </SubsectionContainer>
            </Stage3Container>
        );
    }
}
export default Stage3Component;










