import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";

const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const white         = CSSCommon.color.white;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    padding: 0 1rem;
    margin: 1rem 0 0 0;
    border: solid 0.1rem ${grey};
    cursor: pointer;
    @media print{    
        display: none !important;
    }
    @media(${tabletMin}){
        &:hover {
          border: solid 0.1rem ${hover};
          background: ${hover};
        }
    }
    ${props => props.disabled && css`
        opacity: .15;
        cursor: default;
        &:hover {
            border: solid 0.1rem ${grey};
            background: ${white};
        }
    `}
`;
const SVGContainer = styled.svg`
    width: 1.8rem;
    height: 1.8rem;
`;
const Text = styled.h4`
    margin-left: 1rem;
    font-size: ${CSSCommon.fontSize.h4};
    color: ${green};
    cursor: pointer;
    ${props => props.disabled && css`
        cursor: default;
    `}
`;

class ButtonComponent extends Component {
    onClick = () => {
        this.props.onClick();
    };
    renderSVG = () => {
            return(
                <SVGContainer>
                    {SVG[this.props.svg]}
                </SVGContainer>
            )
    };
    shouldComponentUpdate(nextProps) {
        if (this.props.disabled !== nextProps.disabled) {
            return true;
        }
        if (this.props.appState[this.props.type] !== nextProps.appState[this.props.type]) {
            return true;
        }
        return false;
    };
    render(){
        return(
            <ComponentContainer disabled={this.props.disabled} onClick={this.onClick}>
                {this.renderSVG()}
                <Text disabled={this.props.disabled}>{this.props.text}</Text>
            </ComponentContainer>
        )
    }
}
export default ButtonComponent;
