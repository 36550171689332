import React from 'react';
import styled from 'styled-components';
import {CSSCommon} from "../variables/CSSVariables";
import {SVG} from "../svg/SVG.js"
import {Button} from "../variables/StyledComponents";

const black             = CSSCommon.color.black;
const green             = CSSCommon.color.green;
const yellow            = CSSCommon.color.yellow;
const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax         = CSSCommon.mediaBreakpoints.mobileMax;
const desktopMinInt     = CSSCommon.mediaBreakpointsInt.desktopMin;

const ComponentContainer = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    max-width: ${desktopMinInt}px;
    border-left: solid gainsboro calc((100vw - ${desktopMinInt}px) / 2);
    border-right: solid gainsboro calc((100vw - ${desktopMinInt}px) / 2);
`;
const LogoContainer = styled.div`
    display: flex; 
    align-items: flex-start;
    max-width: ${desktopMinInt}px;
    width: 100%;
    @media(${mobileMax}) {
        display: none;
    }
`;
const SVGLogo = styled.svg`
    width: 10rem;
    height: 3rem;
    fill: ${green};
    padding: 1.5rem;
    padding-left: 9.2rem;
`;
const TextContainer = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding: 4rem;
`;
const Header = styled.h1`
    width: 100%;
    font-size: 2.4rem;
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
    margin-bottom: 2.5rem;
    @media(${tabletMin}) {
        width: 60%;
        font-size: 5.1rem;
        margin-bottom: 2rem;
    }
`;
const TextP1 = styled.p`
    font-size: 1.6rem;
    font-weight: ${CSSCommon.fontWeight.medium};
    width: 100%;
    color: ${green};
    margin-bottom: 5.5rem;
    @media(${tabletMin}) {
        width: 60%;
        font-size: 3rem;
    }
`;
const TextP2 = styled.p`
    font-size: 2rem;
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${green};
    padding-right: 6rem;
    margin-bottom: 2.5rem;
    @media(${tabletMin}) {
        padding-right: 18.8rem;
    }
`;
const TextP3 = styled.p`
    font-size: 5rem;
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${yellow};
    margin-bottom: 5rem;
    @media(${tabletMin}) {
        font-size: 10rem;
    }
`;
const HomeButtonWrapper = styled.a`

`;
const HomeButton = styled(Button)`
    width: 20rem;
    min-width: 20rem;
    min-height: 5rem;
`;
const SVGError = styled.svg`
    height: 26rem;
    fill: ${black};
    z-index: -1;
    @media(${mobileMax}) {
        height: 17rem;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    min-height: 50rem;
    @media(${mobileMax}) {
        min-height: 38rem;
    }
`;

const NotFoundComponent = () => {
    return (
        <ComponentContainer>
            <LogoContainer>
                <SVGLogo>
                    {SVG.logo}
                </SVGLogo>
            </LogoContainer>
            <Container>
                <TextContainer>
                    <Header>Hoppsan...</Header>
                    <TextP1>Vi kan inte hitta sidan du söker.</TextP1>
                    <TextP2>ERROR</TextP2>
                    <TextP3>4 0 4</TextP3>
                    <HomeButtonWrapper href={window.location.origin}>
                        <HomeButton next>Tillbaka till hem</HomeButton>
                    </HomeButtonWrapper>
                </TextContainer>
                <SVGError>
                    {SVG.error}
                </SVGError>
            </Container>
        </ComponentContainer>
    );
};
export default NotFoundComponent