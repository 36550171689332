import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {SVG} from '../../svg/SVG.js'
import {CSSCommon} from '../../variables/CSSVariables';

import ProgressBarComponent from './ProgressBarComponent'

const green             = CSSCommon.color.green;
const white             = CSSCommon.color.white;
const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;
const desktopMin        = CSSCommon.mediaBreakpoints.desktopMin;
const mobileMax         = CSSCommon.mediaBreakpoints.mobileMax;
const desktopMinInt     = CSSCommon.mediaBreakpointsInt.desktopMin;

const ComponentContainer = styled.div`
     display: flex;
     flex-direction: column;
     align-items: center;
     width: 100%;
    @media print{    
        display: none !important;
    }
`;
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    padding-top: 1rem;
    width: 100%;
    background: ${white};
    border-bottom: solid ${green} 0.3rem;
`;
const Header = styled.h1`
    font-size: ${CSSCommon.fontSize.h1};
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${CSSCommon.color.h1};
    background: white;
    z-index: 20;
`;
const ProgressBarFiller = styled.div`
    width: 100%;
    height: 6rem;
    z-index: -50;
`;
const ProgressBarTextContainer = styled.div`
    display: none;
    flex-direction: row;
    justify-content: space-between;
    width: 42rem;
    height: auto;
    margin-bottom: 2rem;
    transition: all ${CSSCommon.transition} ease-in-out;
    z-index: 15;
    @media (${tabletMin}) {
        display: flex;  
    }
`;
const ProgressBarText = styled.h5`
    text-align: center;
    width: 6.4rem;
    height: auto;
    font-size: ${CSSCommon.fontSize.h5};
    z-index: 15;
`;
const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    background: ${white};
    z-index: 20;
    
`;
const Separator = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 6rem;
    width: 100%;
    background: white;
    border-bottom: solid ${green} 0.3rem;
    z-index: 10;
`;
const LogoWrapper = styled.a`
`;
const Logo = styled.button`
    position: absolute;
    left: calc(50vw - (768px / 2) + 2rem);
    top: 1.5rem;
    width: 7rem;
    height: auto;
    fill: ${green};
    cursor: pointer;
    z-index: 50;
    .st0{
        fill:none;
    }
    .st1{
        fill-rule:evenodd;
        clip-rule:evenodd;
        fill:${green};
    }
    .st2{
        fill:${green};
    }
    @media(min-width: 838px) {
        left: 5rem;
    }
    @media(${desktopMin}) {
        left: calc(50vw - (${desktopMinInt}px / 2) + 6rem);
        top: 1.5rem;
    }
    @media(${mobileMax}) {
        display: none;
    }
`;
//Ensures that propper height is kept in main scroll container on transition to fixed alert
const PseudoAlertContainer = styled.div`
    width: 100%;
    display: flex;    
    align-items: center;
    flex-direction: column;
    z-index: 1;
    ${props => !props.scrollTop && css`
        display: none;
        z-index: 1;
    `}
`;
const AlertContainer = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${white};
    z-index: 10;
    ${props => props.scrollTop && css`
        
        position: fixed;
        top: 6.3rem;
    `}
`;

class HeaderComponent extends Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.appState.progressBar !== nextProps.appState.progressBar) {
            return true;
        }
        if (this.props.appState.activeStage !== nextProps.appState.activeStage) {
            return true;
        }
        if (this.props.appState.headerHeight !== nextProps.appState.headerHeight) {
            return true;
        }
        if (this.props.appState.headerComponentHeightAlert !== nextProps.appState.headerComponentHeightAlert) {
            return true;
        }
        if (this.props.appState.renderAlert !== nextProps.appState.renderAlert) {
            return true;
        }
        return false;
    }

    render() {
        const {appState, setAppState} = this.props;
        const header = this.props.header;
        const scrollTop = this.props.appState.headerHeight;
        const progressBarFiller = scrollTop ? <ProgressBarFiller/> : null;

        return (
            <ComponentContainer>
                <HeaderContainer id='header-component'>
                    <ContentContainer>
                        <LogoWrapper href='https://dekra-bilbesiktning.se' tabIndex='-1'>
                            <Logo>
                                {SVG.logo}
                            </Logo>
                        </LogoWrapper>
                        <Header id='header'>{header}</Header>
                        {progressBarFiller}
                        <ProgressBarComponent {...{appState, setAppState}}/>
                        <ProgressBarTextContainer>
                            <ProgressBarText>Välj fordon & produkt</ProgressBarText>
                            <ProgressBarText>Välj station & tid</ProgressBarText>
                            <ProgressBarText>Summering & betalning</ProgressBarText>
                            <ProgressBarText>Bekräftelse</ProgressBarText>
                        </ProgressBarTextContainer>
                    </ContentContainer>
                    <Separator/>
                </HeaderContainer>
                <PseudoAlertContainer scrollTop={this.props.appState.headerComponentHeightAlert}>
                    {this.props.children}
                </PseudoAlertContainer>
                <AlertContainer scrollTop={this.props.appState.headerComponentHeightAlert}>
                    {this.props.children}
                </AlertContainer>
            </ComponentContainer>
        );
    }
}

export default HeaderComponent;
