import React, { Component } from 'react';
import styled from 'styled-components';
import validator from 'email-validator';
import {SVG} from "../../svg/SVG.js"
import { CSSCommon } from "../../variables/CSSVariables";
import { Button, Input, Label, SVGLabel, TextH6 } from "../../variables/StyledComponents";

const grey  = CSSCommon.color.grey;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0.9rem 0.9rem 0.9rem;
`;
const InputEditContacts = styled(Input)`
    margin-bottom: 1rem;
`;
const LabelEditContacts = styled(Label)`
    margin-top: -0.5rem;
`;
const Textarea = styled.textarea`
    width: calc(100% - 1rem);
    height: 4.8rem;
    padding-top: 1rem;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 0.3rem;
    background: ${grey};
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.medium};
    resize: none;
`;
const H6 = styled(TextH6)`
    margin-bottom: 1rem;
`;

class EditContactsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booking: false,
            validateEmail: null,
            validatePhone: null
        }
    }
    handleSubmit = () => {
        const contacts = this.state.booking.contactInformation;
        const validateEmail = validator.validate(contacts.email);
        const validatePhone = (contacts.phoneNumber.length > 7) && !contacts.phoneNumber.match(/[a-z]/i);
        if (this.state.validateEmail === null || this.state.validatePhone === null) {
            this.setState({validateEmail: validateEmail, validatePhone: validatePhone})
        }
        if (this.validateContacts()) {
            this.props.setAppState({
                    renderEditContacts: false
                },
                {
                    type: "postContactInformation",
                    payload: this.booking
                });
        }
    };
    alterInput = (e, type) => {
        this.booking.contactInformation[type] = e;
        this.setState({booking: this.booking});
        if((this.state.validateEmail !== null && this.state.validatePhone !== null)
            || this.props.appState.validateContacts)
        {
            this.validateInput(type)
        }
    };
    validateContacts = () => {
        const contacts = this.state.booking.contactInformation;
        return (
            validator.validate(contacts.email)
            && contacts.phoneNumber.length > 7)
            && !contacts.phoneNumber.match(/[a-z]/i
        );

    };
    handleKeyPress = e => {
        if (e.nativeEvent.key === 'Enter') {
            this.handleSubmit();
        }
    };
    scrollIntoView = (nr) => {
        if(this.props.appState.viewportMobile){
            document.getElementsByTagName('form')[0][nr].scrollIntoView({behavior: "smooth", block: "center"});
        }
    };
    validateInput = type => {
        const contacts = this.state.booking.contactInformation;
        switch(type) {
            case 'email':
                this.setState({validateEmail: validator.validate(contacts.email)});
                break;
            case 'phoneNumber':
                this.setState({validatePhone: (contacts.phoneNumber.length > 7) && !contacts.phoneNumber.match(/[a-z]/i)});
                break;
            default:
                break;
        }
    };
    emailIsValid = () => {
        if(this.state.validateEmail){
            return true
        } else if (this.state.validateEmail === null){
            return true
        }
    };
    phoneIsValid = () => {
        if(this.state.validatePhone){
            return true
        } else if (this.state.validatePhone === null){
            return true
        }
    };
    componentDidMount() {
        this.booking = JSON.parse(JSON.stringify(this.props.appState.booking));
        this.setState({booking: this.booking});
    }
    render() {
        const contacts = this.state.booking.contactInformation;
        //If statement to ensure that values are being loaded properly.
        //This due to deprecation of componentWillMount in coming react versions
        if(this.state.booking){
            return (
                <ComponentContainer>
                    <form>
                        <InputEditContacts
                            autoFocus
                            type="text"
                            placeholder="Förnamn"
                            maxLength="24"
                            value={contacts.firstName ? contacts.firstName : ''}
                            isValid
                            onKeyDown={this.handleKeyPress}
                            onChange={e => this.alterInput(e.target.value, 'firstName')}
                            onFocus={this.scrollIntoView.bind(this, 0)}
                        />
                        <InputEditContacts
                            type="text"
                            placeholder="Efternamn"
                            maxLength="24"
                            value={contacts.lastName ? contacts.lastName : ''}
                            isValid
                            onKeyDown={this.handleKeyPress}
                            onChange={e => this.alterInput(e.target.value, 'lastName')}
                            onFocus={this.scrollIntoView.bind(this, 1)}
                        />
                        <LabelEditContacts
                            isValid={this.emailIsValid()}
                        >
                            <SVGLabel>{SVG.danger}</SVGLabel>
                            Fyll i korrekt e-post:
                        </LabelEditContacts>
                        <InputEditContacts
                            type="email"
                            placeholder="E-post *"
                            maxLength="70"
                            value={contacts.email ? contacts.email : ''}
                            isValid={this.emailIsValid()}
                            onKeyDown={this.handleKeyPress}
                            onChange={e => this.alterInput(e.target.value.trim(), 'email')}
                            onFocus={this.scrollIntoView.bind(this, 2)}
                        />
                        <LabelEditContacts
                            isValid={this.phoneIsValid()}
                        >
                            <SVGLabel>{SVG.danger}</SVGLabel>
                            Fyll i korrekt telefonnummer:
                        </LabelEditContacts>
                        <InputEditContacts
                            type="tel"
                            placeholder="Telefonnummer *"
                            maxLength="12"
                            value={contacts.phoneNumber ? contacts.phoneNumber : ''}
                            isValid={this.phoneIsValid()}
                            onKeyDown={this.handleKeyPress}
                            onChange={e => this.alterInput(e.target.value.trim(), 'phoneNumber')}
                            onFocus={this.scrollIntoView.bind(this, 3)}
                        />
                        <Textarea
                            value={contacts.comment ? contacts.comment : ''}
                            placeholder="Övrigt"
                            maxLength="256"
                            onKeyDown={this.handleKeyPress}
                            onChange={e => this.alterInput(e.target.value, 'comment')}
                            onFocus={this.scrollIntoView.bind(this, 4)}
                        />
                    </form>
                        <H6>Fälten markerade med * är obligatoriska</H6>
                        <Button onlyNext onClick={this.handleSubmit}>Uppdatera uppgifter</Button>
                </ComponentContainer>
            );
        } else {
            return null
        }
    }
}
export default EditContactsComponent






























